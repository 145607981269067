import { ONE_YEAR_IN_MONTHS } from 'services/helpers/calculations/constants';
import { Attribute, JourneyType } from 'shared/types';

export const getAttributeSum = (filteredAttributes, formattedMonthlyPrice: number, leaseTerm: number): number => {
  Array.isArray(filteredAttributes) &&
    filteredAttributes.length > 0 &&
    filteredAttributes.map(
      (attribute: {
        type: string;
        value: number;
        values: Array<{
          percentage: number;
          type: string;
          unit: string;
          value: string;
        }>;
      }) => {
        if (attribute?.value !== undefined) {
          formattedMonthlyPrice += Number(attribute.value / ONE_YEAR_IN_MONTHS);
        } else if (attribute?.type === Attribute.ROAD_TAX) {
          const journeyTypeAttributeValue = Number(
            attribute.values.find((object) => object.type === JourneyType.TOTAL_COST_OF_OWNERSHIP_BREAKDOWN)?.value
          );
          formattedMonthlyPrice += journeyTypeAttributeValue / (ONE_YEAR_IN_MONTHS * leaseTerm);
        } else {
          const journeyTypeAttributeValue = Number(
            attribute.values.find((object) => object.type === JourneyType.BUSINESS_FLEET_MANAGER)?.value
          );

          formattedMonthlyPrice += journeyTypeAttributeValue / ONE_YEAR_IN_MONTHS;
        }
        return formattedMonthlyPrice;
      }
    );
  return formattedMonthlyPrice;
};
