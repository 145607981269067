import React from 'react';
import Modal from 'shared/components/Modal';
import { PrimaryButton, TertiaryButton } from 'shared/components/Button';
import { useTranslation } from 'next-i18next';
import { TranslationKey } from 'shared/constants';
import { useBrand } from 'shared/hooks/useBrand';
import { Brand, ModalTestType } from 'shared/types';
import ExternalLink from 'shared/components/icons/external_link.svg';
import styled from 'styled-components';

type Props = {
  handleClose: () => void;
  testType: ModalTestType;
};

const ModalWrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.components.testTypeInformation.padding.mobile};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: ${theme.viewports.tablet}) {
      padding: ${theme.components.testTypeInformation.padding.desktop};
    }
  `}
`;

const ModalHeader = styled.h3`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.components.testTypeInformation.header.color};
    margin: ${theme.components.testTypeInformation.header.margin.mobile};
    height: ${theme.components.testTypeInformation.header.height.mobile};
    font: ${theme.components.testTypeInformation.header.font};

    @media screen and (min-width: ${theme.viewports.tablet}) {
      margin: ${theme.components.testTypeInformation.header.margin.desktop};
      height: ${theme.components.testTypeInformation.header.height.desktop};
    }
  `}
`;

const TestTypeInformationContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: ${({ theme }) => theme.components.testTypeInformation.body.margin};
`;

const TestTypeInformationContentTitle = styled.h3`
  ${({ theme }) => `
    display: flex;
    justify-content: ${theme.components.testTypeInformation.title.justifyContent};
    text-align: center;
    width: 100%;
    font: ${theme.components.testTypeInformation.title.font};
    color: ${theme.components.testTypeInformation.title.color};
    margin: ${theme.components.testTypeInformation.title.margin};
  `}
`;

const TestTypeInformationContentBody = styled.div`
  ${({ theme }) => `
    color: ${theme.components.testTypeInformation.body.color};
    font: ${theme.components.testTypeInformation.body.font};
    white-space: pre-line;

  `}
`;

const TestTypeInformationContentBodyLink = styled(TertiaryButton)`
  justify-content: flex-start;
`;

const TestProcedureModalButton = styled(PrimaryButton)`
  ${({ theme }) => `
    &&& {
      width: ${theme.components.testTypeInformation.button.width};
    }
  `}
`;

const IconWrapper = styled.div`
  display: inline-flex;
  margin-left: 4px;
`;

const getLinkIconByBrand = (brand) => {
  switch (brand) {
    case Brand.VOLKSWAGEN:
      return (
        <IconWrapper>
          <ExternalLink />
        </IconWrapper>
      );
    default:
      return null;
  }
};

export const TestTypeInformationStandAloneModal = ({ handleClose, testType }: Props) => {
  const brand = useBrand();
  const { t: translate } = useTranslation(`${brand}.${TranslationKey.COMMON}`);
  const icon = getLinkIconByBrand(brand);
  const linkKey = `testTypeInformation.${testType}.linkText`;
  const translatedLinkKey = translate(linkKey);
  const isLinkExisting = linkKey !== translatedLinkKey;

  return (
    <Modal onClose={handleClose}>
      <ModalWrapper>
        <ModalHeader>{translate(`testTypeInformation.${testType}.header`)}</ModalHeader>

        <TestTypeInformationContent data-test="test-type-information--content">
          <TestTypeInformationContentTitle data-test="test-type-information-content--title">
            {translate(`testTypeInformation.${testType}.title`)}
          </TestTypeInformationContentTitle>
          <TestTypeInformationContentBody data-test="test-type-information-content--body">
            {translate(`testTypeInformation.${testType}.body`)}
          </TestTypeInformationContentBody>
          {isLinkExisting ? (
            <TestTypeInformationContentBodyLink
              data-test="test-type-information-link"
              href={translate(`testTypeInformation.${testType}.link`)}
              target="_blank"
              rel="noopener noreferrer"
              text={
                <span>
                  {translatedLinkKey}
                  {icon}
                </span>
              }
              isLink
            />
          ) : null}
        </TestTypeInformationContent>
        <TestProcedureModalButton
          data-test="test-procedure-modal__close-button"
          onClick={handleClose}
          text={translate('Close')}
        />
      </ModalWrapper>
    </Modal>
  );
};
