export enum RANGE_MAX {
  EMISSIONS = 260,
  FUEL_COST = 4000,
  CLEAN_AIR_ZONE_CHARGES = 2352,
  ROAD_TAX_FIRST_YEAR = 2245,
  ROAD_TAX_TOTAL = 3225,
  ELECTRIC_DRIVING_RANGE = 400,
  RESIDUAL_VALUE = 40000,
  MAINTENANCE_COST = 4000,
  FUEL_EFFICIENCY = 240,
  BENEFIT_IN_KIND = 750,
  PERFORMANCE = 500,
  TOTAL_COST_OF_OWNERSHIP = 3500,
  EMPLOYERS_NATIONAL_INSURANCE = 6000,
  TOP_SPEED = 350,
  ACCELERATION = 30,
  MILES_PER_KWH = 10,
}

export enum UNITS {
  BENEFIT_IN_KIND = 'per month',
  EMISSIONS = 'g/km',
  FUEL_COST = 'per year',
  CLEAN_AIR_ZONE_CHARGES = 'per year',
  EMPLOYERS_NATIONAL_INSURANCE = 'per year',
  ROAD_TAX_FIRST_YEAR = 'first year',
  ROAD_TAX_TOTAL = 'over {years} years',
  ELECTRIC_DRIVING_RANGE = 'miles',
  MAINTENANCE = 'per year',
  FUEL_EFFICIENCY_ICE = 'MPG',
  FUEL_EFFICIENCY_BEV = 'MPGe',
  PERFORMANCE_PS = 'PS',
  PERFORMANCE_BHP = 'BHP',
  RESIDUAL_VALUE = 'after {years} years',
  TOP_SPEED = 'MPH',
  ACCELERATION = 'seconds',
  SEATS = 'seats',
  MILES_PER_KWH = 'MPkWh',
}

export enum JSON_PATH_LOCATIONS {
  FUEL_TYPE = 'capCodeDecoded.fuelType',
  DRIVE_TRAIN = 'capCodeDecoded.driveTrain',
  EMISSIONS_ICE = 'consumerTco.vehicle[?(@.item=="CO2 (g/km)")].value',
  EMISSIONS_PHEV = EMISSIONS_ICE,
  EURO_EMISSION_STANDARD = 'technicalData[?(@.Tech_TechCode=="68")].tech_value_string',
  COST_NEW = 'totalCostOfOwnership.CostNew',
  DELIVERY_COST = 'totalCostOfOwnership.DeliveryCost',
  REGISTRATION_FEE = 'options[?(@.Opt_OptionCode=="0")].Opt_Basic',
  ROAD_TAX_FIRST_YEAR = 'consumerTco.tcoSummary[?(@.item=="VED 1st Year")].value',
  ROAD_TAX_TOTAL = 'consumerTco.tcoSummary[?(@.item=="Total VED")].value',
  MAINTENANCE_COST = 'consumerTco.smrSummary[?(@.item=="Total SMR")].value',
  FUEL_EFFICIENCY_BEV_PRIMARY = 'technicalData[?(@.Tech_TechCode==356)].tech_value_string',
  FUEL_EFFICIENCY_BEV_SECONDARY = 'technicalData[?(@.Tech_TechCode==164)].tech_value_string',
  FUEL_EFFICIENCY_BEV_TERTIARY = 'technicalData[?(@.Tech_TechCode==354)].tech_value_string',
  FUEL_EFFICIENCY_PHEV = 'consumerTco.vehicle[?(@.item=="Combined MPG")].value',
  FUEL_EFFICIENCY_ICE = FUEL_EFFICIENCY_PHEV,
  ELECTRIC_DRIVING_RANGE = 'technicalData[?(@.Tech_TechCode==400)].tech_value_string',
  SERVICE_MAINTENCE_REPAIRS = 'consumerTco.smrSummary[?(@.item.match(/Total SMR/i))].value',
  PERFORMANCE_BHP = 'technicalData[?(@.DT_LongDescription.match(/(Engine Power - BHP)/i))].tech_value_string',
  PERFORMANCE_PS = 'technicalData[?(@.DT_LongDescription.match(/(Engine Power - PS)/i))].tech_value_string',
  EMPLOYERS_NATIONAL_INSURANCE = 'consumerTco.tcoSummary[?(@.item=="Total NIC")].value',
  ELECTRIC_DRIVING_RANGE_BEV = 'consumerTco.vehicle[?(@.item=="Electric Range")].value',
  ELECTRIC_DRIVING_RANGE_PHEV = ELECTRIC_DRIVING_RANGE_BEV,
  TOTAL_BENEFIT_IN_KIND = 'consumerTco.tcoSummary[?(@.item=="Total BIK")].value',
  RESIDUAL_VALUE = 'valuation.futureSpecific.valuation',
  USED_VEHICLE_FALLBACK_DESCRIPTION = 'capMakeModelDer.DerivativeDesc',
  USED_VEHICLE_BRAND = 'consumerTco.vehicle[?(@.item.match(/brand/i))]',
  USED_VEHICLE_RANGE = 'consumerTco.vehicle[?(@.item.match(/range/i))]',
  USED_VEHICLE_TRIM = 'consumerTco.vehicle[?(@.item.match(/trim/i))]',
  TOP_SPEED = 'consumerTco.vehicle[?(@.item=="Top Speed")].value',
  ACCELERATION = 'consumerTco.vehicle[?(@.item=="Acceleration 0-62mph")].value',
  SEATS = 'detailedTco.vehicle[?(@.item.match(/Seats$/))].value',
  TEST_TYPE = 'technicalData[?(@.Tech_TechCode === 199)]',
}

export const CLEAN_AIR_ZONE_CHARGES = 2352;
export const FALLBACK_REGISTRATION_FEE = 55;
export const CLEAN_AIR_ZONE_ENTRIES_CHARGE = 10;
