import React from 'react';
import { useTranslation } from 'next-i18next';
import { TranslationKey } from 'shared/constants';
import { JourneyType } from 'shared/types';
import { useJourney } from 'shared/hooks/useJourney';
import { useBrand } from 'shared/hooks/useBrand';
import styled from 'styled-components';

interface Props {
  options: string[];
  selectedOption: string;
  onClick: (selected: string) => void;
}

const ToggleWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    margin-left: ${theme.components.toggle.margin.mobile.left};
    justify-content: ${theme.components.toggle.position};
  `}
`;

const ToggleButtonWrapper = styled.button<{ isSelected: boolean }>`
  ${({ isSelected, theme }) => `
    border: unset;
    background: unset;
    margin-right: ${theme.components.toggle.button.margin.mobile.right};
    margin-left: ${theme.components.toggle.button.margin.mobile.left};
    border-bottom-width:  ${theme.components.toggle.button.border.bottom.width};
    border-bottom-style: ${theme.components.toggle.button.border.bottom.style};
    border-bottom-color: ${
      isSelected
        ? theme.components.toggle.button.active.border.color
        : theme.components.toggle.button.border.bottom.color
    };
    padding-top: ${theme.components.toggle.button.padding.mobile.top};
    padding-right: 0;
    padding-bottom: ${theme.components.toggle.button.padding.mobile.bottom};
    padding-left: 0;
    cursor: pointer;
    color: ${isSelected ? theme.components.toggle.button.active.font.color : theme.components.toggle.font.color};
    font: ${theme.components.toggle.font.family};
    pointer-events: ${isSelected ? 'none' : 'auto'};


    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:focus {
      outline: ${theme.components.toggle.button.focus.outline};
    }

    &:hover {
      color: ${theme.components.toggle.button.active.font.color};
      border-bottom-color: ${theme.components.toggle.button.active.border.color};
    }

    @media screen and (min-width: ${theme.viewports.desktop}) {
      padding-top: ${theme.components.toggle.button.padding.desktop.top};
      padding-bottom: ${theme.components.toggle.button.padding.desktop.bottom};
    }
  `}
`;

const Toggle: React.FC<Props> = ({ options, selectedOption, onClick }) => {
  const brand = useBrand();
  const { setJourneyData } = useJourney();

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);

  const handleToggle = (ev: React.MouseEvent<HTMLButtonElement>): void => {
    ev.preventDefault();
    setJourneyData(ev.currentTarget.value);
    onClick(ev.currentTarget.value);
  };

  const isSelected = (index: number): boolean => {
    return options[index] === selectedOption;
  };

  return (
    <ToggleWrapper>
      <ToggleButtonWrapper
        isSelected={isSelected(0)}
        value={JourneyType.PRIVATE}
        onClick={handleToggle}
        data-test="toggle--private"
        type="button"
      >
        {translate('toggle.private')}
      </ToggleButtonWrapper>

      <ToggleButtonWrapper
        isSelected={isSelected(2)}
        value={JourneyType.BUSINESS_COMPANY_CAR_DRIVER}
        onClick={handleToggle}
        data-test="toggle--company-car-driver"
        type="button"
      >
        {translate('toggle.company-car-driver')}
      </ToggleButtonWrapper>

      <ToggleButtonWrapper
        isSelected={isSelected(1)}
        value={JourneyType.BUSINESS_FLEET_MANAGER}
        onClick={handleToggle}
        data-test="toggle--fleet-manager"
        type="button"
      >
        {translate('toggle.fleet-manager')}
      </ToggleButtonWrapper>
    </ToggleWrapper>
  );
};

export default Toggle;
