import isUndefined from 'lodash/isUndefined';
import { formatToCurrency } from 'shared/utils/currency';
import { UNITS } from 'services/enums/CapHpiVehicleDataEnums';
import { Brand, DataBubbles, Tab } from 'shared/types';

import {
  DATA_BUBBLES,
  EnvironmentOptions,
  KeySpecificationOptions,
  PerformanceOptions,
  SafetyOptions,
  TechnologyOptions,
  VehicleOptionHeadings,
} from 'features/detailed-comparator/components/DataTable/constants';
import { VehicleComparison } from 'features/detailed-comparator/types';
import { makePillData } from 'shared/utils/makePillData';
import sortOptions from 'features/detailed-comparator/utils/sortOptions';
import { AttributeInterface } from 'features/detailed-comparator/components/Attributes/Attributes';

const getAllowedOptions = (key: Tab, brand: Brand): VehicleOptionHeadings[] | DataBubbles[] => {
  switch (key) {
    case Tab.KEY_SPECIFICATION:
      return KeySpecificationOptions[brand];
    case Tab.ENVIRONMENTAL:
      return EnvironmentOptions[brand];
    case Tab.TECHNOLOGY:
      return TechnologyOptions[brand];
    case Tab.SAFETY:
      return SafetyOptions[brand];
    case Tab.PERFORMANCE:
      return PerformanceOptions[brand];
    default:
      return [];
  }
};

const makeOptions = (comparisons: VehicleComparison[], brand: Brand, key: Tab, translate): AttributeInterface[] => {
  const allowedOptions = getAllowedOptions(key, brand);
  const unOrderedOptions = allowedOptions.map((option) => {
    return comparisons.reduce((acc: AttributeInterface, comparison, index) => {
      if (!comparison.isComparisonSet) {
        const values = acc.values ? [...acc.values] : [];
        values[index] = { value: '', testType: '' };
        return { ...acc, values };
      }

      const { attributes, testType = '' } = comparison?.comparison || {};

      switch (key) {
        case Tab.PERFORMANCE:
        case Tab.ENVIRONMENTAL: {
          const { type, unit, value } =
            attributes.find((attributesObject: { type: string }) => attributesObject.type === option) || {};
          let formattedValue = translate('common.no-data-available');

          if (!isUndefined(type) && !isUndefined(value)) {
            if (option === DataBubbles.CLEAN_AIR_ZONE_CHARGES) {
              formattedValue = value === '0' ? translate('common.free') : `${formatToCurrency(value)} ${unit}`;
            } else if (value !== '' || unit !== '') {
              formattedValue = `${value} ${unit}`;
            }
          }

          if (option === DataBubbles.MILES_PER_KWH && value === '') {
            formattedValue = `0 ${UNITS.MILES_PER_KWH}`;
          }
          const { label, content }: (typeof DATA_BUBBLES)[keyof typeof DATA_BUBBLES] = DATA_BUBBLES[option];
          const values = acc.values ? [...acc.values] : [];
          values[index] = { value: formattedValue, testType };
          return {
            ...acc,
            title: option,

            ...{
              ...acc[option],
              values,
              tooltipLabel: label,
              tooltipContent: content,
            },
          };
        }
        default: {
          const {
            title = '',
            content: valueContent = '',
            type = '',
          } = comparison.comparison.options.find((comparisonOption) => option === comparisonOption.title) || {};

          const content = makePillData(type, valueContent);
          const values = acc.values ? [...acc.values] : [];
          values[index] = { value: translate(content.translationKey), testType };

          return {
            ...acc,
            title,

            ...{ ...acc[title], values },
          };
        }
      }
    }, {});
  });

  return sortOptions(unOrderedOptions, allowedOptions);
};

export default makeOptions;
