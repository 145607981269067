import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { TranslationKey } from 'shared/constants';
import FormItem from 'features/detailed-comparator/components/TotalCostOfOwnershipRecalculationForm/FormItem';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { JourneyType, InitialFormValues, FormItems, Arrow } from 'shared/types';
import { useTheme } from 'shared/hooks/useTheme';
import { ButtonSizes, PrimaryButton, SecondaryButton, TertiaryButton } from 'shared/components/Button';
import { FormValues, InputType, OwnershipType } from 'features/detailed-comparator/types';
import isUndefined from 'lodash/isUndefined';
import { useBrand } from 'shared/hooks/useBrand';
import { AvailableFormItems } from 'features/detailed-comparator/components/TotalCostOfOwnershipRecalculationForm/constants';
import makeTotalCostOfOwnershipFormData from 'features/detailed-comparator/components/TotalCostOfOwnershipRecalculationForm/makeTotalCostOfOwnershipFormData';
import { usePlausible } from 'next-plausible';
import Close from 'assets/close.svg';
import styled from 'styled-components';
import { ArrowButtonByBrand } from 'features/results/constants';

interface Props {
  selectedOption: JourneyType;
  setLeaseTerm: Dispatch<SetStateAction<number>>;
  initialFormValues: InitialFormValues;
  formValues: FormValues;
  onSubmit: (formValues: FormValues) => void;
  activeOwnLease: OwnershipType;
  isLoading: boolean;
}

const ChevronIcon = styled.svg<{ src: string }>`
  ${({ src, theme }) => `
    background-color: ${theme.colors.primary};
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(${src});
  `};
`;

const PersonaliseCostsMobileButton = styled(TertiaryButton)`
  ${({ theme }) => `
    &&& {
      display: flex;
      min-width: 24px;
      height: ${theme.components.recalculateForm.wrapper.toggle.height};
      font: ${theme.components.recalculateForm.wrapper.toggle.font};
      text-decoration: ${theme.components.recalculateForm.wrapper.toggle.textDecoration};

      & svg {
        margin-top: 3px;
      }

      @media screen and (min-width: ${theme.viewports.desktop}) {
        display: none;
      }
    }
  `}
`;

const PersonaliseCostsDesktopButton = styled(TertiaryButton)`
  ${({ theme }) => `
    &&& {
      display: none;

      @media screen and (min-width: ${theme.viewports.desktop}) {
        display: flex;
        height: ${theme.components.recalculateForm.wrapper.toggle.height};
        min-width: 24px;
        color: ${theme.components.recalculateForm.wrapper.toggle.color};
        text-decoration: ${theme.components.recalculateForm.wrapper.toggle.textDecoration};
      }
    }
  `}
`;

const Form: React.FC<Props> = ({
  selectedOption,
  setLeaseTerm,
  initialFormValues,
  formValues,
  onSubmit,
  activeOwnLease,
  isLoading,
}) => {
  const theme = useTheme();
  const brand = useBrand();

  const { logEvent } = useAnalytics();
  const plausible = usePlausible();

  const [mobilePersonaliseCostToggle, setMobilePesonaliseCostToggle] = useState(false);
  const [desktopPersonaliseCostToggle, setDesktopPersonaliseCostToggle] = useState(false);

  const [recalculateFormSubmission, setRecalculateFormSubmission] = useState(false);

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);

  const translationPrefix = 'total-cost-of-ownership-recalculation-form';

  useEffect(() => {
    if (!isLoading && recalculateFormSubmission) {
      setMobilePesonaliseCostToggle(false);
      setRecalculateFormSubmission(false);
    }
  }, [isLoading, recalculateFormSubmission]);

  useEffect(() => {
    setLocalFormValues({
      ...formValues,
    });
  }, [formValues]);

  const handleMobilePersonaliseCostToggle = async (): Promise<void> => {
    await logEvent(`/recalculation-menu/click/${mobilePersonaliseCostToggle ? 'close' : 'open'}`);

    setMobilePesonaliseCostToggle(!mobilePersonaliseCostToggle);
  };

  const handleDesktopPersonaliseCostToggle = async (): Promise<void> => {
    await logEvent(`/recalculation-menu/click/${desktopPersonaliseCostToggle ? 'close' : 'open'}`);

    setDesktopPersonaliseCostToggle(!desktopPersonaliseCostToggle);
  };

  const submitRecalculationForm: React.FormEventHandler<HTMLFormElement> = async (ev) => {
    ev.preventDefault();

    const formValues = {
      'average-mileage': 0,
      'fuel-price': 0,
      'clean-air-zone-entries': 0,
      'electricity-rate': 0,
      'lease-term': 0,
      'tax-rate': 0,
    };

    const processFormValues = (event: any, name: string): string => {
      return event.target[name].value;
    };

    Object.keys(formValues).forEach((name) => {
      if (!isUndefined(ev.target[name]?.value)) {
        if (name === 'lease-term') {
          setLeaseTerm(ev.target['lease-term'].value);
        }

        formValues[name] = processFormValues(ev, name);
      }
    });

    onSubmit(localFormValues);
    await logEvent(
      `/recalculation-form/submit/average-mileage/${formValues['average-mileage']}/fuel-price/${formValues['fuel-price']}/clean-air-zone-entries/${formValues['clean-air-zone-entries']}/electricity-rate/${formValues['electricity-rate']}/lease-term/${formValues['lease-term']}/tax-rate/${formValues['tax-rate']}`
    );
    plausible(`detailed_comparator_recalculated`);

    if (mobilePersonaliseCostToggle) {
      setRecalculateFormSubmission(true);
    }
  };

  const formItems = AvailableFormItems[brand][selectedOption];
  const FORM_ITEMS_DATA = makeTotalCostOfOwnershipFormData(initialFormValues);

  const [localFormValues, setLocalFormValues] = useState(formValues);

  const handleFormValueChange = (label: string, value: string): void => {
    setLocalFormValues({
      ...localFormValues,
      [label]: value,
    });
  };

  return (
    <div className={`recalculate-wrapper ${desktopPersonaliseCostToggle ? 'desktop-is-active' : ''}`}>
      <PersonaliseCostsMobileButton
        text={translate('total-cost-of-ownership-recalculation-form.recalculate.toggle')}
        onClick={handleMobilePersonaliseCostToggle}
        data-test="mobile-recalculate-button"
        appendIcon
      >
        <ChevronIcon
          src={ArrowButtonByBrand[Arrow.RIGHT][brand]}
          width={theme.components.recalculateForm.wrapper.toggle.iconSize}
          height={theme.components.recalculateForm.wrapper.toggle.iconSize}
        />
      </PersonaliseCostsMobileButton>
      <PersonaliseCostsDesktopButton
        text={translate('total-cost-of-ownership-recalculation-form.recalculate.toggle')}
        onClick={handleDesktopPersonaliseCostToggle}
        data-test="desktop-recalculate-button"
        appendIcon
      >
        <ChevronIcon
          src={
            desktopPersonaliseCostToggle
              ? ArrowButtonByBrand[Arrow.COLLAPSE][brand]
              : ArrowButtonByBrand[Arrow.EXPAND][brand]
          }
          width={theme.components.recalculateForm.wrapper.toggle.iconSize}
          height={theme.components.recalculateForm.wrapper.toggle.iconSize}
        />
      </PersonaliseCostsDesktopButton>

      <div
        className={`form-wrapper ${mobilePersonaliseCostToggle ? 'mobile-is-active' : ''} ${
          desktopPersonaliseCostToggle ? 'desktop-is-active' : ''
        }`}
        data-test="recalculate-form-wrapper"
      >
        <div className="form-header">
          <img src={`/assets/${brand}/common/${brand}-logo.svg`} alt="" className="brand-logo" />
          <button onClick={handleMobilePersonaliseCostToggle} className="form-close" type="button">
            <Close />
          </button>
        </div>

        <form className="form" data-test="tco-recalculation-form" onSubmit={submitRecalculationForm}>
          <div className="form__inner">
            <h1>{translate('running-costs.mobile.heading')}</h1>
            <div className="form__items">
              {formItems.map((formItemContent: string) => {
                let formItem = formItemContent;

                if (formItemContent === FormItems.LEASE_TERM) {
                  formItem = activeOwnLease === OwnershipType.OWN ? FormItems.OWN_TERM : FormItems.LEASE_TERM;
                }
                const formItemData = FORM_ITEMS_DATA[formItem];
                const type = formItemData.name === 'tax-rate' ? InputType.SELECT : InputType.INPUT;

                return (
                  <FormItem
                    key={formItemData.defaultValue}
                    label={translate(formItemData.label)}
                    helperText={formItemData.helperText}
                    defaultType={formItemData.defaultType}
                    value={localFormValues[formItemData.formValueLabel]}
                    name={formItemData.name}
                    className={formItemData.className}
                    min={formItemData.min}
                    max={formItemData.max}
                    step={formItemData.step}
                    type={type}
                    pattern={formItemData.pattern}
                    title={formItemData.title}
                    formValueLabel={formItemData.formValueLabel}
                    onChange={handleFormValueChange}
                  />
                );
              })}
            </div>

            <div className="form__button">
              <TertiaryButton
                className="recalculation-form--close-desktop"
                text={translate(`${translationPrefix}.recalculate.desktop-close`)}
                appendIcon
                buttonSize={ButtonSizes.SMALL}
                onClick={handleDesktopPersonaliseCostToggle}
              >
                <Close />
              </TertiaryButton>
              <SecondaryButton
                className="recalculate-button recalculate-button--desktop"
                text={translate(`${translationPrefix}.recalculate.button`)}
                data-test="recalculate-button"
                isLoading={isLoading}
                type="submit"
                buttonSize={ButtonSizes.MEDIUM}
              />
              <PrimaryButton
                className="recalculate-button recalculate-button--mobile"
                text={translate(`${translationPrefix}.recalculate.button`)}
                data-test="recalculate-button"
                isLoading={isLoading}
                type="submit"
                buttonSize={ButtonSizes.MEDIUM}
              />
            </div>
          </div>
        </form>
      </div>

      <style jsx>
        {`
          :global(.recalculate-button--desktop) {
            display: none !important;
          }

          .recalculate-wrapper {
            border-color: ${theme.components.recalculateForm.wrapper.border.closed.color};
            border-width: ${theme.components.recalculateForm.wrapper.border.closed.width};
            border-style: ${theme.components.recalculateForm.wrapper.border.closed.style};
          }

          .form-wrapper {
            display: none;
            position: fixed;
            z-index: 4;
            top: -100vh;
            left: 0;
            bottom: 0;
            height: 100vh;
            width: 100vw;
            opacity: 0;
            transition: all 0.5s ease-in-out;
            background-color: ${theme.components.form.backgroundColor};
            overflow: auto;
          }

          .form-wrapper.mobile-is-active {
            display: block;
            top: 0;
            opacity: 1;
          }

          .form-header {
            display: flex;
            border-bottom: ${theme.components.form.border.bottom};
            padding: ${theme.spacing.lg};
            background-color: ${theme.components.form.backgroundColor};
            align-items: center;
          }

          .form-header > .brand-logo {
            width: ${theme.components.form.logo.width};
            display: ${theme.components.form.logo.display};
          }

          .form-header > .form-close {
            margin-left: auto;
            border: unset;
            background-color: unset;
          }

          :global(.form-close > svg) {
            height: 17px;
          }

          .form__inner {
            display: flex;
            flex-direction: column;
            padding: ${theme.components.recalculateForm.wrapper.form.inner.padding[0]};
          }

          .form__inner h1 {
            font: ${theme.components.recalculateForm.wrapper.form.mobileTitle.font} !important;
            padding-top: 24px;
            padding-bottom: 16px;
          }

          :global(.recalculation-form--close-desktop) {
            display: none !important;
          }

          :global(.recalculate-button) {
            width: 100% !important;
            margin-top: 2.571rem;
          }

          @media screen and (min-width: ${theme.viewports.desktop}) {
            .recalculate-wrapper.desktop-is-active {
              width: 100%;
              border-color: ${theme.components.recalculateForm.wrapper.border.open.color};
              border-width: ${theme.components.recalculateForm.wrapper.border.open.width};
              border-style: ${theme.components.recalculateForm.wrapper.border.open.style};
            }

            .form-wrapper {
              display: none;
              margin-top: ${theme.components.recalculateForm.wrapper.margin.top};
              margin-bottom: ${theme.components.recalculateForm.wrapper.margin.bottom};
              background-color: ${theme.components.recalculateForm.backgroundColor};
            }

            .form-wrapper h1 {
              display: none;
            }

            .form-header {
              display: none;
            }

            .form-wrapper {
              position: relative;
              z-index: 0;
              top: auto;
              bottom: auto;
              opacity: 1;
              width: 100%;
              max-width: 100%;
              left: initial;
              height: auto;
              transition: none;
            }

            .form-wrapper.desktop-is-active {
              display: block;
              padding: ${theme.components.recalculateForm.wrapper.form.padding};
              border-width: ${theme.components.recalculateForm.wrapper.form.border.width};
              border-style: ${theme.components.recalculateForm.wrapper.form.border.style};
              border-color: ${theme.components.recalculateForm.wrapper.form.border.color};
              border-radius: ${theme.components.recalculateForm.wrapper.form.border.radius};
            }

            .form__items {
              flex-direction: row;
              width: calc(100% - 140px);
            }

            .form__inner {
              flex-direction: row;
              padding: ${theme.components.recalculateForm.wrapper.form.inner.padding[1]};
            }

            .form__button {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-end;
              margin-bottom: 1px;
            }

            :global(.form__button button:first-of-type) {
              align-self: flex-start;
              justify-content: flex-end;
            }

            :global(.form__button button:last-of-type) {
              align-self: flex-end;
            }

            :global(.recalculation-form--close-desktop) {
              display: flex !important;
            }

            :global(.recalculate-button) {
              width: unset !important;
              margin: unset;
            }

            :global(.recalculate-button--mobile) {
              display: none !important;
            }

            :global(.recalculate-button--desktop) {
              display: flex !important;
              align-self: ${theme.components.recalculateForm.wrapper.form.cta.alignSelf};
            }

            .close-modal-button {
              display: none;
            }

            .form__items {
              display: flex;
              flex-direction: row;
            }
          }

          @media screen and (min-width: 1240px) {
            .form-wrapper,
            .form-wrapper.mobile-is-active {
              padding: ${theme.spacing.xxl} ${theme.spacing.xxxl};
            }
          }
        `}
      </style>
    </div>
  );
};

export default Form;
