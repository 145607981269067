import React from 'react';
import { useTranslation } from 'next-i18next';
import { TranslationKey } from 'shared/constants';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { OwnershipType } from 'features/detailed-comparator/types';
import { useBrand } from 'shared/hooks/useBrand';
import styled from 'styled-components';
import Radio, { Option } from 'shared/components/Radio';

interface Props {
  selectedOption: OwnershipType;
  onClick: (selected: string) => void;
}

const OwnLeaseWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    gap: 8px;
    font: ${theme.components.ownLeaseToggle.wrapper.font};
  `}
`;

const OwnLease: React.FC<Props> = ({ selectedOption, onClick }) => {
  const brand = useBrand();

  const { logEvent } = useAnalytics();

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);

  const handleToggle = (value: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    logEvent(`/own-lease/toggle/click/${value}`);

    onClick(value);
  };

  const options = Object.keys(OwnershipType).map((ownershipType: string): Option => {
    const option = ownershipType.toLowerCase();
    return {
      id: option,
      value: option,
      checked: selectedOption.toLowerCase() === option,
      content: option,
      dataTest: `own-lease-toggle__option--${option}`,
    };
  });

  return (
    <OwnLeaseWrapper>
      <span>{translate('running-costs.own-lease.first-words')}</span>

      <Radio groupName="own-or-lease" options={options} onChange={handleToggle} />

      <span>{translate('running-costs.own-lease.last-words')}</span>
    </OwnLeaseWrapper>
  );
};

export default OwnLease;
