import React from 'react';
import styled from 'styled-components';

const AttributesNoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.components.detailedComparator.attributes.note.title.marginBottom};
`;

const AttributesNoteTitle = styled.h3`
  ${({ theme }) => `
        font: ${theme.components.detailedComparator.attributes.note.title.font};
        color: ${theme.components.detailedComparator.attributes.note.title.color};
    `}
`;
const AttributesNoteBody = styled.p`
  ${({ theme }) => `
        font: ${theme.components.detailedComparator.attributes.note.body.font};
        color: ${theme.components.detailedComparator.attributes.note.body.color};
        white-space: pre-line;
    `}
`;

interface Props {
  title: string;
  body: string | JSX.Element | JSX.Element[];
  listItems?: string[];
}

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
  gap: ${({ theme }) => theme.components.detailedComparator.attributes.note.title.marginBottom};
`;
const ListItem = styled.li`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.components.detailedComparator.attributes.note.list.gap};
    font: ${theme.components.detailedComparator.attributes.note.body.font};
    color: ${theme.components.detailedComparator.attributes.note.body.color};
  `}
`;

export const AttributesNote: React.FC<Props> = ({ title, body, listItems = [] }) => {
  const isListItems = Array.isArray(listItems) && listItems.length;
  return (
    <AttributesNoteWrapper>
      <AttributesNoteTitle>{title}</AttributesNoteTitle>
      {body ? <AttributesNoteBody>{body}</AttributesNoteBody> : null}
      {isListItems ? (
        <List>
          {listItems.map((item, index) => (
            <ListItem key={index}>
              <div>{index + 1}.</div>
              <p>{item}</p>
            </ListItem>
          ))}
        </List>
      ) : null}
    </AttributesNoteWrapper>
  );
};
