import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { TranslationKey } from 'shared/constants';
import { Attribute, JourneyType, InitialFormValues } from 'shared/types';
import Toggle from 'features/detailed-comparator/components/RunningCosts/Toggle';
import { MonthlyCostCalculator, makeMonthlyCostAttributes } from 'features/detailed-comparator';
import { AttributeInterface, Attributes } from 'features/detailed-comparator/components/Attributes/Attributes';
import { FormValues, OwnershipType, VehicleComparison } from 'features/detailed-comparator/types';
import Form from 'features/detailed-comparator/components/TotalCostOfOwnershipRecalculationForm/Form';
import OwnLease from 'features/detailed-comparator/components/RunningCosts/OwnLease';
import AttributeHeadings from 'features/detailed-comparator/components/RunningCosts/AttributeHeadings';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import FormError from 'shared/components/FormError';
import { useBrand } from 'shared/hooks/useBrand';
import { useJourney } from 'shared/hooks/useJourney';
import { snakeCase } from 'lodash';
import { usePlausible } from 'next-plausible';
import { ATTRIBUTES, ATTRIBUTE_JOURNEY_MAPPING } from 'features/detailed-comparator/components/RunningCosts/constants';
import getTooltipText from 'features/detailed-comparator/utils/getTooltipText';
import { ONE_YEAR_IN_MONTHS } from 'services/helpers/calculations/constants';
import { AttributesNote } from 'features/detailed-comparator/components/Attributes/AttributesNote';
import { Container } from 'shared/components/Container';
import styled from 'styled-components';
import { formatToCurrency } from 'shared/utils/currency';
import parse from 'html-react-parser';

interface Props {
  comparisons: VehicleComparison[];
  initialFormValues: InitialFormValues;
  formValues: FormValues;
  selectedOption: JourneyType;
  onToggleClick: (journeyType: JourneyType) => void;
  onSubmit: (formValues: FormValues) => void;
  isLoading: boolean;
  isRecalculationError: boolean;
}

const StyledContainer = styled(Container)`
  &&& {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ToggleAndOwnLeaseWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 0 24px;

    @media screen and (min-width: ${theme.viewports.tablet}) {
      gap: ${theme.components.ownLeaseToggle.wrapper.gap};
      margin: 0 0 28px;
    }
  `}
`;

const RunningCostsHeading = styled.h2`
  ${({ theme }) => `
    display: block;
    font: ${theme.components.detailedComparator.attributes.title.font.mobile};
    margin-right: ${theme.components.runningCostTable.title.mobile.margin.left};
    margin-bottom: ${theme.components.runningCostTable.title.mobile.margin.bottom};
    margin-left: ${theme.components.runningCostTable.title.mobile.margin.left};

    @media screen and (min-width: ${theme.viewports.tablet}) {
      margin-bottom: ${theme.components.runningCostTable.title.tablet.margin.bottom};
      margin-left: ${theme.components.runningCostTable.title.tablet.margin.left};
    }

    @media screen and (min-width: 1240px) {
      font: ${theme.components.detailedComparator.attributes.title.font.desktop};
    }
  `}
`;

const getAttribute = ({ comparisons, position, journeyType, attribute }) => {
  const comparisonData = comparisons[position].comparison;

  const attributes = comparisonData?.attributes?.find((attr) => attr.type === attribute) ?? {};

  if (attribute === Attribute.ROAD_TAX) {
    return attributes.values?.find((attr) => attr.type === 'totalCostOfOwnershipBreakdown') ?? {};
  }

  if (attribute === Attribute.FUEL_COST) {
    return attributes.values?.find((attr) => attr.type === journeyType) ?? {};
  }

  return attributes;
};

const RunningCosts: React.FC<Props> = ({
  comparisons,
  initialFormValues,
  formValues,
  selectedOption,
  onToggleClick,
  onSubmit,
  isLoading,
  isRecalculationError,
}) => {
  const brand = useBrand();

  const { setJourneyData } = useJourney();
  const [selectActive, setSelectActive] = useState('');

  const { logEvent } = useAnalytics();
  const plausible = usePlausible();

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);

  const [activeOwnLease, setActiveOwnLease] = useState(
    selectedOption === JourneyType.PRIVATE ? OwnershipType.OWN : OwnershipType.LEASE
  );

  const handleSelectedOptionClick = async (selected: JourneyType): Promise<void> => {
    let analyticsPath = 'private';

    if (selected === JourneyType.BUSINESS_COMPANY_CAR_DRIVER) {
      analyticsPath = 'company-car-driver';
    } else if (selected === JourneyType.BUSINESS_FLEET_MANAGER) {
      analyticsPath = 'fleet-manager';
    }

    await logEvent(`/running-costs/toggle/click/${analyticsPath}`);
    plausible(`detailed_comparator_select_${snakeCase(translate(selected))}_journey_type`);

    onToggleClick(selected);

    setActiveOwnLease(selected === JourneyType.PRIVATE ? OwnershipType.OWN : OwnershipType.LEASE);
  };

  const handleOwnLeaseClick = (selected: string): void => {
    if (selected === OwnershipType.OWN) {
      setActiveOwnLease(OwnershipType.OWN);
    } else {
      setActiveOwnLease(OwnershipType.LEASE);
    }

    setSelectActive(selected);
  };

  const [leaseTerm, setLeaseTerm] = useState(initialFormValues.financePeriodInYears);

  const [fleetManagerInputValues, setFleetManagerInputValues] = useState([
    { [OwnershipType.OWN]: '0', [OwnershipType.LEASE]: '0' },
    { [OwnershipType.OWN]: '0', [OwnershipType.LEASE]: '0' },
    { [OwnershipType.OWN]: '0', [OwnershipType.LEASE]: '0' },
  ]);

  useEffect(() => {
    setJourneyData(selectedOption);
  }, [selectedOption]);

  const options = ATTRIBUTE_JOURNEY_MAPPING[brand][selectedOption] ?? [];
  const attributes = options.map((option) => {
    return comparisons.reduce((acc: AttributeInterface, comparison, index) => {
      if (!comparison.isComparisonSet) {
        const values = acc.values ? [...acc.values] : [];
        values[index] = { value: '', testType: '' };
        return { ...acc, values };
      }
      const { title, formatValue } = ATTRIBUTES[option];
      const { testType = '' } = comparison?.comparison || {};
      const values = acc.values ? [...acc.values] : [];

      const cellAttribute =
        getAttribute({
          comparisons,
          position: index,
          journeyType: selectedOption,
          attribute: option,
        })?.value ?? '';
      const total = formatValue(translate, leaseTerm * ONE_YEAR_IN_MONTHS)(cellAttribute);

      values[index] = { value: total, testType };
      return {
        ...acc,
        title: translate(title),

        ...{
          ...acc[option],
          values,
          tooltipLabel: getTooltipText(selectedOption, ATTRIBUTES[option], translate),
          tooltipContent: '',
        },
      };
    }, {});
  });

  const monthlyCostAttributes = makeMonthlyCostAttributes({
    comparisons,
    fleetManagerInputValues,
    leaseTerm,
    ownershipType: activeOwnLease,
  });

  const monthlyDepreciations = {
    title: translate('monthly-cost-calculator.monthly-cost'),
    values: monthlyCostAttributes.map(({ monthlyCostOfAsset }, index) => {
      const shouldRenderZero =
        Number(fleetManagerInputValues[index][OwnershipType.OWN]) !== 0 &&
        Number(fleetManagerInputValues[index][OwnershipType.OWN]) <= Number(monthlyCostAttributes[index].residualValue);
      return {
        value: monthlyCostOfAsset ? formatToCurrency(shouldRenderZero ? '' : monthlyCostOfAsset) : '',
      };
    }),
  };

  const shouldShowMonthlyDepreciations =
    selectedOption === JourneyType.BUSINESS_FLEET_MANAGER && activeOwnLease === OwnershipType.OWN;

  return (
    <section className="running-costs" data-test="running-costs">
      <StyledContainer>
        <RunningCostsHeading className="running-costs__heading" data-test="running-costs__heading">
          {translate('tab.running-costs.label')}
        </RunningCostsHeading>
        <ToggleAndOwnLeaseWrapper>
          <Toggle
            options={[JourneyType.PRIVATE, JourneyType.BUSINESS_FLEET_MANAGER, JourneyType.BUSINESS_COMPANY_CAR_DRIVER]}
            selectedOption={selectedOption}
            onClick={handleSelectedOptionClick}
          />
          {selectedOption === JourneyType.BUSINESS_FLEET_MANAGER && (
            <OwnLease selectedOption={activeOwnLease} onClick={handleOwnLeaseClick} />
          )}
        </ToggleAndOwnLeaseWrapper>
        <Form
          selectedOption={selectedOption}
          setLeaseTerm={setLeaseTerm}
          initialFormValues={initialFormValues}
          formValues={formValues}
          onSubmit={onSubmit}
          activeOwnLease={activeOwnLease}
          isLoading={isLoading}
        />
        {isRecalculationError && (
          <FormError text={translate('total-cost-of-ownership-recalculation-form.recalculate.error')} fullWidth />
        )}
        <AttributeHeadings
          comparisons={comparisons}
          selectedOption={selectedOption}
          fleetManagerInputValues={fleetManagerInputValues}
          leaseTerm={leaseTerm}
          ownershipType={activeOwnLease}
        />
        {selectedOption === JourneyType.BUSINESS_FLEET_MANAGER && (
          <MonthlyCostCalculator
            comparisons={comparisons}
            ownershipType={activeOwnLease}
            leaseTerm={leaseTerm}
            setFleetManagerInputValues={setFleetManagerInputValues}
            fleetManagerInputValues={fleetManagerInputValues}
            selectActive={selectActive}
            monthlyCostAttributes={monthlyCostAttributes}
          />
        )}
      </StyledContainer>
      <Attributes
        attributesTitle={translate('tab.running-costs.label')}
        attributes={shouldShowMonthlyDepreciations ? [monthlyDepreciations, ...attributes] : attributes}
        attributesNote={
          <AttributesNote
            title={translate('content.default.calculation-note.heading')}
            body={parse(translate('content.default.calculation-note.content'))}
            listItems={translate('content.default.calculation-note.running-costs-superscript-links', {
              returnObjects: true,
            })}
          />
        }
        shouldHideTopBorder
        shouldHideAttributesTitle
      />
    </section>
  );
};

export default RunningCosts;
