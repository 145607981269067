import React, { useEffect, useReducer, useState, useRef } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useBrand } from 'shared/hooks/useBrand';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import parse from 'html-react-parser';

import {
  BusinessJourneyType,
  Comparison,
  ComparisonResponse,
  FormTypes,
  JourneyType,
  Tab,
  TabItem,
  JourneyTypeParam,
  ParsedUrlQuery,
  FuelType,
  ModalTestType,
  VehicleQueryParams,
  SyntheticTestTypes,
} from 'shared/types';
import { getObjectFromQueryParam } from 'shared/utils/getObjectFromQueryParam';

import { useAnalytics } from 'shared/hooks/useAnalytics';
import { TabHeader, TabDefaultContent } from 'features/detailed-comparator/components/Tab';
import { InitialActiveTab, TabItems, TABS } from 'features/detailed-comparator/components/Tab/constants';
import { TitleAndDescription } from 'features/detailed-comparator/components/DetailedComparator/TitleAndDescription/TitleAndDescription';
import { TranslationKey, Product } from 'shared/constants';
import { AddVehicleForm, VehicleSelector, RunningCosts } from 'features/detailed-comparator';
import Modal from 'shared/components/Modal';
import { initialState, reducer, ActionType } from 'features/detailed-comparator/reducers/index';
import { FormValues } from 'features/detailed-comparator/types';
import { useTranslation } from 'next-i18next';
import { VrmLookup } from 'features/add-my-vehicle';
import isComparisonSet from 'features/detailed-comparator/utils/isEmptyComparison';
import Prompt from 'shared/components/Prompt';
import { useRecalculate } from 'features/detailed-comparator/hooks/useRecalculate';
import clearComparison from 'features/detailed-comparator/utils/clearComparison';
import getVrmVehicleIndexes from 'features/detailed-comparator/utils/getVrmVehicleIndexes';
import isUndefined from 'lodash/isUndefined';
import { formatParameter } from 'shared/utils/formatParameter';
import { VehicleTestTypeModalProvider } from 'shared/hooks/useVehicleTestTypeModal';

import {
  DEFAULT_BUSINESS_FLEET_MANAGER_MILEAGE,
  DEFAULT_ELECTRIC_FUEL_PRICE,
  DEFAULT_FINANCE_PERIOD_IN_YEARS,
  DEFAULT_MONTHLY_CLEAN_AIR_ZONE_ENTRIES,
  DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
  DEFAULT_TAX_RATE,
  MAX_DAYS_IN_YEAR,
  MAX_LEASE_TERM,
  MAX_MILEAGE,
  MIN_LEASE_TERM,
  MIN_MILEAGE,
} from 'services/helpers/calculations/constants';
import AddVehicleOptionsForm from 'features/change-vehicle/components/AddVehicleOptionsForm';
import { useComparison } from 'features/vehicle-comparisons';
import { usePlausible } from 'next-plausible';
import uniq from 'lodash/uniq';
import { FeatureToggles } from 'shared/constants/features';
import { useFeature } from 'shared/hooks/useFeature';
import makeOptions from 'features/detailed-comparator/utils/makeOptions';
import { Attributes, AttributeInterface } from 'features/detailed-comparator/components/Attributes/Attributes';
import { AttributesNote } from 'features/detailed-comparator/components/Attributes/AttributesNote';
import { useFuelCost } from 'shared/hooks/useFuelCosts';
import { TestTypeInformationStandAloneModal } from 'features/TestTypeInformation/TestTypeInformationStandAloneModal';

interface Props {
  query: ParsedUrlQuery;
  product: Product;
}

const TabHeaderWrapper = styled.div<{ isStickyDisabled: boolean; shouldHideTabHeaderWrapper: boolean; top?: number }>`
  ${({ isStickyDisabled, shouldHideTabHeaderWrapper, theme, top }) => `
      display: block;
      position: ${isStickyDisabled ? 'unset' : 'sticky'};
      top: ${top ?? '0'}px;
      z-index: 2;
      margin: ${theme.components.tabHeader.margin.mobile};
      background-color: ${theme.components.tabHeader.backgroundColor.mobile};
      opacity: ${shouldHideTabHeaderWrapper ? '0' : '1'};

      @media screen and (min-width: ${theme.viewports.desktop}) {
        margin: ${theme.components.tabHeader.margin.desktop};
        background-color: ${theme.components.tabHeader.backgroundColor.desktop};
      }
  `}
`;

const TitleAndDescriptionDesktopWrapper = styled.div`
  ${({ theme }) => `
    display: none;
    @media screen and (min-width: ${theme.viewports.desktop}) {
      display: flex;
    };
  `}
`;

const TitleAndDescriptionMobileWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    @media screen and (min-width: ${theme.viewports.desktop}) {
      display: none;
    };
   `}
`;

const StyledVehicleSelector = styled.div<{ isStickyDisabled: boolean; top?: number }>`
  ${({ isStickyDisabled, top }) => `
    display: block;
    position: ${isStickyDisabled ? 'unset' : 'sticky'};
    top: ${top ?? '0'}px;
    z-index: 2;
    pointer-events: none;
  `}
`;

const RunningCostsAndAttributesWrapper = styled.div`
  ${({ theme }) => `
      margin: ${theme.components.runningCostTable.margin.mobile};

      @media screen and (min-width: ${theme.viewports.tablet}) {
        margin: ${theme.components.runningCostTable.margin.desktop};
      }
    `}
`;

const AddVehicleTitle = styled.h1`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    font: ${theme.components.vehicleOptionsForm.title.font};
    color: ${theme.components.vehicleOptionsForm.title.color};
  `}
`;

const AddVehicleModal = styled(Modal)`
  ${({ theme }) => `
    padding: ${theme.components.findVehicleForm.padding.mobile};

    @media (min-width: 900px) {
      padding: ${theme.components.findVehicleForm.padding.desktop};
    }
  `}
`;

const VEHICLE_POSITIONS = ['first', 'second', 'third'];
const convertPositionToText = (position: number): string => VEHICLE_POSITIONS[position];

const DetailedComparator: React.FC<Props> = ({ query, product }): React.ReactElement => {
  const router = useRouter();
  const fuelCost = useFuelCost();

  const initialStateWithFuelCostsUpdated = {
    ...initialState,
    formValues: {
      ...initialState.formValues,
      fuelPrice: fuelCost.private.petrol,
    },
  };

  const [state, dispatch] = useReducer(reducer, initialStateWithFuelCostsUpdated);

  const brand = useBrand();
  const [windowWidth] = useWindowSize();

  const showTestTypeModalForThisRoute = useFeature(
    FeatureToggles.FEATURE_SHOW_TEST_TYPE_MODAL_IN_CHOOSE_VEHICLE,
    brand
  );

  const headerOffset = Number(formatParameter(query?.headerOffset, '0'));
  const firstVehicleParam = query?.first ? formatParameter(query?.first) : null;
  const secondVehicleParam = query?.second ? formatParameter(query?.second) : null;
  const thirdVehicleParam = query?.third ? formatParameter(query?.third) : null;
  const journeyType = query?.journeyType ? formatParameter(query?.journeyType) : null;
  const selectedTab = query?.selectedTab ? formatParameter(query?.selectedTab) : null;
  const { formPosition } = state;
  const paramsForFormPositions = VEHICLE_POSITIONS.map((position) => getObjectFromQueryParam(query[position]) || '');
  const currentMileageForFormPosition = paramsForFormPositions[formPosition][VehicleQueryParams.CURRENT_MILEAGE] ?? '';
  const isCurrentFormPositionVRM = Boolean(paramsForFormPositions[formPosition][VehicleQueryParams.IS_VRM]);

  const { t: translate } = useTranslation(`${brand}.${product}`);
  const { logEvent } = useAnalytics();
  const plausible = usePlausible();

  const offset = headerOffset;

  const [activeTab, setActiveTab] = useState(InitialActiveTab[brand]);
  const [hasChooseVehicle, setHasChooseVehicle] = useState<boolean>();
  const [validVehicle, setValidVehicle] = useState<boolean>();
  const [tabsClicked, setTabsClicked] = useState([TabItems[brand][0]]);
  const [plausibleTriggeredForTwoTabsClicks, setPlausibleTriggeredForTwoTabsClicks] = useState<boolean>(false);
  const [plausibleTriggeredForMaxTabsClicks, setPlausibleTriggeredForMaxTabsClicks] = useState<boolean>(false);
  const [hasTestTypeBeenSeen, setHasTestTypeBeenSeen] = useState<boolean>(false);
  const [showTestProcedureModal, setShowTestProcedureModal] = useState<boolean>(false);
  const [testType, setTestType] = useState<ModalTestType>('WLTP');
  const [testTypesViewed, setTestTypesViewed] = useState<ModalTestType[]>([]);
  const [stickyTop, setStickyTop] = useState(0);
  const [isVehicleSelectorOutOfViewport, setIsVehicleSelectorOutOfViewport] = useState(false);
  const tabHeaderRef = useRef<HTMLInputElement>(null);

  const [initialFormValues, setInitialFormValues] = useState({
    averageMileage: DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
    fuelPrice: fuelCost.private.petrol,
    electricityRate: DEFAULT_ELECTRIC_FUEL_PRICE.toString(),
    cleanAirZone: {
      defaultEntries: DEFAULT_MONTHLY_CLEAN_AIR_ZONE_ENTRIES,
      maxEntriesPerYear: MAX_DAYS_IN_YEAR,
    },
    financePeriodInYears: DEFAULT_FINANCE_PERIOD_IN_YEARS,
    taxRate: DEFAULT_TAX_RATE,
    leaseTerm: {
      min: MIN_LEASE_TERM,
      max: MAX_LEASE_TERM,
    },
    mileage: {
      min: MIN_MILEAGE,
      max: MAX_MILEAGE,
    },
  });

  const getOptions = (): AttributeInterface[] => {
    const key = activeTab.init === true ? null : activeTab.value;
    switch (key) {
      case Tab.KEY_SPECIFICATION:
      case Tab.ENVIRONMENTAL:
      case Tab.TECHNOLOGY:
      case Tab.SAFETY:
      case Tab.PERFORMANCE:
        return makeOptions(state.comparisons, brand, key, translate);
      default:
        return [];
    }
  };

  const isInitialState = !isComparisonSet(state.comparisons);

  const [isLoading, setIsLoading] = useState(false);

  const [isRecalculationError, setIsRecalculationError] = useState(false);

  useEffect(() => {
    isInitialState && setActiveTab(InitialActiveTab[brand]);
  }, [isInitialState]);

  const setComparison = (
    vehicle: ComparisonResponse | undefined,
    position: number,
    matchingParam: string | null = null
  ): void => {
    if (vehicle) {
      dispatch({
        type: ActionType.SET_COMPARISONS,
        formPosition: position,
        data: vehicle,
        isVrm: Boolean(paramsForFormPositions[position][VehicleQueryParams.IS_VRM]),
      });
    }

    if (matchingParam !== null && vehicle === undefined) {
      logEvent(`/invalid-pre-selected-vehicle/${matchingParam}`);
    }
  };

  const comparisonBody = {
    ...state.formValues,
    averageMileage:
      journeyType === 'business-fleet'
        ? String(DEFAULT_BUSINESS_FLEET_MANAGER_MILEAGE)
        : String(DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE),
  };

  const getDecodedParam = (param) => {
    const params = new URLSearchParams(param);
    const decodedParam = [
      params.get(VehicleQueryParams.VEHICLE_ID),
      params.get(VehicleQueryParams.REGISTRATION_YEAR),
    ].filter(Boolean);
    return (
      param &&
      (decodedParam.length
        ? decodedParam
        : decodeURIComponent(param)
            .split(',')
            .map((value) => value.trim()))
    );
  };

  const { isLoading: isVehicleOneLoading, data: vehicleOne } = useComparison(
    getDecodedParam(firstVehicleParam),
    brand,
    product,
    'POST',
    {
      ...comparisonBody,
      currentMileage: new URLSearchParams(firstVehicleParam || '').get(VehicleQueryParams.CURRENT_MILEAGE),
    }
  );
  const { isLoading: isVehicleTwoLoading, data: vehicleTwo } = useComparison(
    getDecodedParam(secondVehicleParam),
    brand,
    product,
    'POST',
    {
      ...comparisonBody,
      currentMileage: new URLSearchParams(secondVehicleParam || '').get(VehicleQueryParams.CURRENT_MILEAGE),
    }
  );
  const { isLoading: isVehicleThreeLoading, data: vehicleThree } = useComparison(
    getDecodedParam(thirdVehicleParam),
    brand,
    product,
    'POST',
    {
      ...comparisonBody,
      currentMileage: new URLSearchParams(thirdVehicleParam || '').get(VehicleQueryParams.CURRENT_MILEAGE),
    }
  );

  useEffect(() => {
    if (firstVehicleParam === null && secondVehicleParam === null && thirdVehicleParam === null) {
      const { pathname, replace, query } = router;
      const { journeyType, selectedTab, ...newQuery } = query;
      replace({ pathname, query: { ...newQuery } }, undefined, { shallow: true });
    }
  }, []);

  useEffect(() => {
    if (!isVehicleOneLoading && !isVehicleTwoLoading && !isVehicleThreeLoading) {
      setComparison(vehicleOne, 0, firstVehicleParam);
      setComparison(vehicleTwo, 1, secondVehicleParam);
      setComparison(vehicleThree, 2, thirdVehicleParam);

      if (isInitialState && (vehicleOne || vehicleTwo || vehicleThree)) {
        const formattedSelectedTab = selectedTab?.replace(/_/g, '-');

        TABS[brand][formattedSelectedTab]
          ? setActiveTab(TABS[brand][formattedSelectedTab])
          : setActiveTab(TABS[brand][TabItems[brand][0]]);

        const journeyTypes: string[] = Object.values(JourneyTypeParam);

        if (journeyTypes.includes(journeyType ?? '')) {
          const paramConversion = {
            private: JourneyType.PRIVATE,
            'business-fleet': JourneyType.BUSINESS_FLEET_MANAGER,
            'business-company': JourneyType.BUSINESS_COMPANY_CAR_DRIVER,
          };
          dispatch({
            type: ActionType.SET_SELECTED_RUNNING_COSTS_TAB,
            selectedRunningCostsTab: paramConversion[journeyType!],
          });
        } else if (journeyType !== null) {
          logEvent(`/invalid-journey-type-param/${journeyType}`);
        }
      }
    }
  }, [isVehicleOneLoading, isVehicleTwoLoading, isVehicleThreeLoading]);

  useEffect(() => {
    if (state.shouldUpdate) {
      const recalculate = async (): Promise<void> => {
        const data = await useRecalculate(state.comparisons, state.formValues, brand, product);

        if (data?.response != null && data?.response.length > 0) {
          data.response.map((comparison) => {
            dispatch({
              type: ActionType.SET_RECALCULATION,
              comparison: comparison.data,
              index: comparison.index,
            });
          });

          setIsRecalculationError(false);
        }

        if (!isUndefined(data.error)) {
          dispatch({
            type: ActionType.SET_SHOULD_UPDATE,
            shouldUpdate: false,
          });

          setIsRecalculationError(true);
        }

        setIsLoading(false);
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      recalculate();
    }
  }, [state.shouldUpdate]);

  useEffect(() => {
    const uniqueItems = uniq(tabsClicked);
    if (
      (uniqueItems.length === 3 && plausibleTriggeredForTwoTabsClicks === false) ||
      (uniqueItems.length === TabItems[brand].length && plausibleTriggeredForMaxTabsClicks === false)
    ) {
      if (uniqueItems.length === 3) {
        plausible('two_additional_tabs_viewed');
        setPlausibleTriggeredForTwoTabsClicks(true);
      } else {
        plausible('all_tabs_viewed');
        setPlausibleTriggeredForMaxTabsClicks(true);
      }
    }
  }, [tabsClicked]);

  const handleShowAddVehicleForm = (formPosition: number, isComparisonSet: boolean, isVrmComparison: boolean): void => {
    if (formPosition === 0 || (isComparisonSet && !isVrmComparison)) {
      dispatch({
        type: ActionType.SET_IS_ADD_VEHICLE_FORM_VISIBLE,
        isAddVehicleFormVisible: true,
      });
    } else {
      dispatch({
        type: ActionType.SET_IS_ADD_VEHICLE_OPTIONS_FORM_VISIBLE,
        isAddVehicleOptionsFormVisible: true,
      });
    }

    dispatch({
      type: ActionType.SET_FORM_POSITION,
      formPosition,
    });

    dispatch({
      type: ActionType.SET_IS_INITIAL_SLOT,
      isInitialSlot: formPosition === 0,
    });

    if (isComparisonSet && isVrmComparison) {
      handleChooseOption(true);
    }
  };

  const handleCloseAddVehicleForm = (event: KeyboardEvent | React.SyntheticEvent): void => {
    event?.preventDefault?.();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    logEvent('/add-vehicle-form/close');

    dispatch({
      type: ActionType.SET_IS_ADD_VEHICLE_FORM_VISIBLE,
      isAddVehicleFormVisible: false,
    });
    setHasChooseVehicle(false);
    setValidVehicle(false);
  };

  const brandConfig = useBrandConfig()[product];
  const { earliestRegistrationYearPermitted } = brandConfig;
  const shouldPutRegistrationYearInQuery = Boolean(earliestRegistrationYearPermitted);
  const handleShowVehicleFormSubmission = async (
    data: ComparisonResponse,
    vehicleId: string,
    registrationYear: string
  ): Promise<void> => {
    try {
      await logEvent(`/add-vehicle-form/submit/${vehicleId}`);

      dispatch({
        type: ActionType.SET_FORM_VALUES,
        formValues: {
          ...state.formValues,
          averageMileage: String(DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE),
        },
      });

      dispatch({
        type: ActionType.SET_COMPARISONS,
        formPosition,
        data,
        isVRM: Boolean(paramsForFormPositions[formPosition][VehicleQueryParams.IS_VRM]),
        clearComparison: false,
      });

      const { pathname, replace, query } = router;
      if (shouldPutRegistrationYearInQuery) {
        query[convertPositionToText(state.formPosition)] = new URLSearchParams({
          [VehicleQueryParams.VEHICLE_ID]: `${vehicleId}`,
          [VehicleQueryParams.REGISTRATION_YEAR]: `${registrationYear}`,
        }).toString();
      } else {
        query[convertPositionToText(state.formPosition)] = new URLSearchParams({
          [VehicleQueryParams.VEHICLE_ID]: `${vehicleId}`,
        }).toString();
      }
      replace({ pathname, query }, undefined, { shallow: true });
      const { hasEVTestType, hasPhevTestType } = brandConfig;
      const { fuel, manufacturer, model, testType } = data.comparisons[0];
      const fuelType =
        hasPhevTestType && fuel === FuelType.PLUG_IN_HYBRID
          ? SyntheticTestTypes.PHEV
          : (hasEVTestType && fuel === FuelType.ELECTRIC && SyntheticTestTypes.EV) || testType;

      if (!testTypesViewed.includes(fuelType)) {
        setHasTestTypeBeenSeen(false);
      }
      setTestType(fuelType);
      setShowTestProcedureModal(true);

      setInitialFormValues({
        ...initialFormValues,
        averageMileage: DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
      });

      dispatch({
        type: ActionType.SET_IS_ADD_VEHICLE_FORM_VISIBLE,
        isAddVehicleFormVisible: false,
      });

      if (isInitialState) {
        setActiveTab(TABS[brand][TabItems[brand][0]]);
      }

      let plausibleGoal: string = '';
      switch (state.formPosition) {
        case 0:
          plausibleGoal = 'brand_vehicle_added';
          break;
        case 1:
          plausibleGoal = 'comparision_slot_two_added';
          break;
        case 2:
          plausibleGoal = 'comparision_slot_three_added';
          break;
        default:
          break;
      }
      if (plausibleGoal !== '') {
        plausible(plausibleGoal, {
          props: {
            capId: vehicleId,
            make: manufacturer,
            vehicle: model,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SET_IS_SHOW_MODAL_ERROR,
        isShowModalError: true,
      });
    }
  };

  const handleChangeFormType = async (activeFormType: FormTypes): Promise<void> => {
    await logEvent(`/add-vehicle-form/menu/${activeFormType}`);

    dispatch({
      type: ActionType.SET_ACTIVE_FORM_TYPE,
      activeFormType,
    });
  };

  const handleVrmConfirmation = async (data: Comparison, vrm: string, value: string): Promise<void> => {
    await logEvent(`/add-vehicle-form/mileage/${value}`);

    const { pathname, replace, query } = router;
    const { id, registrationYear } = data;

    query[convertPositionToText(state.formPosition)] = new URLSearchParams({
      [VehicleQueryParams.VEHICLE_ID]: `${id}`,
      [VehicleQueryParams.REGISTRATION_YEAR]: `${registrationYear}`,
      [VehicleQueryParams.CURRENT_MILEAGE]: value || '',
      [VehicleQueryParams.IS_VRM]: 'true',
    }).toString();

    replace({ pathname, query }, undefined, { shallow: true });

    const comparisonData = { comparisons: [{ ...data, currentMileage: value }] };

    dispatch({
      type: ActionType.SET_FORM_VALUES,
      formValues: {
        ...state.formValues,
        averageMileage: String(DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE),
      },
    });

    dispatch({
      type: ActionType.SET_COMPARISONS,
      formPosition: state.formPosition,
      data: comparisonData,
      clearComparison: false,
      vrm,
    });
    const fuelType = brandConfig?.hasPhevTestType && data.fuel === FuelType.PLUG_IN_HYBRID ? 'PHEV' : data.testType;

    if (!testTypesViewed.includes(fuelType)) {
      setHasTestTypeBeenSeen(false);
    }

    setTestType(fuelType);
    setShowTestProcedureModal(true);

    setInitialFormValues({
      ...initialFormValues,
      averageMileage: DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
    });

    dispatch({
      type: ActionType.SET_SELECTED_RUNNING_COSTS_TAB,
      selectedRunningCostsTab:
        state.selectedRunningCostsTab === JourneyType.BUSINESS_FLEET_MANAGER
          ? JourneyType.PRIVATE
          : state.selectedRunningCostsTab,
    });

    dispatch({
      type: ActionType.SET_IS_ADD_VEHICLE_FORM_VISIBLE,
      isAddVehicleFormVisible: false,
    });

    setHasChooseVehicle(false);
    setValidVehicle(false);
    if (activeTab?.label === TABS[brand]['running-costs'].label) {
      handleRecalculation({
        ...state.formValues,
        averageMileage: String(DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE),
      });
    }

    if (isInitialState) {
      setActiveTab(TABS[brand]['running-costs']);
    }
  };

  const handleShowRemoveVehiclePrompt = (formPosition: number): void => {
    dispatch({
      type: ActionType.SET_FORM_POSITION,
      formPosition,
    });

    dispatch({
      type: ActionType.SET_IS_REMOVE_VEHICLE_PROMPT_VISIBLE,
      isRemoveVehiclePromptVisible: true,
    });
  };

  const handleHideRemoveVehiclePrompt = async (): Promise<void> => {
    await logEvent('/remove-vehicle-prompt/cancel');

    dispatch({
      type: ActionType.SET_IS_REMOVE_VEHICLE_PROMPT_VISIBLE,
      isRemoveVehicleFormVisible: false,
    });
  };

  const handleClearComparison = async (): Promise<void> => {
    await logEvent(`/remove-vehicle-prompt/continue/${state.formPosition + 1}`);

    dispatch({
      type: ActionType.SET_COMPARISONS,
      formPosition: state.formPosition,
      data: null,
      clearComparison: true,
    });

    dispatch({
      type: ActionType.SET_IS_REMOVE_VEHICLE_PROMPT_VISIBLE,
      isRemoveVehicleFormVisible: false,
    });

    const { pathname, replace, query } = router;
    delete query[convertPositionToText(state.formPosition)];
    delete query.selectedTab;
    replace({ pathname, query }, undefined, { shallow: true });
  };

  const handleRecalculation = (formValues: FormValues): void => {
    dispatch({
      type: ActionType.SET_FORM_VALUES,
      formValues,
    });

    dispatch({
      type: ActionType.SET_SHOULD_UPDATE,
      shouldUpdate: true,
    });

    setIsLoading(true);

    setIsRecalculationError(false);
  };

  const handleRunningCostsToggleClick = async (journeyType: JourneyType): Promise<void> => {
    const { pathname, replace, query } = router;
    if (journeyType === JourneyType.PRIVATE) {
      replace({ pathname, query: { ...query, journeyType: JourneyTypeParam.PRIVATE } }, undefined, { shallow: true });
    } else if (journeyType === JourneyType.BUSINESS_COMPANY_CAR_DRIVER) {
      replace({ pathname, query: { ...query, journeyType: JourneyTypeParam.BUSINESS_COMPANY } }, undefined, {
        shallow: true,
      });
    } else if (journeyType === JourneyType.BUSINESS_FLEET_MANAGER) {
      replace({ pathname, query: { ...query, journeyType: JourneyTypeParam.BUSINESS_FLEET } }, undefined, {
        shallow: true,
      });
    }

    if (journeyType === JourneyType.BUSINESS_FLEET_MANAGER) {
      if (getVrmVehicleIndexes(state.comparisons).length === 0) {
        dispatch({
          type: ActionType.SET_SELECTED_RUNNING_COSTS_TAB,
          selectedRunningCostsTab: journeyType,
        });

        handleRecalculation({
          ...state.formValues,
          averageMileage: DEFAULT_BUSINESS_FLEET_MANAGER_MILEAGE.toString(),
        });
      } else {
        await logEvent('/running-costs/toggle/fleet-manager/warning-prompt/open');

        dispatch({
          type: ActionType.SET_SHOW_FLEET_MANAGER_PROMPT,
          isVisible: true,
        });
      }
    }

    if (journeyType !== JourneyType.BUSINESS_FLEET_MANAGER) {
      dispatch({
        type: ActionType.SET_SELECTED_RUNNING_COSTS_TAB,
        selectedRunningCostsTab: journeyType,
      });

      handleRecalculation({
        ...state.formValues,
        averageMileage: DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE.toString(),
      });
    }
  };

  const clearVrmsFromUrl = () => {
    const { pathname, replace, query } = router;
    const newQuery = { ...query };
    VEHICLE_POSITIONS.forEach((position, index) => {
      const isPositionVrm = Boolean(paramsForFormPositions[index][VehicleQueryParams.IS_VRM]);

      if (isPositionVrm) {
        delete newQuery[position];
      }
    });

    replace(
      {
        pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  };

  const handleFleetManagerContinue = async (): Promise<void> => {
    await logEvent('/running-costs/fleet-manager/warning-prompt/continue');

    clearVrmsFromUrl();

    dispatch({
      type: ActionType.SET_CLEAR_VRM_VEHICLES,
      comparisons: clearComparison(state.comparisons),
    });
  };

  const handleFleetManagerCancel = async (): Promise<void> => {
    await logEvent('/running-costs/fleet-manager/warning-prompt/cancel');

    dispatch({
      type: ActionType.SET_SHOW_FLEET_MANAGER_PROMPT,
      isVisible: false,
    });
  };

  const handleClosePopupError = async (): Promise<void> => {
    dispatch({
      type: ActionType.SET_IS_SHOW_MODAL_ERROR,
      isShowModelError: false,
    });
  };

  const handleTabChange = (tab: TabItem): void => {
    const { pathname, replace, query } = router;
    replace(
      {
        pathname,
        query: { ...query, selectedTab: tab.value },
      },
      undefined,
      { shallow: true }
    );

    setActiveTab(tab);
    setTabsClicked([...tabsClicked, tab.value]);
    tab.init = isInitialState;

    dispatch({
      type: ActionType.SET_SELECTED_RUNNING_COSTS_TAB,
      selectedRunningCostsTab: JourneyType.PRIVATE,
    });
  };

  const handleChooseOption = (hasRegistrationNumber: boolean): void => {
    setHasChooseVehicle(hasRegistrationNumber);
    setValidVehicle(false);
    const textChooseVehicle = hasRegistrationNumber ? 'find-vrm-vehicle-selected' : 'find-vehicle-from-list-selected';

    logEvent(`/${textChooseVehicle}`);
    dispatch({
      type: ActionType.SET_IS_ADD_VEHICLE_FORM_VISIBLE,
      isAddVehicleFormVisible: true,
    });

    dispatch({
      type: ActionType.SET_IS_ADD_VEHICLE_OPTIONS_FORM_VISIBLE,
      isAddVehicleOptionsFormVisible: false,
    });
  };

  const handleCloseAddVehicleOptionsForm = (): void => {
    dispatch({
      type: ActionType.SET_IS_ADD_VEHICLE_OPTIONS_FORM_VISIBLE,
      isAddVehicleOptionsFormVisible: false,
    });
  };

  const messagesVehicleStep = validVehicle
    ? 'add-vehicle-form.header-tabs.compare-vehicle.vehicle-match'
    : 'add-vehicle-form.header-tabs.compare-vehicle.choose-vehicle';

  const handleClosingTestProcedureModal = (testType: ModalTestType) => {
    setHasTestTypeBeenSeen(true);
    setTestTypesViewed([...testTypesViewed, testType]);
  };

  // get height of mobile tab header so we can set the top position of the VehicleSelector
  useEffect(() => {
    const { clientHeight } = tabHeaderRef?.current || {};
    if (clientHeight) {
      setStickyTop(clientHeight);
    }
  }, [isInitialState, windowWidth]);

  const pageTitleKey = 'page-title';
  const pageDescriptionKey = 'page-description';
  const pageTitle = translate(pageTitleKey);
  const pageDescription = translate(pageDescriptionKey);
  const shouldShowTitleAndDescription = pageTitle !== pageTitleKey && pageDescription !== pageDescriptionKey;
  const tabsToShowAttributeFor = [Tab.KEY_SPECIFICATION, Tab.TECHNOLOGY, Tab.SAFETY];
  const shouldShowDefaultAttributeNote = tabsToShowAttributeFor.includes(activeTab.value);

  const attributesNote = (
    <AttributesNote
      title={translate('content.default.disclaimer.heading')}
      body={parse(`
    ${shouldShowDefaultAttributeNote ? `${translate('content.default.disclaimer.content')} ` : ''}${translate(
        'content.default.calculation-note.content'
      )}
  `)}
      listItems={translate('content.default.calculation-note.default-superscript-links', { returnObjects: true })}
    />
  );
  const attributes = getOptions();
  const shouldRenderDefaultContent = activeTab.init || !activeTab.value;
  const shouldRenderRunningCosts = !activeTab.init && activeTab.value === Tab.RUNNING_COSTS;
  const shouldRenderAttributes = !activeTab.init && activeTab.value !== Tab.RUNNING_COSTS && attributes.length;

  const handleVehicleSelectorOutOfViewport = (value: boolean) => {
    setIsVehicleSelectorOutOfViewport(value);
  };

  const isWindowsEdge =
    typeof window !== 'undefined' && navigator.userAgent.indexOf('Windows') !== -1 && /Edg/.test(navigator?.userAgent);

  const { features } = brandConfig || {};
  const { isImageResizeOnStickyDisabled } = features?.vehicleSummary || {};
  const isStickyDisabled = isWindowsEdge || isImageResizeOnStickyDisabled;

  return (
    <VehicleTestTypeModalProvider>
      <div className="content">
        {shouldShowTitleAndDescription ? (
          <TitleAndDescriptionDesktopWrapper>
            <TitleAndDescription title={pageTitle} description={pageDescription} />
          </TitleAndDescriptionDesktopWrapper>
        ) : null}
        <TabHeaderWrapper
          shouldHideTabHeaderWrapper={isVehicleSelectorOutOfViewport}
          isStickyDisabled={isStickyDisabled}
        >
          <TabHeader
            ref={tabHeaderRef}
            items={TabItems[brand]}
            setActiveTab={handleTabChange}
            activeTab={activeTab}
            isInitialState={isInitialState}
            product={product}
          />
        </TabHeaderWrapper>

        {shouldShowTitleAndDescription ? (
          <TitleAndDescriptionMobileWrapper>
            <TitleAndDescription title={pageTitle} description={pageDescription} />
          </TitleAndDescriptionMobileWrapper>
        ) : null}
        {shouldRenderDefaultContent ? <TabDefaultContent /> : null}
        <StyledVehicleSelector top={stickyTop} isStickyDisabled={isStickyDisabled}>
          <VehicleSelector
            comparisons={state.comparisons}
            onClick={handleShowAddVehicleForm}
            onClearComparison={handleShowRemoveVehiclePrompt}
            product={product}
            top={stickyTop}
            onOutOfViewport={handleVehicleSelectorOutOfViewport}
            isStickyDisabled={isStickyDisabled}
          />
        </StyledVehicleSelector>
        {shouldRenderRunningCosts || shouldRenderAttributes ? (
          <RunningCostsAndAttributesWrapper>
            {shouldRenderRunningCosts ? (
              <RunningCosts
                comparisons={state.comparisons}
                initialFormValues={initialFormValues}
                formValues={state.formValues}
                selectedOption={state.selectedRunningCostsTab}
                onToggleClick={handleRunningCostsToggleClick}
                onSubmit={handleRecalculation}
                isLoading={isLoading}
                isRecalculationError={isRecalculationError}
              />
            ) : null}

            {shouldRenderAttributes ? (
              <Attributes
                attributesTitle={activeTab.value ? translate(`tab.${activeTab.value}.label`) : ''}
                attributes={attributes}
                attributesNote={attributesNote}
              />
            ) : null}
          </RunningCostsAndAttributesWrapper>
        ) : null}
      </div>

      {state.isAddVehicleOptionsFormVisible && (
        <Modal onClose={handleCloseAddVehicleOptionsForm} offset={offset}>
          <AddVehicleOptionsForm
            onClick={handleChooseOption}
            onCancel={handleCloseAddVehicleOptionsForm}
            product={Product.DETAILED_COMPARATOR}
          />
        </Modal>
      )}

      {state.isAddVehicleFormVisible && (
        <AddVehicleModal onClose={handleCloseAddVehicleForm} offset={offset}>
          <AddVehicleTitle>
            {translate(
              state.isInitialSlot ? 'add-vehicle-form.header-tabs.initial-slot.choose-model' : messagesVehicleStep
            )}
          </AddVehicleTitle>
          {state.activeFormType === FormTypes.CHANGE_VEHICLE &&
            (hasChooseVehicle || isCurrentFormPositionVRM ? (
              <VrmLookup
                loadedVrm={state.comparisons[state.formPosition].vrm}
                formValues={state.formValues}
                onVrmConfirmation={handleVrmConfirmation}
                currentMileage={currentMileageForFormPosition}
                journeyType={JourneyType.PRIVATE}
                businessJourneyType={BusinessJourneyType.COMPANY_CAR_DRIVER}
                product={product}
                onCancel={handleCloseAddVehicleForm}
                setValidVehicle={setValidVehicle}
              />
            ) : (
              <AddVehicleForm
                key={`vo-${state.formPosition}`}
                formValuesProp={state.formValues}
                onSubmit={handleShowVehicleFormSubmission}
                onCancel={handleCloseAddVehicleForm}
                isInitialSlot={state.formPosition === 0}
                product={product}
                currentVehicle={{
                  manufacturerName: state.comparisons[state.formPosition].comparison.manufacturer,
                  capId: state.comparisons[state.formPosition].comparison.id,
                  fuelType: state.comparisons[state.formPosition].comparison.fuelTypeCategory,
                  modelRaw: state.comparisons[state.formPosition].comparison.modelRaw,
                  modelName: state.comparisons[state.formPosition].comparison.model,
                  registrationYear: state.comparisons[state.formPosition].comparison.registrationYear,
                }}
              />
            ))}
          {state.activeFormType === FormTypes.ADD_MY_VEHICLE && (
            <VrmLookup
              formValues={state.formValues}
              currentMileage={currentMileageForFormPosition}
              onVrmConfirmation={handleVrmConfirmation}
              journeyType={JourneyType.PRIVATE}
              businessJourneyType={BusinessJourneyType.COMPANY_CAR_DRIVER}
              product={product}
              onCancel={handleCloseAddVehicleForm}
              setValidVehicle={setValidVehicle}
            />
          )}
        </AddVehicleModal>
      )}

      {state.isRemoveVehiclePromptVisible && (
        <Modal onClose={handleHideRemoveVehiclePrompt} offset={offset}>
          <Prompt
            text={translate('remove-vehicle.prompt')}
            translationKey={`${brand}.${TranslationKey.DETAILED_COMPARATOR}`}
            onContinue={handleClearComparison}
            onCancel={handleHideRemoveVehiclePrompt}
          />
        </Modal>
      )}

      {state.isFleetManagerPromptVisible && (
        <Modal onClose={handleFleetManagerCancel} offset={offset}>
          <Prompt
            text={translate('fleet-manager-warning-prompt')}
            translationKey={`${brand}.${TranslationKey.DETAILED_COMPARATOR}`}
            onContinue={handleFleetManagerContinue}
            onCancel={handleFleetManagerCancel}
          />
        </Modal>
      )}

      {showTestTypeModalForThisRoute && !hasTestTypeBeenSeen && showTestProcedureModal && (
        <TestTypeInformationStandAloneModal
          testType={testType}
          handleClose={() => handleClosingTestProcedureModal(testType)}
        />
      )}

      <style jsx>
        {`
          .content {
            max-width: 100vw;
            margin: 0 auto;
            overflow: clip;
          }

          .tab-content-wrapper {
            overflow: hidden;
          }
        `}
      </style>
      {state.isShowModalError && (
        <Modal onClose={handleClosePopupError} offset={offset}>
          <Prompt
            text={translate('prompt.show-error')}
            translationKey={`${brand}.${TranslationKey.DETAILED_COMPARATOR}`}
            showIconError
          />
        </Modal>
      )}
    </VehicleTestTypeModalProvider>
  );
};

export default DetailedComparator;
