import {
  CostCalculatorInputLimit,
  FleetManagerCosts,
  OwnershipType,
  VehicleComparison,
} from 'features/detailed-comparator/types';
import React, { Dispatch, SetStateAction, useReducer, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { TranslationKey } from 'shared/constants';
import { formatToCurrency } from 'shared/utils/currency';
import { Attribute, JourneyType, KeyboardKey } from 'shared/types';
import { ONE_YEAR_IN_MONTHS } from 'services/helpers/calculations/constants';
import { useBrand } from 'shared/hooks/useBrand';
import Modal from 'shared/components/Modal';
import { ActionType, initialState, reducer } from 'features/detailed-comparator/reducers';
import FormError from 'shared/components/FormError';
import { SecondaryButton } from 'shared/components/Button';
import styled from 'styled-components';

const OwnershipOwnWrapper = styled.div`
  ${({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${theme.components.monthlyCost.totals.gap.mobile};
  `}
`;

const TitleAndValues = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ${theme.components.detailedComparator.attributes.columnGap};
    grid-row-gap: ${theme.components.monthlyCost.totals.ownTypeOwn.titleAndValuesGap};
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-areas: 'title title' 'value0 value1';

    @media screen and (min-width: ${theme.viewports.tablet}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, 1fr));
      grid-template-areas: 'title title .' 'value0 value1 value2';
    }

    @media screen and (min-width: 1240px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      grid-template-areas: 'title value0 value1 value2';
    }
  `}
`;

const OwnershipOwnTitle = styled.p`
  ${({ theme }) => `
    grid-area: title;
    font: ${theme.components.monthlyCost.totals.ownTypeOwn.title.mobile.font};
  `}
`;

const Value = styled.p<{ position }>`
  ${({ position, theme }) => `
    grid-area: value${position};
    font: ${theme.components.monthlyCost.totals.ownTypeOwn.value.mobile.font};
    justify-self: ${theme.components.detailedComparator.attributes.attributeValue.alignItems.mobile};

    &:last-of-type {
      display: none;

      @media screen and (min-width: ${theme.viewports.tablet}) {
        display: flex;
      }
    }
    @media screen and (min-width: ${theme.viewports.tablet}) {
      justify-self: ${theme.components.detailedComparator.attributes.attributeValue.alignItems.desktop};
    }
  `}
`;

const Totals = styled.div<{ isOwnershipTypeOwn: boolean }>`
  ${({ isOwnershipTypeOwn, theme }) => {
    const { totals } = theme.components.monthlyCost;
    const { gap, ownTypeOwn } = totals;
    const ownShipOwnStyles = `
      padding: ${ownTypeOwn.padding.mobile};
      background-color: ${ownTypeOwn.innerBackgroundColor};

      &:before {
        content: '';
        position: absolute;
        left: -${theme.spacing.lg};
        right: -${theme.spacing.lg};
        top: 0px;
        height: 100%;
        background-color: ${ownTypeOwn.backgroundColor.mobile};
      }

      @media screen and (min-width: 1240px) {
        padding: ${ownTypeOwn.padding.desktop};
        background-color: ${ownTypeOwn.backgroundColor.desktop};

        &:before {
          display: none;
        }
      }
    `;

    return `
      position: relative;
      width: 100%;
      display:flex;
      flex-direction: column;
      gap: ${gap.mobile};

      @media screen and (min-width: 1240px) {
        border-top: 0;
      }

      ${isOwnershipTypeOwn ? ownShipOwnStyles : ``}
    `;
  }}
`;

const Values = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: 'heading heading' 'value0 value1';
    grid-column-gap: ${theme.components.detailedComparator.attributes.columnGap};
    position: relative;

    @media screen and (min-width: ${theme.viewports.tablet}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-areas: 'heading heading heading' 'value0 value1 value2';
    }

    @media screen and (min-width: 1240px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-areas: 'heading value0 value1 value2';
    }
  `}
`;

const VehicleValue = styled.div<{ isOwnershipTypeOwn: boolean; position: number }>`
  ${({ isOwnershipTypeOwn, position, theme }) => {
    const { detailedComparator, monthlyCost } = theme.components;
    const { attributes } = detailedComparator;
    const { value } = monthlyCost.totals;

    return `
      display: flex;
      flex-direction: column;
      flex: 1;
      border-bottom: ${isOwnershipTypeOwn ? '' : attributes.borderBottom};
      justify-content: center;
      align-items: ${attributes.attributeValue.alignItems.mobile};
      padding: ${isOwnershipTypeOwn ? '' : value.padding.mobile};
      grid-area: ${`value${position}`};

      &:last-of-type {
        display: none;

        @media screen and (min-width: ${theme.viewports.tablet}) {
          display: flex;
          flex: 1;
        }
      }

      @media screen and (min-width: ${theme.viewports.tablet}) {
        align-items: ${attributes.attributeValue.alignItems.desktop};
      }
      @media screen and (min-width: 1240px) {
        padding: ${isOwnershipTypeOwn ? '' : value.padding.desktop};
      }
  `;
  }}
`;

const Heading = styled.div<{ isOwnershipTypeOwn?: boolean }>`
  ${({ isOwnershipTypeOwn, theme }) => {
    const { totals } = theme.components.monthlyCost;
    const mobilePadding = isOwnershipTypeOwn ? totals.ownTypeOwn.heading.padding.mobile : totals.heading.padding.mobile;
    const desktopPadding = isOwnershipTypeOwn
      ? totals.ownTypeOwn.heading.padding.desktop
      : totals.heading.padding.desktop;
    const desktopBorderBottom = isOwnershipTypeOwn ? '' : theme.components.detailedComparator.attributes.borderBottom;

    return `
      grid-area: heading;
      padding: ${mobilePadding};
      display: flex;
      flex-direction: column;
      gap: ${totals.heading.flexGap.mobile};

      @media screen and (min-width: 1240px) {
        padding: ${desktopPadding};
        border-bottom: ${desktopBorderBottom};
        gap: ${totals.heading.flexGap.desktop};
      }
    `;
  }}
`;

const HeadingTitle = styled.p`
  ${({ theme }) => {
    const { monthlyCost } = theme.components;
    return `
    font: ${monthlyCost.heading.title.font.mobile};
    color: ${monthlyCost.heading.title.color};

    @media screen and (min-width: 1240px) {
      font-size: ${monthlyCost.font.size};
      padding: ${monthlyCost.heading.title.padding.desktop};
    }
  `;
  }}
`;

const HeadingDescription = styled.p`
  ${({ theme }) => {
    const { heading } = theme.components.monthlyCost;
    return `
    font-size: ${theme.fontSizes.md};
    font: ${heading.description.font.mobile};
    color: ${heading.description.color};
  `;
  }}
`;

const InputWrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  ${({ theme }) => {
    const { inputCell } = theme.components.runningCostTable;
    return `
    border-top: ${inputCell.borderTop};
    border-right: ${inputCell.borderRight};
    border-bottom: ${inputCell.borderBottom};
    border-left: ${inputCell.borderLeft};
    border-radius: ${inputCell.borderRadius};
    text-align: ${inputCell.textAlign};
    width: ${inputCell.width.mobile};
    height: ${inputCell.height.mobile};
    font-size: ${theme.fontSizes.lg};
    -moz-appearance: textfield;
    box-sizing: border-box;
    padding: ${inputCell.padding};

    &:focus {
      outline: ${inputCell.focus.outline};
      border-bottom: ${inputCell.focus.borderBottom};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    @media screen and (min-width: 1240px) {
      width: ${inputCell.width.desktop};
      height: ${inputCell.height.desktop};
    }
  `;
  }}
`;

const Prompt = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${theme.spacing.xxxxl};
  `}
`;

const EVENT_KEYS = [KeyboardKey.BACKSPACE, KeyboardKey.LEFT, KeyboardKey.RIGHT, KeyboardKey.DELETE];

export const makeMonthlyCostAttributes = ({
  comparisons,
  fleetManagerInputValues,
  leaseTerm,
  ownershipType,
}: {
  comparisons: VehicleComparison[];
  fleetManagerInputValues: FleetManagerCosts[];
  leaseTerm: number;
  ownershipType: OwnershipType;
}) =>
  comparisons.map((individualComparison, index) => {
    let residualValue = '';
    let costNewValue = '';
    let assetValue = '';
    let monthlyCostOfAsset = '';

    const repaymentLength = ONE_YEAR_IN_MONTHS * leaseTerm;

    if (individualComparison.isComparisonSet) {
      const roadTax =
        individualComparison.comparison.attributes
          .find((attr) => attr.type === Attribute.ROAD_TAX)
          .values.find((type) => type.type === JourneyType.PRIVATE).value ?? '';

      residualValue = individualComparison.comparison.attributes.find(
        (object: { type: Attribute }) => object.type === Attribute.RESIDUAL_VALUE
      ).value;

      costNewValue =
        Number(fleetManagerInputValues[index][ownershipType]) - Number(residualValue) <= 0
          ? individualComparison.comparison.totalPrice
          : fleetManagerInputValues[index][ownershipType];

      assetValue = (Number(costNewValue) - Number(residualValue) - Number(roadTax)).toString();

      monthlyCostOfAsset = (Number(assetValue) / repaymentLength).toString();
    }

    return { residualValue, assetValue, monthlyCostOfAsset };
  });

interface Props {
  comparisons: VehicleComparison[];
  ownershipType: OwnershipType;
  leaseTerm: number;
  setFleetManagerInputValues: Dispatch<SetStateAction<FleetManagerCosts[]>>;
  fleetManagerInputValues: FleetManagerCosts[];
  selectActive?: string;
  offset?: number;
  monthlyCostAttributes: ReturnType<typeof makeMonthlyCostAttributes>;
}

const MonthlyCostCalculator: React.FC<Props> = ({
  comparisons,
  ownershipType,
  leaseTerm,
  setFleetManagerInputValues,
  fleetManagerInputValues,
  selectActive,
  offset,
  monthlyCostAttributes,
}) => {
  const brand = useBrand();

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const [residualInputValue, setResidualInputValue] = useState(0);

  const handleInputChange = (index: number) => (ev: { target: { value: string } }) => {
    const updatedFleetManagerInputValues = [...fleetManagerInputValues];

    updatedFleetManagerInputValues[index][ownershipType] = ev.target.value;

    setFleetManagerInputValues(updatedFleetManagerInputValues);
  };

  const onBlurHandle =
    (index: number, individualComparison: VehicleComparison, ownershipTypeValue: string) =>
    (ev: { target: { value: string } }) => {
      if (selectActive === OwnershipType.OWN) {
        const updatedFleetManagerInputValues = [...fleetManagerInputValues];

        if (individualComparison.isComparisonSet) {
          const residualValue: string = individualComparison.comparison.attributes.find(
            (object: { type: Attribute }) => object.type === Attribute.RESIDUAL_VALUE
          ).value;
          if (Number(ev.target.value) < Number(residualValue)) {
            setResidualInputValue(Number(residualValue));
            updatedFleetManagerInputValues[index][ownershipType] = ownershipTypeValue;
            dispatch({
              type: ActionType.SET_IS_VALID_NEW_COST_FLEET_MANAGER,
              isValidNewCostFleetManager: false,
            });
          }
        }

        setFleetManagerInputValues(updatedFleetManagerInputValues);
      }
    };

  const handleCloseModalInvalidCostNew = (): void => {
    dispatch({
      type: ActionType.SET_IS_VALID_NEW_COST_FLEET_MANAGER,
      isValidNewCostFleetManager: true,
    });
  };

  const limitInputValue = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const limit = ownershipType === OwnershipType.OWN ? CostCalculatorInputLimit.OWN : CostCalculatorInputLimit.LEASE;

    if (e.currentTarget.value.length >= limit && !EVENT_KEYS.includes(e.key as KeyboardKey)) {
      e.preventDefault();
    }
  };

  const getFleetManagerOwnershipValue = (string: string, startingPrice: string): string => {
    if (string === '') {
      return '';
    }

    if (Number(string) > 0) {
      return string;
    }

    return startingPrice;
  };

  const isOwnershipTypeOwn = ownershipType === OwnershipType.OWN;

  const costLabel =
    ownershipType === OwnershipType.LEASE
      ? 'monthly-cost-calculator.cost-label.lease'
      : 'monthly-cost-calculator.cost-label.own';

  const description =
    ownershipType === OwnershipType.LEASE
      ? 'monthly-cost-calculator.description.lease'
      : 'monthly-cost-calculator.description.own';

  return (
    <Totals isOwnershipTypeOwn={isOwnershipTypeOwn} data-test="monthly-cost-calculator" key={leaseTerm}>
      <Values data-test="value-input">
        <Heading isOwnershipTypeOwn={isOwnershipTypeOwn}>
          <HeadingTitle>{translate(costLabel)}</HeadingTitle>

          <HeadingDescription className="heading__description">{translate(description)}</HeadingDescription>
        </Heading>

        {comparisons.map((individualComparison, index) => (
          <VehicleValue key={index} isOwnershipTypeOwn={isOwnershipTypeOwn} position={index}>
            {individualComparison.isComparisonSet && (
              <InputWrapper>
                <Input
                  type="number"
                  value={
                    ownershipType === OwnershipType.LEASE
                      ? fleetManagerInputValues[index][ownershipType]
                      : getFleetManagerOwnershipValue(
                          fleetManagerInputValues[index][OwnershipType.OWN],
                          individualComparison.comparison.totalPrice
                        )
                  }
                  min={0}
                  onChange={handleInputChange(index)}
                  onBlur={onBlurHandle(index, individualComparison, individualComparison.comparison.totalPrice)}
                  onKeyDown={limitInputValue}
                  data-test="monthly-cost-calculator__input"
                />
              </InputWrapper>
            )}
          </VehicleValue>
        ))}
      </Values>

      {ownershipType === OwnershipType.OWN && (
        <OwnershipOwnWrapper>
          <TitleAndValues>
            <OwnershipOwnTitle>{translate('monthly-cost-calculator.residual-value')}</OwnershipOwnTitle>
            {comparisons.map((_, index) => (
              <Value key={index} position={index}>
                {monthlyCostAttributes[index].residualValue
                  ? formatToCurrency(monthlyCostAttributes[index].residualValue)
                  : ''}
              </Value>
            ))}
          </TitleAndValues>
          <TitleAndValues>
            <OwnershipOwnTitle>{translate('monthly-cost-calculator.asset-value')}</OwnershipOwnTitle>
            {comparisons.map((_, index) => {
              const shouldRenderZero =
                Number(fleetManagerInputValues[index][ownershipType]) !== 0 &&
                Number(fleetManagerInputValues[index][ownershipType]) <=
                  Number(monthlyCostAttributes[index].residualValue);
              return (
                <Value key={index} position={index}>
                  {monthlyCostAttributes[index].assetValue
                    ? formatToCurrency(shouldRenderZero ? '0' : monthlyCostAttributes[index].assetValue)
                    : ''}
                </Value>
              );
            })}
          </TitleAndValues>
        </OwnershipOwnWrapper>
      )}
      {!state.isValidNewCostFleetManager && (
        <Modal onClose={handleCloseModalInvalidCostNew} offset={offset}>
          <Prompt data-test="prompt">
            <FormError
              text={translate('monthly-cost-calculator.invalid.cost-new', {
                residualInputValue,
              })}
            />
            <SecondaryButton
              text={translate('monthly-cost-calculator.invalid.ok')}
              onClick={handleCloseModalInvalidCostNew}
            />
          </Prompt>
        </Modal>
      )}
    </Totals>
  );
};

export default MonthlyCostCalculator;
