import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';
import { TAX_RATES } from 'features/detailed-comparator/components/RunningCosts/constants';
import { InputType } from 'features/detailed-comparator/types';

interface Props {
  label: string;
  helperText: string;
  defaultType: string;
  value: number;
  name: string;
  className: string;
  min: number;
  max: number;
  step: string;
  type: InputType;
  pattern: string;
  title: string;
  formValueLabel: string;
  onChange: (label: string, value: string) => void;
}

const FormItem: React.FC<Props> = ({
  label,
  helperText,
  value,
  defaultType,
  name,
  className,
  min,
  max,
  step,
  pattern,
  type,
  title,
  formValueLabel,
  onChange,
}) => {
  const theme = useTheme();
  const formItemTheme = theme.components.formItem;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    onChange(formValueLabel, e.target.value);
  };

  return (
    <div className={`form-item ${className}`}>
      <div className="form-item__inner">
        <label className="form-item__label" htmlFor={name}>
          {label}
        </label>
        <span className="form-item__helper-text" id={`${name}-described-by`}>
          {helperText}
        </span>

        {type === InputType.INPUT && (
          <input
            type={defaultType}
            name={name}
            id={name}
            value={value}
            min={min}
            max={max}
            step={step}
            aria-describedby={`${name}-described-by`}
            className="form-item__input input"
            pattern={pattern}
            title={title}
            required
            onChange={handleChange}
          />
        )}

        {type === InputType.SELECT && (
          <div className="select">
            <select className="form-item__input" id={name} onChange={handleChange}>
              {TAX_RATES.map((rate, index) => (
                <option value={rate.value} key={index}>
                  {rate.label}
                </option>
              ))}
            </select>

            <span className="select__arrow" />
          </div>
        )}
      </div>

      <style jsx>
        {`
          .form-item {
            margin-bottom: ${formItemTheme.margin};
          }

          .form-item:last-of-type {
            margin-bottom: ${formItemTheme.margin};
          }

          .form-item__label {
            display: block;
            font-size: ${theme.fontSizes.lg};
            margin-bottom: 0.2rem;
            color: ${formItemTheme.label.color};
            font-weight: ${theme.components.form.label.fontWeight};
          }

          .form-item__helper-text {
            display: block;
            margin-bottom: ${theme.spacing.md};
            color: ${formItemTheme.hint.color};
            font-size: ${theme.fontSizes.sm};
          }

          .form-item__input::-webkit-outer-spin-button,
          .form-item__input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          .form-item__input {
            padding: ${formItemTheme.input.padding};
            width: 100%;
            height: ${theme.components.recalculateForm.input.height};
            border-top: ${theme.components.form.border.top};
            border-right: ${theme.components.form.border.right};
            border-bottom: ${theme.components.form.border.bottom};
            border-left: ${theme.components.form.border.left};
            color: ${formItemTheme.input.color};
          }

          .input {
            -moz-appearance: textfield;
          }

          .select {
            position: relative;
          }

          .select select {
            background-color: ${theme.colors.secondary};
            -webkit-appearance: none;
            appearance: none;
            -webkit-border-radius: 0;
            border-radius: 0;
          }

          .select select.padding-left {
            padding-left: ${theme.spacing.xs};
          }

          .select__arrow {
            position: absolute;
            top: 4px;
            right: 2px;
            display: block;
            width: 22px;
            height: 22px;
            border: 2px solid transparent;
            border-radius: 100px;
            color: ${theme.colors.primary};
            pointer-events: none;
          }

          .select__arrow::after {
            content: '';
            display: block;
            box-sizing: border-box;
            position: absolute;
            width: 10px;
            height: 10px;
            border-bottom: 2px solid;
            border-right: 2px solid;
            transform: rotate(45deg);
            top: 10px;
          }

          @media screen and (min-width: ${theme.viewports.desktop}) {
            .form-item {
              margin: 0 ${theme.spacing.xs};
            }

            .form-item__input {
              padding: ${formItemTheme.input.padding};
            }

            .form-item:first-of-type {
              margin-left: 0;
            }

            .form-item:last-of-type {
              margin-right: calc(${theme.spacing.xs} * 2);
            }

            .form-item--large {
              width: 185px;
            }

            .form-item--clean-air-zone-entries {
              width: 250px;
            }

            .form-item--small {
              width: 120px;
            }

            .form-item--own-lease-fleet-manager {
              width: 190px;
            }

            .form-item__label {
              font-size: ${theme.fontSizes.md};
            }
          }

          @media screen and (min-width: 1240px) {
            .form-item--clean-air-zone-entries {
              width: 250px;
            }

            .form-item__label {
              font-size: ${theme.fontSizes.lg};
            }
          }
        `}
      </style>
    </div>
  );
};

export default FormItem;
