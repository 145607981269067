import React from 'react';
import { Container } from 'shared/components/Container';
import { Tooltip } from 'shared/components/Tooltip';
import styled from 'styled-components';
import { DataBubbles, TestTypes } from 'shared/types';
import { NEDCFlag } from 'features/detailed-comparator/components/NEDCFlag/NEDCFlag';
import { useVehicleTestTypeModal } from 'shared/hooks/useVehicleTestTypeModal';
import {
  AttributesToShowNEDCLabelFor,
  VehicleOptionHeadings,
} from 'features/detailed-comparator/components/DataTable/constants';
import { useFeature } from 'shared/hooks/useFeature';
import { FeatureToggles } from 'shared/constants/features';
import { useBrand } from 'shared/hooks/useBrand';
import { useTooltipAnalytics } from 'shared/hooks/useTooltipAnalytics';
import { Trans } from 'next-i18next';
import { TranslationKey } from 'shared/constants';

const AttributesWrapper = styled(Container)`
  flex-direction: column;
`;

const AttributesTitle = styled.h2`
  ${({ theme }) => `
    font: ${theme.components.detailedComparator.attributes.title.font.mobile};
    margin-bottom: ${theme.components.detailedComparator.attributes.title.marginBottom.mobile};

    @media screen and (min-width: 1240px) {
      font: ${theme.components.detailedComparator.attributes.title.font.desktop};
      margin-bottom: ${theme.components.detailedComparator.attributes.title.marginBottom.desktop};
    }
  `}
`;

const AttributesList = styled.div<{ shouldHideTopBorder?: boolean }>`
  ${({ shouldHideTopBorder, theme }) => `
  ${!shouldHideTopBorder ? `border-top:  ${theme.components.detailedComparator.attributes.borderTop};` : ''}

    display: flex;
    flex-direction: column;
    padding: ${theme.components.detailedComparator.attributes.list.padding.mobile};
    gap: ${theme.components.detailedComparator.attributes.list.flexGap.mobile};

    @media screen and (min-width: 1240px) {
      border-top: 0;
      padding: ${theme.components.detailedComparator.attributes.list.padding.desktop};
      gap: ${theme.components.detailedComparator.attributes.list.flexGap.desktop};
    }
  `}
`;

const Attribute = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, fit-content(100%));
    grid-row-gap: ${theme.components.detailedComparator.attributes.rowGap};
    grid-template-areas: 'attributeTitle attributeTitle' 'value0 value1';
    grid-column-gap: ${theme.components.detailedComparator.attributes.columnGap};
    align-items: center;
    min-height: ${theme.components.detailedComparator.attributes.minHeight.mobile};

    @media screen and (min-width: ${theme.viewports.tablet}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(2, fit-content(100%));
      grid-template-areas: 'attributeTitle attributeTitle attributeTitle' 'value0 value1 value2';
      border-bottom: 0;
      padding: 0;
    }

    @media screen and (min-width: 1240px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
      grid-template-areas: 'attributeTitle value0 value1 value2';
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      border-bottom: 0;
      min-height: unset;
    }
  `}
`;

const AttributeTitle = styled.div<{ attributeIndex: number; shouldHideTopBorder?: boolean }>`
  ${({ attributeIndex, shouldHideTopBorder, theme }) => `
    grid-area: attributeTitle;
    font: ${theme.components.detailedComparator.attributes.attributeTitle.font};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    @media screen and (min-width: 1240px) {
      min-height: ${theme.components.detailedComparator.attributes.minHeight.desktop};
      margin-bottom: 0;
      border-bottom: ${theme.components.detailedComparator.attributes.borderBottom};
      ${
        attributeIndex === 0 && !shouldHideTopBorder
          ? `border-top:  ${theme.components.detailedComparator.attributes.borderTop};`
          : ''
      }
    }
  `}
`;

export const AttributeValue = styled.p<{ position: number; attributeIndex: number; shouldHideTopBorder?: boolean }>`
  ${({ attributeIndex, position, shouldHideTopBorder, theme }) => `
    grid-area: value${position};
    display: flex;
    align-items: ${theme.components.detailedComparator.attributes.attributeValue.alignItems.mobile};
    justify-content: ${theme.components.detailedComparator.attributes.attributeValue.justifyContent.mobile};
    flex-direction: column;
    height: 100%;
    font: ${theme.components.detailedComparator.attributes.attributeValue.font};
    border-bottom: ${theme.components.detailedComparator.attributes.borderBottom};
    padding: ${theme.components.detailedComparator.attributes.attributeValue.padding};

    &:last-child {
      display: none;

      @media screen and (min-width: ${theme.viewports.tablet}) {
        display: flex;
      }
    }

    @media screen and (min-width: ${theme.viewports.tablet}) {
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: ${theme.components.detailedComparator.attributes.attributeValue.justifyContent.desktop};
    }

    @media screen and (min-width: 1240px) {
      display: flex;
      padding: ${theme.components.detailedComparator.attributes.value.padding.desktop};
      ${
        attributeIndex === 0 && !shouldHideTopBorder
          ? `border-top:  ${theme.components.detailedComparator.attributes.borderTop};`
          : ''
      }
    }
  `}
`;

export const AttributeValues = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: 'value0 value1';
    gap: ${theme.components.detailedComparator.attributes.columnGap};

    @media screen and (min-width: ${theme.viewports.tablet}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-areas: 'value0 value1 value2';
    }
    `}
`;

const AttributeTooltip = styled(Tooltip)`
  margin-left: 4px;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AttributeTooltipLabel = styled.div`
  ${({ theme }) => `
    font: ${theme.components.detailedComparator.attributes.tooltipLabel.font};
    color: ${theme.components.detailedComparator.attributes.tooltipLabel.color};
  `}
`;
const AttributeTooltipContent = styled.div`
  ${({ theme }) => `
    font: ${theme.components.detailedComparator.attributes.tooltipContent.font};
    color: ${theme.components.detailedComparator.attributes.tooltipContent.color};
  `}
`;

const StyledNEDCFlag = styled(NEDCFlag)`
  padding: 0;
`;

export interface AttributeInterface {
  title: VehicleOptionHeadings | DataBubbles;
  tooltipContent: string;
  tooltipLabel: string;
  values: { value: string; testType: string }[];
}

interface Props {
  attributes: AttributeInterface[];
  attributesTitle?: string;
  attributesNote?: React.ReactNode;
  children?: React.ReactNode;
  shouldHideTopBorder?: boolean;
  shouldHideAttributesTitle?: boolean;
}

export const Attributes: React.FC<Props> = ({
  shouldHideTopBorder,
  attributesTitle = '',
  attributes = [],
  children = null,
  attributesNote,
  shouldHideAttributesTitle,
}) => {
  const { openModal } = useVehicleTestTypeModal();
  const brand = useBrand();
  const tooltipAnalytics = useTooltipAnalytics({ analyticsPrefix: attributesTitle });
  const isNEDCFlagFeatureEnabled = useFeature(FeatureToggles.FEATURE_SHOW_NEDC_FLAG, brand);
  return (
    <AttributesWrapper>
      {shouldHideAttributesTitle || !attributesTitle ? null : <AttributesTitle>{attributesTitle}</AttributesTitle>}
      {children}
      <AttributesList shouldHideTopBorder={shouldHideTopBorder}>
        {attributes.map((attribute, attributeIndex) => {
          const { title, tooltipContent, tooltipLabel, values = [] } = attribute;

          if (!title) {
            return null;
          }

          return (
            <Attribute key={attributeIndex}>
              <AttributeTitle attributeIndex={attributeIndex} shouldHideTopBorder={shouldHideTopBorder}>
                {title}
                {tooltipLabel || tooltipContent ? (
                  <AttributeTooltip
                    dataTest={`${attributesTitle}-${attribute.title}`}
                    onOpen={tooltipAnalytics(attribute.title)}
                  >
                    <TooltipContent>
                      {tooltipLabel ? (
                        <AttributeTooltipLabel>
                          <Trans i18nKey={tooltipLabel} ns={`${brand}.${TranslationKey.DETAILED_COMPARATOR}`} />
                        </AttributeTooltipLabel>
                      ) : null}
                      {tooltipContent ? (
                        <AttributeTooltipContent>
                          <Trans i18nKey={tooltipContent} ns={`${brand}.${TranslationKey.DETAILED_COMPARATOR}`} />
                        </AttributeTooltipContent>
                      ) : null}
                    </TooltipContent>
                  </AttributeTooltip>
                ) : null}
              </AttributeTitle>

              {values.map((currentValue, index) => {
                const { value, testType } = currentValue || {};
                const shouldShowNEDCFlag =
                  isNEDCFlagFeatureEnabled &&
                  testType === 'NEDC' &&
                  AttributesToShowNEDCLabelFor.some((attributeTitle) => attributeTitle === title);
                return (
                  <AttributeValue
                    key={index}
                    position={index}
                    attributeIndex={attributeIndex}
                    shouldHideTopBorder={shouldHideTopBorder}
                  >
                    {value}
                    {shouldShowNEDCFlag ? (
                      <StyledNEDCFlag
                        onClick={() => {
                          openModal(TestTypes.NEDC);
                        }}
                      />
                    ) : null}
                  </AttributeValue>
                );
              })}
            </Attribute>
          );
        })}
      </AttributesList>
      {attributesNote ? <div>{attributesNote}</div> : null}
    </AttributesWrapper>
  );
};
