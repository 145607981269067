import { Attribute, Brand, DataBubbles, VehicleOptions } from 'shared/types';

export enum VehicleOptionHeadings {
  SMARTPHONE_INTEGRATION = 'Smartphone Integration',
  AUTOMATIC_HEADLIGHTS = 'Automatic Headlights',
  SATELLITE_NAVIGATION = 'Satellite Navigation',
  TOUCHSCREEN_MEDIA_DISPLAY = 'Touchscreen Media Display',
  WIRELESS_MOBILE_PHONE_CHARGING = 'Wireless Mobile Phone Charging',
  USB_PORTS = 'USB Ports',
  LEATHER_SEATS = 'Leather Seats',
  HEATED_FRONT_SEATS = 'Heated Front Seats',
  BLUETOOTH_HANDSFREE_SYSTEM = 'Bluetooth Handsfree System',
  CRUISE_CONTROL = 'Cruise Control',
  HEATED_FRONT_WINDSCREEN = 'Heated Front Windscreen',
  POWER_OPERATED_TAILGATE = 'Power Operated Tailgate',
  KEYLESS_START = 'Keyless Start',
  KEYLESS_ENTRY = 'Keyless Entry',
  HEATED_STEERING_WHEEL = 'Heated Steering Wheel',
  ADVANCED_SOUND_SYSTEM = 'Advanced Sound System',
  ADJUSTABLE_SEATS = 'Adjustable Seats',
  CLIMATE_CONTROL = 'Climate Control',
  LED_HEADLIGHTS = 'LED Headlights',
  PANORAMIC_SUNROOF = 'Panoramic Sunroof',
  POWER_CHILD_LOCKS = 'Power Child Locks',
  PASSENGER_AIRBAG_CUT_OFF_SWITCH = 'Passenger Airbag Cut Off Switch',
  ACTIVE_LANE_KEEP_ASSIST = 'Active Lane Keep Assist',
  COLLISION_AVOIDANCE_BREAKING = 'Collision Avoidance Braking',
  HILL_START_ASSIST = 'Hill Start Assist',
  SPARE_WHEEL = 'Spare Wheel',
  PARKING_CAMERA = 'Parking Camera',
  ISOFIX_ATTACHMENTS = 'ISOFIX Attachments',
  AUTOMATED_HIGH_BEAM_ASSIST = 'Automated High-beam Assist',
  EMERGENCY_SERVICES_CALL_SYSTEM = 'Emergency Services Call System',
  REAR_COLLISION_WARNING = 'Rear Collision Warning',
  FRONT_COLLISION_WARNING = 'Front Collision Warning',
  ALLOY_WHEELS = 'Alloy Wheels',
}

export const KeySpecificationOptions = {
  [Brand.DRIVVNQA]: [VehicleOptionHeadings.LEATHER_SEATS],
  [Brand.VOLVO]: [
    VehicleOptionHeadings.LEATHER_SEATS,
    VehicleOptionHeadings.HEATED_FRONT_SEATS,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.CRUISE_CONTROL,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    VehicleOptionHeadings.POWER_OPERATED_TAILGATE,
    VehicleOptionHeadings.KEYLESS_START,
    VehicleOptionHeadings.HEATED_STEERING_WHEEL,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.ADJUSTABLE_SEATS,
    VehicleOptionHeadings.CLIMATE_CONTROL,
    VehicleOptionHeadings.LED_HEADLIGHTS,
    VehicleOptionHeadings.PANORAMIC_SUNROOF,
    VehicleOptionHeadings.ALLOY_WHEELS,
  ],
  [Brand.FORD]: [
    VehicleOptionHeadings.LEATHER_SEATS,
    VehicleOptionHeadings.HEATED_FRONT_SEATS,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.CRUISE_CONTROL,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    VehicleOptionHeadings.POWER_OPERATED_TAILGATE,
    VehicleOptionHeadings.KEYLESS_START,
    VehicleOptionHeadings.HEATED_STEERING_WHEEL,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.ADJUSTABLE_SEATS,
    VehicleOptionHeadings.CLIMATE_CONTROL,
    VehicleOptionHeadings.LED_HEADLIGHTS,
    VehicleOptionHeadings.PANORAMIC_SUNROOF,
  ],
  [Brand.WAYLANDS]: [
    VehicleOptionHeadings.LEATHER_SEATS,
    VehicleOptionHeadings.HEATED_FRONT_SEATS,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.CRUISE_CONTROL,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    VehicleOptionHeadings.POWER_OPERATED_TAILGATE,
    VehicleOptionHeadings.KEYLESS_START,
    VehicleOptionHeadings.HEATED_STEERING_WHEEL,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.ADJUSTABLE_SEATS,
    VehicleOptionHeadings.CLIMATE_CONTROL,
    VehicleOptionHeadings.LED_HEADLIGHTS,
    VehicleOptionHeadings.PANORAMIC_SUNROOF,
    VehicleOptionHeadings.ALLOY_WHEELS,
  ],
  [Brand.AUDI]: [
    VehicleOptionHeadings.SATELLITE_NAVIGATION,
    VehicleOptionHeadings.LEATHER_SEATS,
    VehicleOptionHeadings.HEATED_FRONT_SEATS,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.CRUISE_CONTROL,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    VehicleOptionHeadings.POWER_OPERATED_TAILGATE,
    VehicleOptionHeadings.KEYLESS_ENTRY,
    VehicleOptionHeadings.HEATED_STEERING_WHEEL,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.ADJUSTABLE_SEATS,
    VehicleOptionHeadings.CLIMATE_CONTROL,
    VehicleOptionHeadings.LED_HEADLIGHTS,
    VehicleOptionHeadings.PANORAMIC_SUNROOF,
    VehicleOptionHeadings.ALLOY_WHEELS,
  ],
  [Brand.VOLKSWAGEN]: [
    VehicleOptionHeadings.SATELLITE_NAVIGATION,
    VehicleOptionHeadings.LEATHER_SEATS,
    VehicleOptionHeadings.HEATED_FRONT_SEATS,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.CRUISE_CONTROL,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    VehicleOptionHeadings.POWER_OPERATED_TAILGATE,
    VehicleOptionHeadings.KEYLESS_ENTRY,
    VehicleOptionHeadings.HEATED_STEERING_WHEEL,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.ADJUSTABLE_SEATS,
    VehicleOptionHeadings.CLIMATE_CONTROL,
    VehicleOptionHeadings.LED_HEADLIGHTS,
    VehicleOptionHeadings.PANORAMIC_SUNROOF,
    VehicleOptionHeadings.ALLOY_WHEELS,
  ],
};

export const TechnologyOptions = {
  [Brand.DRIVVNQA]: [VehicleOptionHeadings.SMARTPHONE_INTEGRATION],
  [Brand.VOLVO]: [
    VehicleOptionHeadings.TOUCHSCREEN_MEDIA_DISPLAY,
    VehicleOptionHeadings.SMARTPHONE_INTEGRATION,
    VehicleOptionHeadings.WIRELESS_MOBILE_PHONE_CHARGING,
    VehicleOptionHeadings.SATELLITE_NAVIGATION,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.USB_PORTS,
  ],
  [Brand.FORD]: [
    VehicleOptionHeadings.TOUCHSCREEN_MEDIA_DISPLAY,
    VehicleOptionHeadings.SMARTPHONE_INTEGRATION,
    VehicleOptionHeadings.WIRELESS_MOBILE_PHONE_CHARGING,
    VehicleOptionHeadings.SATELLITE_NAVIGATION,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.USB_PORTS,
  ],
  [Brand.WAYLANDS]: [
    VehicleOptionHeadings.TOUCHSCREEN_MEDIA_DISPLAY,
    VehicleOptionHeadings.SMARTPHONE_INTEGRATION,
    VehicleOptionHeadings.WIRELESS_MOBILE_PHONE_CHARGING,
    VehicleOptionHeadings.SATELLITE_NAVIGATION,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.USB_PORTS,
  ],
  [Brand.AUDI]: [
    VehicleOptionHeadings.TOUCHSCREEN_MEDIA_DISPLAY,
    VehicleOptionHeadings.SMARTPHONE_INTEGRATION,
    VehicleOptionHeadings.WIRELESS_MOBILE_PHONE_CHARGING,
    VehicleOptionHeadings.SATELLITE_NAVIGATION,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.USB_PORTS,
  ],
  [Brand.VOLKSWAGEN]: [
    VehicleOptionHeadings.TOUCHSCREEN_MEDIA_DISPLAY,
    VehicleOptionHeadings.SMARTPHONE_INTEGRATION,
    VehicleOptionHeadings.WIRELESS_MOBILE_PHONE_CHARGING,
    VehicleOptionHeadings.SATELLITE_NAVIGATION,
    VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    VehicleOptionHeadings.USB_PORTS,
  ],
};

export const SafetyOptions = {
  [Brand.DRIVVNQA]: [VehicleOptionHeadings.HILL_START_ASSIST],
  [Brand.VOLVO]: [
    VehicleOptionHeadings.POWER_CHILD_LOCKS,
    VehicleOptionHeadings.ISOFIX_ATTACHMENTS,
    VehicleOptionHeadings.PASSENGER_AIRBAG_CUT_OFF_SWITCH,
    VehicleOptionHeadings.ACTIVE_LANE_KEEP_ASSIST,
    VehicleOptionHeadings.COLLISION_AVOIDANCE_BREAKING,
    VehicleOptionHeadings.HILL_START_ASSIST,
    VehicleOptionHeadings.SPARE_WHEEL,
    VehicleOptionHeadings.PARKING_CAMERA,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    VehicleOptionHeadings.AUTOMATED_HIGH_BEAM_ASSIST,
    VehicleOptionHeadings.EMERGENCY_SERVICES_CALL_SYSTEM,
  ],
  [Brand.FORD]: [
    VehicleOptionHeadings.POWER_CHILD_LOCKS,
    VehicleOptionHeadings.ISOFIX_ATTACHMENTS,
    VehicleOptionHeadings.PASSENGER_AIRBAG_CUT_OFF_SWITCH,
    VehicleOptionHeadings.ACTIVE_LANE_KEEP_ASSIST,
    VehicleOptionHeadings.COLLISION_AVOIDANCE_BREAKING,
    VehicleOptionHeadings.HILL_START_ASSIST,
    VehicleOptionHeadings.SPARE_WHEEL,
    VehicleOptionHeadings.PARKING_CAMERA,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    VehicleOptionHeadings.AUTOMATED_HIGH_BEAM_ASSIST,
    VehicleOptionHeadings.EMERGENCY_SERVICES_CALL_SYSTEM,
  ],
  [Brand.WAYLANDS]: [
    VehicleOptionHeadings.POWER_CHILD_LOCKS,
    VehicleOptionHeadings.ISOFIX_ATTACHMENTS,
    VehicleOptionHeadings.PASSENGER_AIRBAG_CUT_OFF_SWITCH,
    VehicleOptionHeadings.ACTIVE_LANE_KEEP_ASSIST,
    VehicleOptionHeadings.COLLISION_AVOIDANCE_BREAKING,
    VehicleOptionHeadings.HILL_START_ASSIST,
    VehicleOptionHeadings.SPARE_WHEEL,
    VehicleOptionHeadings.PARKING_CAMERA,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    VehicleOptionHeadings.AUTOMATED_HIGH_BEAM_ASSIST,
    VehicleOptionHeadings.EMERGENCY_SERVICES_CALL_SYSTEM,
  ],
  [Brand.AUDI]: [
    VehicleOptionHeadings.POWER_CHILD_LOCKS,
    VehicleOptionHeadings.ISOFIX_ATTACHMENTS,
    VehicleOptionHeadings.PASSENGER_AIRBAG_CUT_OFF_SWITCH,
    VehicleOptionHeadings.REAR_COLLISION_WARNING,
    VehicleOptionHeadings.FRONT_COLLISION_WARNING,
    VehicleOptionHeadings.HILL_START_ASSIST,
    VehicleOptionHeadings.SPARE_WHEEL,
    VehicleOptionHeadings.PARKING_CAMERA,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
  ],
  [Brand.VOLKSWAGEN]: [
    VehicleOptionHeadings.POWER_CHILD_LOCKS,
    VehicleOptionHeadings.ISOFIX_ATTACHMENTS,
    VehicleOptionHeadings.PASSENGER_AIRBAG_CUT_OFF_SWITCH,
    VehicleOptionHeadings.REAR_COLLISION_WARNING,
    VehicleOptionHeadings.FRONT_COLLISION_WARNING,
    VehicleOptionHeadings.HILL_START_ASSIST,
    VehicleOptionHeadings.SPARE_WHEEL,
    VehicleOptionHeadings.PARKING_CAMERA,
    VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
  ],
};

export const EnvironmentOptions = {
  [Brand.VOLVO]: [
    DataBubbles.ELECTRIC_DRIVING_RANGE,
    DataBubbles.CLEAN_AIR_ZONE_CHARGES,
    DataBubbles.EMISSIONS,
    DataBubbles.EURO_EMISSION_STANDARD,
  ],
  [Brand.FORD]: [
    DataBubbles.ELECTRIC_DRIVING_RANGE,
    DataBubbles.CLEAN_AIR_ZONE_CHARGES,
    DataBubbles.EMISSIONS,
    DataBubbles.EURO_EMISSION_STANDARD,
  ],
  [Brand.WAYLANDS]: [
    DataBubbles.ELECTRIC_DRIVING_RANGE,
    DataBubbles.CLEAN_AIR_ZONE_CHARGES,
    DataBubbles.EMISSIONS,
    DataBubbles.EURO_EMISSION_STANDARD,
  ],
  [Brand.AUDI]: [DataBubbles.ELECTRIC_DRIVING_RANGE, DataBubbles.EMISSIONS],
  [Brand.VOLKSWAGEN]: [DataBubbles.ELECTRIC_DRIVING_RANGE, DataBubbles.EMISSIONS],
};

export const PerformanceOptions = {
  [Brand.VOLVO]: [
    DataBubbles.PERFORMANCE,
    DataBubbles.TOP_SPEED,
    DataBubbles.FUEL_EFFICIENCY,
    DataBubbles.ACCELERATION,
    DataBubbles.MILES_PER_KWH,
  ],
  [Brand.FORD]: [
    DataBubbles.PERFORMANCE,
    DataBubbles.TOP_SPEED,
    DataBubbles.FUEL_EFFICIENCY,
    DataBubbles.ACCELERATION,
    DataBubbles.MILES_PER_KWH,
  ],
  [Brand.WAYLANDS]: [
    DataBubbles.PERFORMANCE,
    DataBubbles.TOP_SPEED,
    DataBubbles.FUEL_EFFICIENCY,
    DataBubbles.ACCELERATION,
    DataBubbles.MILES_PER_KWH,
  ],
  [Brand.AUDI]: [DataBubbles.PERFORMANCE, DataBubbles.TOP_SPEED, DataBubbles.ACCELERATION, DataBubbles.MILES_PER_KWH],
  [Brand.VOLKSWAGEN]: [
    DataBubbles.PERFORMANCE,
    DataBubbles.TOP_SPEED,
    DataBubbles.ACCELERATION,
    DataBubbles.MILES_PER_KWH,
  ],
};

export const unselectedVehicleOptions: VehicleOptions = [
  {
    title: VehicleOptionHeadings.LEATHER_SEATS,
    content: '',
    type: 'string',
    ordering: 0,
  },
  {
    title: VehicleOptionHeadings.HEATED_FRONT_SEATS,
    content: '',
    type: 'string',
    ordering: 1,
  },
  {
    title: VehicleOptionHeadings.BLUETOOTH_HANDSFREE_SYSTEM,
    content: '',
    type: 'string',
    ordering: 2,
  },
  {
    title: VehicleOptionHeadings.CRUISE_CONTROL,
    content: '',
    type: 'string',
    ordering: 3,
  },
  {
    title: VehicleOptionHeadings.AUTOMATIC_HEADLIGHTS,
    content: '',
    type: 'string',
    ordering: 4,
  },
  {
    title: VehicleOptionHeadings.POWER_OPERATED_TAILGATE,
    content: '',
    type: 'string',
    ordering: 6,
  },
  {
    title: VehicleOptionHeadings.KEYLESS_START,
    content: '',
    type: 'string',
    ordering: 9,
  },
  {
    title: VehicleOptionHeadings.HEATED_STEERING_WHEEL,
    content: '',
    type: 'string',
    ordering: 10,
  },
  {
    title: VehicleOptionHeadings.ADVANCED_SOUND_SYSTEM,
    content: '',
    type: 'string',
    ordering: 11,
  },
  {
    title: VehicleOptionHeadings.ADJUSTABLE_SEATS,
    content: '',
    type: 'string',
    ordering: 12,
  },
  {
    title: VehicleOptionHeadings.CLIMATE_CONTROL,
    content: '',
    type: 'string',
    ordering: 13,
  },
  {
    title: VehicleOptionHeadings.LED_HEADLIGHTS,
    content: '',
    type: 'string',
    ordering: 14,
  },
  {
    title: VehicleOptionHeadings.PANORAMIC_SUNROOF,
    content: '',
    type: 'string',
    ordering: 15,
  },
];

export const AttributesToShowNEDCLabelFor = [
  DataBubbles.EMISSIONS,
  DataBubbles.TOP_SPEED,
  DataBubbles.FUEL_EFFICIENCY,
  DataBubbles.ACCELERATION,
  Attribute.ROAD_TAX,
  Attribute.FUEL_COST,
];

export const DATA_BUBBLES = {
  [DataBubbles.ELECTRIC_DRIVING_RANGE]: {
    heading: 'electric-driving-range.heading',
    label: 'electric-driving-range.label',
    content: 'electric-driving-range.content',
  },
  [DataBubbles.CLEAN_AIR_ZONE_CHARGES]: {
    heading: 'clean-air-zone-charges.heading',
    label: 'clean-air-zone-charges.label',
    content: 'clean-air-zone-charges.content',
  },
  [DataBubbles.EMISSIONS]: {
    heading: 'emissions.heading',
    label: 'emissions.label',
    content: 'emissions.content',
  },
  [DataBubbles.EURO_EMISSION_STANDARD]: {
    heading: 'euro-emission-standard.heading',
    label: 'euro-emission-standard.label',
    content: 'euro-emission-standard.content',
  },
  [DataBubbles.PERFORMANCE]: {
    heading: 'performance.heading',
    label: 'performance.label',
    content: 'performance.content',
  },
  [DataBubbles.TOP_SPEED]: {
    heading: 'top-speed.heading',
    label: 'top-speed.label',
    content: 'top-speed.content',
  },
  [DataBubbles.FUEL_EFFICIENCY]: {
    heading: 'fuel-efficiency.heading',
    label: 'fuel-efficiency.label',
    content: 'fuel-efficiency.content',
  },
  [DataBubbles.ACCELERATION]: {
    heading: 'acceleration.heading',
    label: 'acceleration.label',
    content: 'acceleration.content',
  },
  [DataBubbles.MILES_PER_KWH]: {
    heading: 'miles-per-kwh.heading',
    label: 'miles-per-kwh.label',
    content: 'miles-per-kwh.content',
  },
};
