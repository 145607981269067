import { VehicleComparison } from 'features/detailed-comparator/types';
import { State } from 'features/detailed-comparator/reducers';

const makeComparison = (state: State, action): VehicleComparison[] => {
  const updatedComparisons = [...state.comparisons];
  const { clearComparison, data, formPosition, isVRM, vrm } = action;

  if (clearComparison) {
    updatedComparisons[formPosition].isComparisonSet = false;
    updatedComparisons[formPosition].isVrmComparison = false;
    updatedComparisons[formPosition].comparison = {};
    updatedComparisons[formPosition].vrm = '';
    return updatedComparisons;
  }

  updatedComparisons[formPosition].isComparisonSet = true;
  [updatedComparisons[formPosition].comparison] = data.comparisons;

  updatedComparisons[formPosition].isVrmComparison = Boolean(isVRM || vrm);
  if (vrm) updatedComparisons[formPosition].vrm = vrm;

  return updatedComparisons;
};

export default makeComparison;
