import { Brand } from 'shared/types';

interface ChangeVehicle {
  [key: string]: {
    icon: {
      show: boolean;
      append: boolean;
      src: string;
    };
  };
}

export const changeVehicle: ChangeVehicle = {
  [Brand.AUDI]: {
    icon: {
      show: true,
      append: true,
      src: 'common/right-chevron.svg',
    },
  },
  [Brand.DRIVVN]: {
    icon: {
      show: true,
      append: true,
      src: 'common/right-chevron.svg',
    },
  },
  [Brand.FORD]: {
    icon: {
      show: false,
      append: false,
      src: '',
    },
  },
  [Brand.VOLKSWAGEN]: {
    icon: {
      show: true,
      append: true,
      src: 'common/right-chevron.svg',
    },
  },
  [Brand.VOLVO]: {
    icon: {
      show: false,
      append: false,
      src: '',
    },
  },
  [Brand.WAYLANDS]: {
    icon: {
      show: false,
      append: false,
      src: '',
    },
  },
};
