import { TestTypeInformationStandAloneModal } from 'features/TestTypeInformation/TestTypeInformationStandAloneModal';
import React, { useContext, useMemo, useState } from 'react';
import { SyntheticTestTypes, TestType } from 'shared/types';
import { useBrand } from 'shared/hooks/useBrand';
import { useFeature } from 'shared/hooks/useFeature';
import { FeatureToggles } from 'shared/constants/features';

const VehicleTestTypeModalContext = React.createContext({});

interface Props {
  children: React.ReactNode;
}

type VehicleTestTypeModal = {
  isModalOpen: boolean;
  openModal: (testType: TestType | SyntheticTestTypes) => void;
};

const VehicleTestTypeModalProvider: React.FC<Props> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [testType, setTestType] = useState(null);
  const brand = useBrand();
  const isTestTypeModalFeatureEnabled = useFeature(FeatureToggles.FEATURE_SHOW_TEST_TYPE_MODAL, brand);

  const value = useMemo(() => {
    const openModal = (testType) => {
      if (isTestTypeModalFeatureEnabled) {
        setTestType(testType);
        setIsModalOpen(true);
      }
    };

    return {
      isModalOpen,
      openModal,
    };
  }, [isModalOpen, isTestTypeModalFeatureEnabled]);

  const handleClose = () => {
    setIsModalOpen(false);
    setTestType(null);
  };

  return (
    <VehicleTestTypeModalContext.Provider value={value}>
      {isModalOpen && testType && isTestTypeModalFeatureEnabled ? (
        <TestTypeInformationStandAloneModal testType={testType} handleClose={handleClose} />
      ) : null}
      {children}
    </VehicleTestTypeModalContext.Provider>
  );
};

type UseVehicleTestTypeModal = () => VehicleTestTypeModal;

const useVehicleTestTypeModal: UseVehicleTestTypeModal = () =>
  useContext(VehicleTestTypeModalContext) as VehicleTestTypeModal;

export { VehicleTestTypeModalProvider, useVehicleTestTypeModal };
