import React, { useEffect } from 'react';
import { useBrand } from 'shared/hooks/useBrand';
import { useJourney } from 'shared/hooks/useJourney';
import { useTranslation, Trans } from 'next-i18next';
import { JourneyType } from 'shared/types';
import { TranslationKey } from 'shared/constants';
import { Container } from 'shared/components/Container';
import styled from 'styled-components';
import parse from 'html-react-parser';

const DefaultTabContentWrapper = styled(Container)`
  ${({ theme }) => `
   &&& {
      flex-direction: column;
      margin-bottom: ${theme.components.detailedComparator.tabDefaultHeader.marginBottom.mobile};

      @media screen and (min-width: ${theme.viewports.desktop}) {
        margin-bottom: ${theme.components.detailedComparator.tabDefaultHeader.marginBottom.desktop};
      }
    }
  `}
`;

const Heading = styled.h2`
  ${({ theme }) => `
    font: ${theme.components.detailedComparator.tabDefaultHeader.header.defaultFont.mobile};
    margin: ${theme.components.detailedComparator.tabDefaultHeader.header.margin.mobile};

    @media screen and (min-width: ${theme.viewports.desktop}) {
      font: ${theme.components.detailedComparator.tabDefaultHeader.header.defaultFont.desktop};
      margin: ${theme.components.detailedComparator.tabDefaultHeader.header.margin.desktop};
    }
  `}
`;

const BoldHeading = styled.span`
  ${({ theme }) => `
    font: ${theme.components.detailedComparator.tabDefaultHeader.header.boldFont.mobile};

    @media screen and (min-width: ${theme.viewports.desktop}) {
      font: ${theme.components.detailedComparator.tabDefaultHeader.header.boldFont.desktop};
    }
  `}
`;

const ParagraphWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.lg};

    @media screen and (min-width: ${theme.viewports.desktop}) {
      width: calc(100% / 3 * 2);
    }
  `}
`;

const Paragraph = styled.p`
  ${({ theme }) => `
    font: ${theme.components.detailedComparator.tabDefaultHeader.body.defaultFont.mobile};
  `}
`;

const TabDefaultContent: React.FC = () => {
  const brand = useBrand();
  const { setJourneyData } = useJourney();

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);

  useEffect(() => {
    setJourneyData(JourneyType.JOURNEY_PROVIDER_EMPTY);
  }, []);

  const paragraphs = [
    parse(translate('content.default.copy.line1')),
    parse(translate('content.default.copy.line2')),
    parse(translate('content.default.copy.line3')),
    parse(translate('content.default.copy.line4')),
  ];

  return (
    <DefaultTabContentWrapper>
      <Heading>
        <Trans i18nKey="content.default.heading" t={translate} components={{ bold: <BoldHeading /> }} />
      </Heading>
      <ParagraphWrapper>
        {paragraphs.map((paragraph, index) => {
          return paragraph ? <Paragraph key={index}>{paragraph}</Paragraph> : null;
        })}
      </ParagraphWrapper>
    </DefaultTabContentWrapper>
  );
};

export default TabDefaultContent;
