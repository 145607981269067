import { TFunction } from 'next-i18next';
import { JourneyType } from 'shared/types';

interface Copy {
  companyCarDriverTooltip: string;
  privateTooltip: string;
  fleetManagerTooltip: string;
}

export const getTooltipText = (
  journeyType: JourneyType,
  { companyCarDriverTooltip, privateTooltip, fleetManagerTooltip }: Copy,
  translate: TFunction
): string => {
  if (journeyType === JourneyType.BUSINESS_COMPANY_CAR_DRIVER) {
    return translate(companyCarDriverTooltip);
  }

  if (journeyType === JourneyType.BUSINESS_FLEET_MANAGER) {
    return translate(fleetManagerTooltip);
  }

  return translate(privateTooltip);
};

export default getTooltipText;
