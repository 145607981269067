import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { TranslationKey } from 'shared/constants';
import { useTheme } from 'shared/hooks/useTheme';
import { FleetManagerCosts, OwnershipType, VehicleComparison } from 'features/detailed-comparator/types';
import isComparisonSet from 'features/detailed-comparator/utils/isComparisonSet';
import { Attribute, JourneyType } from 'shared/types';
import getMonthlyCost from 'features/detailed-comparator/utils/getMonthlyCost';
import { formatToCurrency } from 'shared/utils/currency';
import { ONE_YEAR_IN_MONTHS } from 'services/helpers/calculations/constants';
import { ATTRIBUTE_JOURNEY_MAPPING } from 'features/detailed-comparator/components/RunningCosts/constants';
import { getAttributeSum } from 'features/detailed-comparator/utils/getAttributeSum';
import { useBrand } from 'shared/hooks/useBrand';

interface Props {
  comparisons: VehicleComparison[];
  selectedOption: JourneyType;
  leaseTerm: number;
  fleetManagerInputValues: FleetManagerCosts[];
  ownershipType: OwnershipType;
}

const AttributeHeadingsWrapper = styled.div<{ isFleetManagerSelected: boolean }>`
  ${({ isFleetManagerSelected, theme }) => `
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, fit-content(100%));
    grid-template-areas: 'attributeHeadingsTitle attributeHeadingsTitle'
     'attributeHeadingsValue0 attributeHeadingsValue1';
    grid-column-gap: ${theme.components.detailedComparator.attributes.columnGap};
    width: 100%;
    align-items: center;
    margin: ${theme.components.runningCostTable.attributesHeading.margin.mobile};
    border-bottom: ${
      isFleetManagerSelected
        ? theme.components.runningCostTable.attributesHeading.headerBorder.borderBottom.mobileFleetManagerSelected
        : theme.components.runningCostTable.attributesHeading.headerBorder.borderBottom.mobile
    };

    @media screen and (min-width: ${theme.viewports.tablet}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-areas: 'attributeHeadingsTitle attributeHeadingsTitle attributeHeadingsTitle'
      'attributeHeadingsValue0 attributeHeadingsValue1 attributeHeadingsValue2';
      border-bottom: 0;
      margin: ${theme.components.runningCostTable.attributesHeading.margin.desktop};
    }

    @media screen and (min-width: 1240px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: auto 1fr auto;
      grid-template-areas: 'attributeHeadingsTitle attributeHeadingsValue0 attributeHeadingsValue1 attributeHeadingsValue2';
      border-bottom: ${theme.components.runningCostTable.attributesHeading.headerBorder.borderBottom.desktop};
    }
  `}
`;

const AttributeHeadingsTitle = styled.h4`
  ${({ theme }) => {
    const { attributesHeading } = theme.components.runningCostTable;
    const { title } = attributesHeading;
    return `
    grid-area: attributeHeadingsTitle;
    display: flex;
    flex-direction: ${title.flexDirection.mobile};
    font: ${title.font.mobile};
    padding: ${title.padding.mobile};
    gap: ${title.gap.mobile};
    align-items: ${title.alignItems.mobile};

    @media screen and (min-width: ${theme.viewports.tablet}) {
      font: ${title.font.desktop};
    }

    @media screen and (min-width: 1240px) {
      padding: ${title.padding.desktop};
      flex-direction: ${title.flexDirection.desktop};
      align-items: ${title.alignItems.desktop};
      gap: ${title.gap.desktop};
      border-bottom: ${title.borderBottom.desktop};
    }
  `;
  }}
`;

const AttributeHeadingsValue = styled.div<{ position: number; isFleetManagerSelected: boolean }>`
  ${({ position, isFleetManagerSelected, theme }) => `
    grid-area: ${`attributeHeadingsValue${position}`};
    display: flex;
    width: 100%;
    align-self: stretch;
    justify-content: ${theme.components.detailedComparator.attributes.attributeValue.alignItems.mobile};
    font: ${theme.components.runningCostTable.attributesHeading.value.font.mobile};
    padding: ${theme.components.runningCostTable.attributesHeading.value.padding.mobile};
    border-bottom: ${theme.components.runningCostTable.attributesHeading.value.borderBottom.mobile};

    &:last-of-type {
      display: none;

      @media screen and (min-width: ${theme.viewports.tablet}) {
        display: flex;
      }
    }

    @media screen and (min-width: ${theme.viewports.tablet}) {
      justify-content: ${theme.components.detailedComparator.attributes.attributeValue.alignItems.desktop};
      padding: ${theme.components.runningCostTable.attributesHeading.value.padding.mobile};
      border-bottom: ${
        isFleetManagerSelected ? '' : theme.components.runningCostTable.attributesHeading.value.borderBottom.desktop
      };
      border-bottom: ${theme.components.runningCostTable.attributesHeading.value.borderBottom.desktop};
      font: ${theme.components.runningCostTable.attributesHeading.value.font.desktop};
    }

    @media screen and (min-width: 1240px) {
      align-items: center;
      justify-content: ${theme.components.runningCostTable.attributesHeading.value.justifyContent.desktop};
      padding: ${theme.components.runningCostTable.attributesHeading.value.padding.desktop};
    }
  `}
`;

const SubHeading = styled.span`
  ${({ theme }) => `
    font: ${theme.components.runningCostTable.attributesHeading.subheading.font};
    color: ${theme.components.runningCostTable.attributesHeading.subheading.color};

    @media screen and (min-width: 1240px) {
        display: block;
    }
  `}
`;

const SuperScript = styled.span`
  vertical-align: super;
  font: ${({ theme }) => theme.components.runningCostTable.attributesHeading.superscript.font};
`;

const AttributeHeadings: React.FC<Props> = ({
  comparisons,
  selectedOption,
  leaseTerm,
  fleetManagerInputValues,
  ownershipType,
}) => {
  const brand = useBrand();

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);

  const filterMonthlyCost = (position: number): string => {
    if (selectedOption === JourneyType.BUSINESS_FLEET_MANAGER) {
      if (ownershipType === OwnershipType.LEASE) {
        const formattedMonthlyPrice = Number(fleetManagerInputValues[position][OwnershipType.LEASE]);

        const attributes = ATTRIBUTE_JOURNEY_MAPPING[brand][selectedOption] ?? [];

        const filteredAttributes = comparisons[position].comparison.attributes.filter((object: { type: Attribute }) =>
          attributes.includes(object.type)
        );

        const total = getAttributeSum(filteredAttributes, formattedMonthlyPrice, leaseTerm);
        return formatToCurrency(String(total));
      }

      if (ownershipType === OwnershipType.OWN) {
        if (isComparisonSet(comparisons[position]) && Number(fleetManagerInputValues[position][ownershipType]) !== 0) {
          const repaymentLength = ONE_YEAR_IN_MONTHS * leaseTerm;

          const roadTax =
            comparisons[position].comparison.attributes
              .find((attr) => attr.type === Attribute.ROAD_TAX)
              .values.find((type) => type.type === JourneyType.PRIVATE).value ?? '';

          const residualValue = comparisons[position].comparison.attributes.find(
            (object: { type: Attribute }) => object.type === Attribute.RESIDUAL_VALUE
          ).value;

          const assetValue =
            Number(fleetManagerInputValues[position][ownershipType]) - Number(residualValue) - Number(roadTax);

          const monthlyCostOfAsset = assetValue / repaymentLength;

          const attributes = ATTRIBUTE_JOURNEY_MAPPING[brand][selectedOption] ?? [];

          const filteredAttributes = comparisons[position].comparison.attributes.filter((object: { type: Attribute }) =>
            attributes.includes(object.type)
          );

          const total = getAttributeSum(filteredAttributes, monthlyCostOfAsset, leaseTerm);

          return formatToCurrency(total > 0 ? total.toString() : '0');
        }

        return getMonthlyCost(comparisons[position].comparison.costs, selectedOption);
      }
    }

    return getMonthlyCost(comparisons[position].comparison.costs, selectedOption);
  };

  const isFleetManagerSelected = selectedOption === JourneyType.BUSINESS_FLEET_MANAGER;

  return (
    <AttributeHeadingsWrapper data-test="attribute-headings" isFleetManagerSelected={isFleetManagerSelected}>
      <AttributeHeadingsTitle>
        <span className="desktop attributes-headings__cell--desktop">
          {selectedOption === JourneyType.BUSINESS_FLEET_MANAGER
            ? translate('running-costs.attribute-headings.desktop.fleet')
            : translate('running-costs.attribute-headings.desktop')}
          <SuperScript>{translate('running-costs.attribute-headings.desktop-superscript')}</SuperScript>
        </span>

        <SubHeading>({translate('running-costs.attribute-headings.per-month')})</SubHeading>
      </AttributeHeadingsTitle>

      {comparisons.map((comparison, index) => (
        <AttributeHeadingsValue
          key={index}
          data-test={`attribute-headings__value--${index}`}
          position={index}
          isFleetManagerSelected={isFleetManagerSelected}
        >
          {isComparisonSet(comparison) ? filterMonthlyCost(index) : ''}
        </AttributeHeadingsValue>
      ))}
    </AttributeHeadingsWrapper>
  );
};

export default AttributeHeadings;
