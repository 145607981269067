import {
  Environmental as EnvironmentalIcon,
  KeySpecification as KeySpecificationIcon,
  Technology as TechnologyIcon,
  Performance as PerformanceIcon,
  Safety as SafetyIcon,
  RunningCosts as RunningCostsIcon,
  AudiEnvironmental as AudiEnvironmentalIcon,
  AudiKeySpecification as AudiKeySpecificationIcon,
  AudiTechnology as AudiTechnologyIcon,
  AudiPerformance as AudiPerformanceIcon,
  AudiSafety as AudiSafetyIcon,
  AudiRunningCosts as AudiRunningCostsIcon,
} from 'features/vehicle-attributes/components/icons';
import { Tab, Brand } from 'shared/types';

const TABS = {
  [Brand.VOLVO]: {
    [Tab.KEY_SPECIFICATION]: {
      label: 'tab.key-specification.label',
      value: 'key-specification',
      Icon: KeySpecificationIcon,
    },
    [Tab.ENVIRONMENTAL]: {
      label: 'tab.environmental.label',
      value: 'environmental',
      Icon: EnvironmentalIcon,
    },
    [Tab.TECHNOLOGY]: {
      label: 'tab.technology.label',
      value: 'technology',
      Icon: TechnologyIcon,
    },
    [Tab.PERFORMANCE]: {
      label: 'tab.performance.label',
      value: 'performance',
      Icon: PerformanceIcon,
    },
    [Tab.SAFETY]: {
      label: 'tab.safety.label',
      value: 'safety',
      Icon: SafetyIcon,
    },
    [Tab.RUNNING_COSTS]: {
      label: 'tab.running-costs.label',
      value: 'running-costs',
      Icon: RunningCostsIcon,
    },
  },
  [Brand.FORD]: {
    [Tab.KEY_SPECIFICATION]: {
      label: 'tab.key-specification.label',
      value: 'key-specification',
      Icon: KeySpecificationIcon,
    },
    [Tab.ENVIRONMENTAL]: {
      label: 'tab.environmental.label',
      value: 'environmental',
      Icon: EnvironmentalIcon,
    },
    [Tab.TECHNOLOGY]: {
      label: 'tab.technology.label',
      value: 'technology',
      Icon: TechnologyIcon,
    },
    [Tab.PERFORMANCE]: {
      label: 'tab.performance.label',
      value: 'performance',
      Icon: PerformanceIcon,
    },
    [Tab.SAFETY]: {
      label: 'tab.safety.label',
      value: 'safety',
      Icon: SafetyIcon,
    },
    [Tab.RUNNING_COSTS]: {
      label: 'tab.running-costs.label',
      value: 'running-costs',
      Icon: RunningCostsIcon,
    },
  },
  [Brand.WAYLANDS]: {
    [Tab.KEY_SPECIFICATION]: {
      label: 'tab.key-specification.label',
      value: 'key-specification',
      Icon: KeySpecificationIcon,
    },
    [Tab.ENVIRONMENTAL]: {
      label: 'tab.environmental.label',
      value: 'environmental',
      Icon: EnvironmentalIcon,
    },
    [Tab.TECHNOLOGY]: {
      label: 'tab.technology.label',
      value: 'technology',
      Icon: TechnologyIcon,
    },
    [Tab.PERFORMANCE]: {
      label: 'tab.performance.label',
      value: 'performance',
      Icon: PerformanceIcon,
    },
    [Tab.SAFETY]: {
      label: 'tab.safety.label',
      value: 'safety',
      Icon: SafetyIcon,
    },
    [Tab.RUNNING_COSTS]: {
      label: 'tab.running-costs.label',
      value: 'running-costs',
      Icon: RunningCostsIcon,
    },
  },
  [Brand.AUDI]: {
    [Tab.KEY_SPECIFICATION]: {
      label: 'tab.key-specification.label',
      value: 'key-specification',
      Icon: AudiKeySpecificationIcon,
    },
    [Tab.ENVIRONMENTAL]: {
      label: 'tab.environmental.label',
      value: 'environmental',
      Icon: AudiEnvironmentalIcon,
    },
    [Tab.TECHNOLOGY]: {
      label: 'tab.technology.label',
      value: 'technology',
      Icon: AudiTechnologyIcon,
    },
    [Tab.PERFORMANCE]: {
      label: 'tab.performance.label',
      value: 'performance',
      Icon: AudiPerformanceIcon,
    },
    [Tab.SAFETY]: {
      label: 'tab.safety.label',
      value: 'safety',
      Icon: AudiSafetyIcon,
    },
    [Tab.RUNNING_COSTS]: {
      label: 'tab.running-costs.label',
      value: 'running-costs',
      Icon: AudiRunningCostsIcon,
    },
  },
  [Brand.VOLKSWAGEN]: {
    [Tab.KEY_SPECIFICATION]: {
      label: 'tab.key-specification.label',
      value: 'key-specification',
      Icon: '',
    },
    [Tab.ENVIRONMENTAL]: {
      label: 'tab.environmental.label',
      value: 'environmental',
      Icon: '',
    },
    [Tab.TECHNOLOGY]: {
      label: 'tab.technology.label',
      value: 'technology',
      Icon: '',
    },
    [Tab.PERFORMANCE]: {
      label: 'tab.performance.label',
      value: 'performance',
      Icon: '',
    },
    [Tab.SAFETY]: {
      label: 'tab.safety.label',
      value: 'safety',
      Icon: '',
    },
    [Tab.RUNNING_COSTS]: {
      label: 'tab.running-costs.label',
      value: 'running-costs',
      Icon: '',
    },
  },
};

const TabItems = {
  [Brand.VOLVO]: [
    Tab.RUNNING_COSTS,
    Tab.KEY_SPECIFICATION,
    Tab.ENVIRONMENTAL,
    Tab.TECHNOLOGY,
    Tab.PERFORMANCE,
    Tab.SAFETY,
  ],
  [Brand.FORD]: [
    Tab.RUNNING_COSTS,
    Tab.KEY_SPECIFICATION,
    Tab.ENVIRONMENTAL,
    Tab.TECHNOLOGY,
    Tab.PERFORMANCE,
    Tab.SAFETY,
  ],
  [Brand.WAYLANDS]: [
    Tab.RUNNING_COSTS,
    Tab.KEY_SPECIFICATION,
    Tab.ENVIRONMENTAL,
    Tab.TECHNOLOGY,
    Tab.PERFORMANCE,
    Tab.SAFETY,
  ],
  [Brand.AUDI]: [
    Tab.RUNNING_COSTS,
    Tab.KEY_SPECIFICATION,
    Tab.ENVIRONMENTAL,
    Tab.TECHNOLOGY,
    Tab.PERFORMANCE,
    Tab.SAFETY,
  ],
  [Brand.VOLKSWAGEN]: [
    Tab.RUNNING_COSTS,
    Tab.KEY_SPECIFICATION,
    Tab.ENVIRONMENTAL,
    Tab.TECHNOLOGY,
    Tab.PERFORMANCE,
    Tab.SAFETY,
  ],
  [Brand.DRIVVNQA]: [
    Tab.RUNNING_COSTS,
    Tab.KEY_SPECIFICATION,
    Tab.ENVIRONMENTAL,
    Tab.TECHNOLOGY,
    Tab.PERFORMANCE,
    Tab.SAFETY,
  ],
};

const InitialActiveTab = {
  [Brand.VOLVO]: {
    label: '',
    value: '',
    Icon: '',
    init: false,
  },
  [Brand.FORD]: {
    label: '',
    value: '',
    Icon: '',
    init: false,
  },
  [Brand.WAYLANDS]: {
    label: '',
    value: '',
    Icon: '',
    init: false,
  },
  [Brand.AUDI]: {
    label: 'tab.running-costs.label',
    value: 'running-costs',
    Icon: AudiRunningCostsIcon,
    init: true,
  },
  [Brand.VOLKSWAGEN]: {
    label: 'tab.running-costs.label',
    value: 'running-costs',
    Icon: AudiRunningCostsIcon,
    init: true,
  },
  [Brand.DRIVVNQA]: {
    label: '',
    value: '',
    Icon: '',
    init: false,
  },
};

export { TABS, TabItems, InitialActiveTab };
