import snakeCase from 'lodash/snakeCase';
import React from 'react';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { useTheme } from 'shared/hooks/useTheme';
import { useTranslation } from 'next-i18next';
import { Tab as TabEnum, TabItem } from 'shared/types';
import { EnableMenuByBrand, Product, TranslationKey } from 'shared/constants';
import { useBrand } from 'shared/hooks/useBrand';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';
import { useCustomSVGComponent } from 'shared/hooks/useCustomSVG';
import { InitialActiveTab, TABS } from 'features/detailed-comparator/components/Tab/constants';
import { usePlausible } from 'next-plausible';
import styled from 'styled-components';
import { Container } from 'shared/components/Container';

interface Props {
  setActiveTab: (tab: TabItem) => void;
  activeTab: TabItem;
  items: string[];
  isInitialState: boolean;
  product: Product;
}

const ChevronIcon = styled.svg<{ src: string }>`
  ${({ src, theme }) => `
    &&& {
      height: ${theme.components.tabHeader.chevronIcon.height};
      width: ${theme.components.tabHeader.chevronIcon.width};
      background-color: ${theme.components.tabHeader.chevronIcon.color};
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
      mask-image: url(${src})
    }
  `};
`;

const TabHeaderContainer = styled(Container)<{ ref: React.LegacyRef<HTMLDivElement> }>`
  ${({ theme }) => `
    &&& {
      z-index: 3;
      display: flex;
      flex: 1 0 100%;
      justify-content: center;
      background-color: ${theme.components.tabHeader.backgroundColor.mobile};

      @media screen and (max-width: ${theme.viewports.desktop}) {
        padding: 0;
      }

      @media screen and (min-width: ${theme.viewports.desktop}) {
        height: ${theme.components.tabHeader.height.desktop};
        padding: ${theme.components.tabHeader.spacing.xxxl} ${theme.spacing.lg} 0;
        background-color: ${theme.components.tabHeader.backgroundColor.desktop};
      }
    }
  `}
`;

const TabHeader = React.forwardRef<HTMLElement, Props>(
  ({ items, setActiveTab, activeTab, isInitialState, product }, ref) => {
    const theme = useTheme();
    const brand = useBrand();
    const brandConfig = useBrandConfig()[product];
    const { customSVGs, getCustomSVG } = useCustomSVGComponent();
    const shouldUseNewIcons = brandConfig?.shouldUseNewIcons;

    const { logEvent } = useAnalytics();
    const plausible = usePlausible();

    const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);

    const handleTabChange = async (tab: TabItem): Promise<void> => {
      const tabLabel = snakeCase(translate(tab.label));

      plausible(`detailed_comparator_select_${tabLabel}_tab`);

      if (activeTab.init === false || activeTab.init === undefined) {
        setActiveTab(tab);
      }

      await logEvent(`/tab/${tabLabel}`);
    };

    const heading = activeTab?.label !== '' ? activeTab.label : 'tab.default.heading';
    const NewActiveIcon = customSVGs[activeTab.value];

    const ActiveIcon = activeTab?.Icon !== '' ? activeTab?.Icon : '';

    const itemsEnabled: string[] = [];

    const handleSlideClick = async (currentTab: string, isPrevious: boolean): Promise<void> => {
      const index = itemsEnabled.indexOf(currentTab);
      if (index === 0 && isPrevious) {
        await handleTabChange(TABS[brand][itemsEnabled.pop()]);
      } else if (index === itemsEnabled.length - 1 && !isPrevious) {
        await handleTabChange(TABS[brand][itemsEnabled[0]]);
      } else {
        const key = isPrevious ? index - 1 : index + 1;
        await handleTabChange(TABS[brand][itemsEnabled[key]]);
      }
    };

    const shouldShowSlideIcons =
      (InitialActiveTab[brand].init === true && !EnableMenuByBrand.includes(brand)) || isInitialState === false;

    if (isInitialState) {
      return null;
    }
    return (
      <TabHeaderContainer className="tab-header" data-test="tab-header" ref={ref as React.LegacyRef<HTMLDivElement>}>
        <div className="tab-header__inner">
          {typeof items !== 'undefined' && (
            <div className="tab-header__tabs" data-test="tab-header__tabs" role="tablist">
              {items.map((tab: TabEnum, index: number) => {
                const tabs = TABS[brand] || {};
                const { label, value, Icon: TabIcon } = tabs[tab] || {};
                getCustomSVG(value);
                const Icon = customSVGs[value];
                const shouldRenderIcon = Boolean(Icon || TabIcon);
                const isActive = value === activeTab.value;

                itemsEnabled.push(tab);
                return (
                  <button
                    key={index}
                    role="tab"
                    aria-selected={isActive}
                    className={`tab-header__item ${isActive ? 'is-active' : ''}`}
                    data-test="tab-header__item"
                    onClick={async () => handleTabChange(TABS[brand][tab])}
                    disabled={isInitialState}
                    type="button"
                  >
                    {shouldRenderIcon && (shouldUseNewIcons && Icon ? <Icon title={value} /> : <TabIcon />)}
                    <span>{translate(label)}</span>
                  </button>
                );
              })}
            </div>
          )}

          <div
            className={`tab-header__heading ${activeTab.init ?? false ? 'tab-header__heading--active' : ''}`}
            data-test="tab-header__heading"
          >
            {shouldShowSlideIcons ? (
              <button
                disabled={activeTab.init ?? false}
                className="tab-header__slide-icon prev"
                data-test="tab-header__slide-icon"
                onClick={async () => handleSlideClick(activeTab.value, true)}
                type="button"
              >
                <ChevronIcon aria-label="Previous feature" src={`/assets/${brand}/common/prev.svg`} />
              </button>
            ) : null}
            <div className="tab-header__center">
              {activeTab?.Icon !== ''
                ? (shouldUseNewIcons && NewActiveIcon && (
                    <NewActiveIcon title={activeTab.value} width={20} height={20} />
                  )) || <ActiveIcon />
                : ''}
              {translate(heading)}
            </div>
            {shouldShowSlideIcons ? (
              <button
                disabled={activeTab.init ?? false}
                className="tab-header__slide-icon next"
                data-test="tab-header__slide-icon"
                onClick={async () => handleSlideClick(activeTab.value, false)}
                type="button"
              >
                <ChevronIcon aria-label="Next feature" src={`/assets/${brand}/common/next.svg`} />
              </button>
            ) : null}
          </div>
        </div>

        <style jsx>
          {`
            .tab-header.is-active {
              position: sticky;
              top: 0;
            }

            .tab-header__heading {
              position: relative;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: space-between;
              text-align: center;
              width: 100%;
              height: ${theme.components.tabHeader.height.mobile};
              text-transform: ${theme.components.tabHeader.item.textTransform};
              font-size: 16px;
              font-weight: bold;
              border-bottom: ${theme.components.tabHeader.heading.borderBottom};
              margin-bottom: ${theme.components.tabHeader.spacing.xxxl};
              color: ${theme.colors.senary};
            }

            .tab-header__heading--active {
              opacity: ${theme.components.tabHeader.itemActive.svg.opacity};
            }

            :global(.tab-header__heading svg) {
              ${!shouldUseNewIcons
                ? `
                  fill: ${theme.components.tabHeader.itemActive.svg.fill};
                  stroke: ${theme.components.tabHeader.itemActive.svg.stroke};
                  height: 1.5em;
                `
                : ''}
            }

            .tab-header__center {
              color: ${theme.components.tabHeader.color.mobile};
              font: ${theme.components.tabHeader.font};
              display: flex;
              ${shouldUseNewIcons
                ? `
                  gap: ${theme.spacing.xs};
                `
                : ''}
              align-items: center;
            }

            .tab-header__item:hover,
            .tab-header__item.is-active {
              color: ${theme.components.tabHeader.itemActive.color.mobile};
            }

            .tab-header__inner {
              width: 100%;
              height: ${theme.components.tabHeader.height.mobile};
              display: flex;
            }

            .tab-header__tabs {
              position: absolute;
              left: -100vw;
              top: 0;
              width: 100vw;
              height: 100vh;
              overflow: hidden;
              background-color: ${theme.components.tabHeader.backgroundColor.mobile};
              transition: left 0.2s ease-in-out;
              display: none;
              flex-direction: column;
              padding-top: ${activeTab?.Icon !== '' ? '11.7' : '7.2'}rem;
            }

            .tab-header__item {
              position: relative;
              text-align: left;
              padding: ${theme.spacing.lg};
              border: none;
              background-color: initial;
              font-size: ${theme.fontSizes.lg};
              cursor: pointer;
              font-family: inherit;
              color: ${theme.components.tabHeader.color.mobile};
            }

            .tab-header__item.is-active span {
              padding-bottom: ${theme.components.tabHeader.itemActive.underlineSpacing};
              border-bottom: ${theme.components.tabHeader.itemActive.underline};
            }

            .tab-header__item > span {
              padding-bottom: ${theme.components.tabHeader.itemActive.underlineSpacing};
              border-bottom: 2px solid transparent;
            }

            .tab-header__item:hover > span {
              border-bottom-color: ${theme.components.tabHeader.item.hover.borderBottomColor};
            }

            .tab-header__item::before {
              content: '';
              position: absolute;
              top: 50%;
              right: ${theme.spacing.lg};
              width: 1rem;
              height: 1rem;
              transform: translateY(-50%) rotate(180deg);
              background-image: url('/chevron-left.svg');
              background-repeat: no-repeat;
            }

            .tab-header__item:hover,
            .tab-header__item:focus,
            .tab-header__item.is-active {
              background-color: ${theme.components.tabHeader.itemActive.backgroundColor};
              color: ${activeTab?.init === true ? '' : `${theme.components.tabHeader.itemActive.active.color}`};
            }

            .tab-header__item.is-active {
              pointer-events: ${activeTab?.init === true ? 'fill' : 'none'};
            }

            .tab-header__item:disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }

            .tab-header__item:disabled:hover {
              background-color: initial;
              color: ${theme.colors.tertiary};
            }

            :global(.tab-header__tabs svg) {
              ${!shouldUseNewIcons
                ? `
                display: none;
                `
                : ''}
            }

            .tab-header__tabs.is-active {
              left: 0;
            }

            @media screen and (min-width: ${theme.viewports.desktop}) {
              .tab-header__heading {
                display: none;
              }

              .tab-header__inner {
                height: unset;
                height: ${theme.components.tabHeader.height.desktop};
                border-bottom: ${theme.components.tabHeader.borderBottom};
                width: 100%;
                max-width: 1280px;
                justify-content: ${theme.components.tabHeader.justifyContent};
              }

              .tab-header__tabs {
                position: relative;
                display: flex;
                left: auto;
                width: auto;
                height: auto;
                background-color: ${theme.components.tabHeader.backgroundColor.desktop};
                align-items: flex-end;
                flex-direction: row;
                padding: 0;
                gap: ${theme.components.tabHeader.flexGap};
              }

              .tab-header__item {
                font: ${theme.components.tabHeader.item.font};
                display: flex;
                flex-direction: column;
                min-width: ${theme.components.tabHeader.item.minWidth.mobile};
                min-height: ${theme.components.tabHeader.item.minHeight};
                height: 100%;
                background-color: ${theme.components.tabHeader.backgroundColor.desktop};
                align-items: center;
                justify-content: flex-end;
                padding: ${theme.spacing.md} 0 ${theme.spacing.sm};
                padding: ${theme.components.tabHeader.item.padding.desktop};
                border-bottom: ${theme.components.tabHeader.item.border.borderBottom};
                border-top: ${theme.components.tabHeader.item.border.borderTop};
                border-left: ${theme.components.tabHeader.item.border.borderLeft};
                border-right: ${theme.components.tabHeader.item.border.borderRight};
                overflow: hidden;
                color: ${theme.components.tabHeader.color.desktop};
              }

              .tab-header__item:last-child {
                border-right: 0;
              }

              .tab-header__item:disabled {
                border-right: ${theme.components.tabHeader.itemDisabled.border.borderRight};
              }

              .tab-header__item::before,
              .tab-header__item::after {
                display: none;
              }

              .tab-header__item.is-active,
              .tab-header__item:hover {
                background-color: ${theme.components.tabHeader.itemActive.backgroundColor};
                color: ${theme.components.tabHeader.itemActive.color.desktop};
                border-bottom: ${theme.components.tabHeader.itemActive.border.borderBottom};
                border-top: ${theme.components.tabHeader.itemActive.border.borderTop};
                border-left: ${theme.components.tabHeader.itemActive.border.borderLeft};
                border-right: ${theme.components.tabHeader.itemActive.border.borderRight};
              }

              .tab-header__item.is-active:last-child,
              .tab-header__item:hover:last-child {
                border-right: ${theme.components.tabHeader.item.border.lastItem.borderRight};
              }

              .tab-header__item:disabled:hover {
                background-color: initial;
                border-right: ${theme.components.tabHeader.itemActive.border.borderRight};
                border-bottom: none;
              }

              .tab-header__item:disabled:hover:last-child {
                background-color: initial;
                border-right: ${theme.components.tabHeader.item.border.lastItem.borderRight};
              }

              :global(.tab-header__item svg) {
                ${!shouldUseNewIcons
                  ? `
                  display: block;
                  fill: ${theme.components.tabHeader.item.svg.fill};
                  stroke: ${theme.components.tabHeader.item.svg.stroke};
                `
                  : ''}
              }

              :global(.tab-header__item.is-active svg),
              :global(.tab-header__item:hover svg) {
                ${!shouldUseNewIcons
                  ? `
                  fill: ${theme.components.tabHeader.itemActive.svg.fill};
                  stroke: ${theme.components.tabHeader.itemActive.svg.stroke};
                `
                  : ''}
              }

              :global(.tab-header__item:disabled:hover svg),
              :global(.tab-header__item.is-active:disabled svg) {
                ${!shouldUseNewIcons
                  ? `
                  fill: ${theme.components.tabHeader.itemDisabled.svg.fill};
                  stroke: ${theme.components.tabHeader.itemDisabled.svg.stroke};
                `
                  : ''}
              }

              .tab-header__item span {
                font-size: ${theme.fontSizes.md};
                margin-top: ${theme.spacing.sm};
                text-transform: ${theme.components.tabHeader.item.textTransform};
                max-width: 200px;
                overflow: hidden;
              }
            }

            @media screen and (min-width: 1240px) {
              .tab-header__item {
                min-width: ${theme.components.tabHeader.item.minWidth.desktop};
              }

              .tab-header__item span {
                font-size: ${theme.fontSizes.lg};
              }
            }

            @media screen and (max-width: ${theme.viewports.desktop}) {
              .tab-header__heading .tab-header__slide-icon {
                display: block;
                padding: ${theme.spacing.lg};
                cursor: pointer;
                background: none;
                border: none;
              }
            }
          `}
        </style>
      </TabHeaderContainer>
    );
  }
);

export default TabHeader;
