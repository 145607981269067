import { TFunction } from 'next-i18next';
import { Attribute, Brand, JourneyType, Months } from 'shared/types';
import {
  Benefits,
  CleanAirZone,
  FuelCosts,
  RoadTax,
  EmployersInsurance,
  Maintenance,
  AudiRoadTax,
  AudiFuelCosts,
  AudiCleanAirZone,
  AudiMaintenance,
  AudiEmployersInsurance,
  AudiBenefits,
} from 'features/vehicle-attributes/components/icons';
import { formatToCurrency } from 'shared/utils/currency';
import { formatToCurrencyByPeriod } from 'features/total-cost-of-ownership/utils/attributes';

const ATTRIBUTES = {
  [Attribute.BENEFIT_IN_KIND]: {
    title: 'running-costs.benefit-in-kind.title',
    Icon: Benefits,
    IconValue: 'benefits',
    IconAudi: AudiBenefits,
    formatValue: () => formatToCurrency,
    privateTooltip: 'running-costs.benefit-in-kind.tooltip.private',
    companyCarDriverTooltip: 'running-costs.benefit-in-kind.tooltip.company-car-driver',
    fleetManagerTooltip: 'running-costs.benefit-in-kind.tooltip.fleet-manager',
  },
  [Attribute.ROAD_TAX]: {
    title: 'running-costs.road-tax.title',
    Icon: RoadTax,
    IconValue: 'road-tax',
    IconAudi: AudiRoadTax,
    formatValue: (_translate: TFunction, leaseTerm: Months) => formatToCurrencyByPeriod(leaseTerm),
    privateTooltip: 'running-costs.road-tax.tooltip.private',
    companyCarDriverTooltip: 'running-costs.road-tax.tooltip.company-car-driver',
    fleetManagerTooltip: 'running-costs.road-tax.tooltip.fleet-manager',
  },
  [Attribute.FUEL_COST]: {
    title: 'running-costs.fuel-costs.title',
    Icon: FuelCosts,
    IconValue: 'fuel-costs',
    IconAudi: AudiFuelCosts,
    formatValue: () => formatToCurrencyByPeriod(Months.ONE_YEAR),
    privateTooltip: 'running-costs.fuel-costs.tooltip.private',
    companyCarDriverTooltip: 'running-costs.fuel-costs.tooltip.company-car-driver',
    fleetManagerTooltip: 'running-costs.fuel-costs.tooltip.fleet-manager',
  },
  [Attribute.CLEAN_AIR_ZONE_CHARGES]: {
    title: 'running-costs.clean-air-zone-charges.title',
    Icon: CleanAirZone,
    IconValue: 'clean-air-zone-charges',
    IconAudi: AudiCleanAirZone,
    formatValue: (translate: TFunction) => (value: string) => {
      return value === '0'
        ? translate('running-costs.clean-air-zone-charges.free')
        : formatToCurrencyByPeriod(Months.ONE_YEAR)(value);
    },
    privateTooltip: 'running-costs.clean-air-zone-charges.tooltip.private',
    companyCarDriverTooltip: 'running-costs.clean-air-zone-charges.tooltip.company-car-driver',
    fleetManagerTooltip: 'running-costs.clean-air-zone-charges.tooltip.fleet-manager',
  },
  [Attribute.MAINTENANCE]: {
    title: 'running-costs.maintenance.title',
    Icon: Maintenance,
    IconAudi: AudiMaintenance,
    IconValue: 'maintenance',
    formatValue: () => formatToCurrencyByPeriod(Months.ONE_YEAR),
    privateTooltip: 'running-costs.maintenance.tooltip.private',
    companyCarDriverTooltip: 'running-costs.maintenance.tooltip.company-car-driver',
    fleetManagerTooltip: 'running-costs.maintenance.tooltip.fleet-manager',
  },
  [Attribute.EMPLOYERS_NATIONAL_INSURANCE]: {
    title: 'running-costs.employers-national-insurance.title',
    Icon: EmployersInsurance,
    IconValue: 'employers-insurance',
    IconAudi: AudiEmployersInsurance,
    formatValue: () => formatToCurrencyByPeriod(Months.ONE_YEAR),
    fleetManagerTooltip: 'running-costs.employers-national-insurance.tooltip.fleet-manager',
  },
};

const ATTRIBUTE_JOURNEY_MAPPING = {
  [Brand.VOLVO]: {
    [JourneyType.PRIVATE]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
      Attribute.MAINTENANCE,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
      Attribute.MAINTENANCE,
      Attribute.EMPLOYERS_NATIONAL_INSURANCE,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      Attribute.BENEFIT_IN_KIND,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
    ],
  },
  [Brand.FORD]: {
    [JourneyType.PRIVATE]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
      Attribute.MAINTENANCE,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
      Attribute.MAINTENANCE,
      Attribute.EMPLOYERS_NATIONAL_INSURANCE,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      Attribute.BENEFIT_IN_KIND,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
    ],
  },
  [Brand.WAYLANDS]: {
    [JourneyType.PRIVATE]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
      Attribute.MAINTENANCE,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
      Attribute.MAINTENANCE,
      Attribute.EMPLOYERS_NATIONAL_INSURANCE,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      Attribute.BENEFIT_IN_KIND,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
    ],
  },
  [Brand.AUDI]: {
    [JourneyType.PRIVATE]: [Attribute.ROAD_TAX, Attribute.FUEL_COST, Attribute.MAINTENANCE],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.MAINTENANCE,
      Attribute.EMPLOYERS_NATIONAL_INSURANCE,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [Attribute.BENEFIT_IN_KIND, Attribute.FUEL_COST],
  },
  [Brand.VOLKSWAGEN]: {
    [JourneyType.PRIVATE]: [Attribute.ROAD_TAX, Attribute.FUEL_COST, Attribute.MAINTENANCE],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.MAINTENANCE,
      Attribute.EMPLOYERS_NATIONAL_INSURANCE,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [Attribute.BENEFIT_IN_KIND, Attribute.FUEL_COST],
  },
  [Brand.DRIVVNQA]: {
    [JourneyType.PRIVATE]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
      Attribute.MAINTENANCE,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      Attribute.ROAD_TAX,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
      Attribute.MAINTENANCE,
      Attribute.EMPLOYERS_NATIONAL_INSURANCE,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      Attribute.BENEFIT_IN_KIND,
      Attribute.FUEL_COST,
      Attribute.CLEAN_AIR_ZONE_CHARGES,
    ],
  },
};

const TOOLTIP_TEXT = {
  business: 'running-costs.tooltip.company-car-driver',
  private: 'running-costs.tooltip.private',
  fleetManager: 'running-costs.tooltip.fleet-manager',
};

const TAX_RATES = [
  {
    value: 20,
    label: '20%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 45,
    label: '45%',
  },
  {
    value: 50,
    label: '50%',
  },
];

const ATTRIBUTES_TO_SHOW_NEDC_FLAG_FOR = [Attribute.ROAD_TAX, Attribute.FUEL_COST];

export { ATTRIBUTES, ATTRIBUTE_JOURNEY_MAPPING, ATTRIBUTES_TO_SHOW_NEDC_FLAG_FOR, TOOLTIP_TEXT, TAX_RATES };
