import React, { PropsWithChildren } from 'react';
import { useTheme } from 'shared/hooks/useTheme';

interface Props {
  fullWidth?: boolean;
}

const TabContent = ({ children, fullWidth = false }: PropsWithChildren<Props>) => {
  const theme = useTheme();

  return (
    <div className="tab-content" data-test="tab-content" role="tabpanel">
      <div className={`tab-content__inner ${fullWidth ? 'tab-content__inner--full-width' : ''}`}>{children}</div>

      <style jsx>
        {`
          .tab-content {
            max-width: 1310px;
            margin: 0 auto;
            padding: 0 ${theme.spacing.lg} ${theme.spacing.xxxxl};
          }

          .tab-content__inner {
            max-width: 956px;
          }

          .tab-content__inner--full-width {
            max-width: none;
          }
        `}
      </style>
    </div>
  );
};

export default TabContent;
