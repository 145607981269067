import { useRef, useEffect, useState } from 'react';

export const useDetectSticky = (
  argRef?: React.RefObject<HTMLDivElement>,
  observerSettings: { threshold: number[]; rootMargin: string } = {
    threshold: [1],
    rootMargin: '-1px 0px 0px 0px',
  },
  isStickyDisabled?: boolean
) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef<HTMLDivElement>(null);
  const ref: React.RefObject<HTMLDivElement> = argRef || newRef;

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(([event]) => {
      const { intersectionRatio } = event;
      if (!isStickyDisabled) {
        setIsSticky(intersectionRatio < 1);
      }
    }, observerSettings);

    if (cachedRef) {
      observer.observe(cachedRef);
    }

    return () => {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, [isStickyDisabled, ref, observerSettings]);

  return { isSticky, ref };
};
