export const transformBrandCasing = (manufacturer: string) => {
  const brandMapping = {
    bmw: 'BMW',
    'bmw alpina': 'BMW Alipna',
    ds: 'DS',
    kia: 'KIA',
    bac: 'BAC',
    byd: 'BYD',
    'mg motor uk': 'MG Motor UK',
  };

  return brandMapping[manufacturer.toLowerCase()] || manufacturer.toLowerCase();
};
