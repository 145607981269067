import React, { useCallback, useEffect, useRef } from 'react';
import { VehicleComparison } from 'features/detailed-comparator/types';
import { Comparison } from 'shared/types';
import { Product } from 'shared/constants';
import { useDetectSticky } from 'shared/hooks/useDetectSticky';
import VehicleSummary from 'features/detailed-comparator/components/VehicleSelection/VehicleSummary';
import VehicleOption from 'features/detailed-comparator/components/VehicleSelection/VehicleOption';
import styled from 'styled-components';

interface Props {
  className?: string;
  comparisons: VehicleComparison[];
  onClick: (position: number, isComparisonSet: boolean, isVrmComparison: boolean) => void;
  onClearComparison: (position: number) => void;
  product: Product;
  onOutOfViewport: (isOutOfViewport: boolean) => void;
  top?: number;
  isStickyDisabled?: boolean;
}

const VehicleSelectorWrapper = styled.div<{ isSticky: boolean; top?: number; isStickySelectorView?: boolean }>`
  ${({ isSticky, isStickySelectorView, theme, top = 0 }) => {
    const display = (isStickySelectorView && isSticky) || !isStickySelectorView ? 'flex' : 'none';
    const opacity = !isStickySelectorView && isSticky ? '0' : '1';
    const pointerEvents = !isStickySelectorView && isSticky ? 'none' : 'all';
    const padding = isSticky ? `16px ${theme.spacing.lg}` : `16px ${theme.spacing.lg} 0`;
    return `
    display: ${display};
    max-width: 1310px;
    margin: 0 auto;
    padding: ${padding};
    background-color: ${theme.components.vehicleOption.backgroundColor};
    width: 100%;
    top: ${top}px;

    ${
      isStickySelectorView &&
      `
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;

      &:after {
        content: "";
        width: 200vw;
        height: 1px;
        display: block;
        position: absolute;
        bottom: 0;
        left: -50vw;
        z-index: -2;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15), 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
      }

      &:before {
        content: "";
        width: 200vw;
        height: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        left: -50vw;
        top: 0;
        z-index: -1;
        background-color: ${theme.components.vehicleOption.backgroundColor};
      }
    `
    };

    opacity: ${opacity};
    pointer-events: ${pointerEvents};

    @media screen and (min-width: 1240px) {
      justify-content: flex-end;
    }
  `;
  }}
`;

const VehicleSelectorWrapperBackground = styled.div<{ top: number }>`
  ${({ top }) => `
    width: 100%;
    position: unset;
    z-index: 4;
    scroll-margin-top: ${top}px;
  `}
`;

const VehicleSelectorContent = styled.div`
  ${({ theme }) => `
    display: flex;
    width: 100%;
    gap: calc(2 * ${theme.spacing.xs});

    @media screen and (min-width: 1240px) {
      max-width: 952px;
    }
  `}
`;

const VehicleSelector: React.FC<Props> = ({
  className,
  comparisons,
  onClick,
  onClearComparison,
  onOutOfViewport = () => {},
  product,
  top = 0,
  isStickyDisabled,
}) => {
  const vehicleSelectorRef = useRef<HTMLDivElement>(null);
  const stickyVehicleSelectorRef = useRef<HTMLDivElement>(null);
  // set the rootMargin to have really high side and bottom px so that an intersection is only detected when it's sticky at the top
  const rootMargin = top ? `-${top + 1}px 100000px 100000px 100000px` : '-1px 100000px 100000px 100000px';

  const cachedOnOutOfViewport = useCallback(onOutOfViewport, [onOutOfViewport]);
  const { isSticky: isStickyDetected } = useDetectSticky(
    vehicleSelectorRef,
    {
      threshold: [1],
      rootMargin,
    },
    isStickyDisabled
  );

  const { isSticky: isOutOfViewport } = useDetectSticky(
    vehicleSelectorRef,
    {
      threshold: [1],
      rootMargin: '-1px 100000px 100000px 100000px',
    },
    isStickyDisabled
  );

  const isAnyVehicleSelected = comparisons.some((comparison) => comparison.isComparisonSet);
  const isSticky = isAnyVehicleSelected && isStickyDetected && !isStickyDisabled;

  useEffect(() => {
    if (isOutOfViewport) {
      cachedOnOutOfViewport(true);
    } else {
      cachedOnOutOfViewport(false);
    }
  }, [cachedOnOutOfViewport, isOutOfViewport]);

  const getVehicles = (isStickySelectorView = false) => (
    <VehicleSelectorContent className="vehicle-selector" data-test="vehicle-selector">
      {comparisons.map((vehicle, index) => {
        return vehicle.isComparisonSet ? (
          <VehicleSummary
            comparison={vehicle.comparison as Comparison}
            isComparisonSet={vehicle.isComparisonSet}
            isVrmComparison={vehicle.isVrmComparison}
            key={index}
            position={index}
            onClick={onClick}
            onClearComparison={onClearComparison}
            product={product}
            isSticky={isSticky}
            isStickySelectorView={isStickySelectorView}
          />
        ) : (
          <VehicleOption
            isComparisonSet={vehicle.isComparisonSet}
            isVrmComparison={vehicle.isVrmComparison}
            isSticky={isSticky}
            isStickySelectorView={isStickySelectorView}
            key={index}
            position={index}
            onClick={onClick}
            product={product}
          />
        );
      })}
    </VehicleSelectorContent>
  );

  const vehicleSelectorWrapperProps = { isSticky, top };
  return (
    <VehicleSelectorWrapperBackground className={`${className}`} ref={vehicleSelectorRef} top={top}>
      <VehicleSelectorWrapper {...vehicleSelectorWrapperProps}>{getVehicles()}</VehicleSelectorWrapper>
      <VehicleSelectorWrapper {...vehicleSelectorWrapperProps} isStickySelectorView ref={stickyVehicleSelectorRef}>
        {getVehicles(true)}
      </VehicleSelectorWrapper>
    </VehicleSelectorWrapperBackground>
  );
};

export default VehicleSelector;
