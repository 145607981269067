import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';

interface Props {
  text: string;
  fullWidth?: boolean;
}

const FormError: React.FC<Props> = ({ text, fullWidth = false }) => {
  const theme = useTheme();

  return (
    <div className={`form-error ${fullWidth ? 'form-error--full-width' : ''}`} data-test="form-error">
      <img role="presentation" alt="error" src="/error.svg" />
      <p>{text}</p>

      <style jsx>
        {`
          .form-error {
            display: flex;
            justify-content: center;
            max-width: 290px;
            margin: ${theme.spacing.lg} auto;
          }

          .form-error.form-error--full-width {
            max-width: 100%;
          }

          .form-error > p {
            margin-left: ${theme.spacing.md};
          }

          @media screen and (min-width: ${theme.viewports.tablet}) {
            .form-error {
              max-width: 400px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default FormError;
