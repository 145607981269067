import { Brand, FormItems, JourneyType } from 'shared/types';

const AvailableFormItems = {
  [Brand.VOLVO]: {
    [JourneyType.PRIVATE]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
      FormItems.TAX_RATE,
    ],
  },
  [Brand.FORD]: {
    [JourneyType.PRIVATE]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
      FormItems.TAX_RATE,
    ],
  },
  [Brand.WAYLANDS]: {
    [JourneyType.PRIVATE]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.CLEAN_AIR_ZONE_ENTRIES,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
      FormItems.TAX_RATE,
    ],
  },
  [Brand.AUDI]: {
    [JourneyType.PRIVATE]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.ELECTRICITY_RATE,
      FormItems.TAX_RATE,
    ],
  },
  [Brand.VOLKSWAGEN]: {
    [JourneyType.PRIVATE]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_FLEET_MANAGER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.ELECTRICITY_RATE,
      FormItems.LEASE_TERM,
    ],
    [JourneyType.BUSINESS_COMPANY_CAR_DRIVER]: [
      FormItems.AVERAGE_MILEAGE,
      FormItems.FUEL_PRICE,
      FormItems.ELECTRICITY_RATE,
      FormItems.TAX_RATE,
    ],
  },
};

export { AvailableFormItems };
