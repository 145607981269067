import getVrmVehicleIndexes from 'features/detailed-comparator/utils/getVrmVehicleIndexes';
import { VehicleComparison } from '../types';

const clearComparison = (comparisons: VehicleComparison[]): VehicleComparison[] => {
  getVrmVehicleIndexes(comparisons).map((vehicle) => {
    comparisons[vehicle] = {
      isComparisonSet: false,
      isVrmComparison: false,
      comparison: {},
      vrm: '',
      registrationYear: undefined,
    };
  });

  return comparisons;
};

export default clearComparison;
