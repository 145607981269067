import { OptionType, PillVariant } from 'shared/types';

export const makePillData = (
  type: string,
  content: string
): {
  variant: PillVariant;
  translationKey: string;
} => {
  if (type === OptionType.PRICE) {
    return {
      variant: PillVariant.SECONDARY,
      translationKey: 'pill.optional.label',
    };
  }

  if (content.toLowerCase() === 'n/a') {
    return {
      variant: PillVariant.TERTIARY,
      translationKey: 'pill.unavailable.label',
    };
  }

  if (content.toLowerCase() === 'yes') {
    return {
      variant: PillVariant.PRIMARY,
      translationKey: 'pill.available.label',
    };
  }

  return {
    variant: PillVariant.QUATERNARY,
    translationKey: '',
  };
};
