import React from 'react';
import { useTranslation } from 'next-i18next';
import { Product } from 'shared/constants';
import { PrimaryButton } from 'shared/components/Button';
import { useTheme } from 'shared/hooks/useTheme';
import { useBrand } from 'shared/hooks/useBrand';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { titleCase } from 'shared/utils/titleCase';
import getBrandMapping from 'shared/utils/getSubBrandMapping';
import Vector from 'assets/vector.svg';
import styled from 'styled-components';

interface Props {
  position: number;
  onClick: (position: number, isComparisonSet: boolean, isVrmComparison: boolean) => void;
  isComparisonSet: boolean;
  isVrmComparison: boolean;
  product: Product;
  isSticky?: boolean;
  isStickySelectorView?: boolean;
}

const VehicleOptionWrapper = styled.div<{ isSticky: boolean }>`
  ${({ isSticky, theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${isSticky ? 'unset' : theme.components.vehicleOption.minHeight};
    width: ${theme.components.vehicleOption.width.mobile};
    border: ${theme.components.vehicleOption.border};
    border-radius: ${theme.components.vehicleOption.borderRadius};
    background: ${theme.components.vehicleOption.unSelected.backgroundColor};
    padding: ${theme.components.vehicleOption.padding};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:nth-child(3) {
      display: none;
    }

    @media screen and (min-width: ${theme.viewports.tablet}) {
      width: ${theme.components.vehicleOption.width.desktop};
      min-height: ${isSticky ? 'unset' : '230px'};

      &:nth-child(3) {
        display: flex;
      }
    }

    @media screen and (min-width: 1240px) {
      min-width: 308px;
    }
  `}
`;

const CompareButton = styled(PrimaryButton)`
  ${({ theme }) => `
    &&& {
      height: 52px;
      padding: ${theme.components.vehicleOption.compareButton.padding};

      @media screen and (max-width: ${theme.viewports.tablet}) {
        min-width: 130px;
      }

      & svg {
        min-width: ${theme.components.vehicleOption.compareButton.iconWidth};
        min-height: ${theme.components.vehicleOption.compareButton.iconHeight};
      }
    }
  `}
`;

const VehicleOption: React.FC<Props> = ({
  position,
  onClick,
  isComparisonSet,
  isVrmComparison,
  product,
  isStickySelectorView,
  isSticky,
}) => {
  const theme = useTheme();
  const brand = useBrand();

  const { t: translate } = useTranslation(`${brand}.${product}`);

  const { logEvent } = useAnalytics();

  const handleClick = async (): Promise<void> => {
    await logEvent(`/add-vehicle-form/open/${position + 1}`);

    onClick(position, isComparisonSet, isVrmComparison);
  };

  const buttonTitleBrand = getBrandMapping(brand);
  const buttonText =
    position === 0
      ? translate('add-vehicle.button.brand-vehicle.content', {
          brand: titleCase(buttonTitleBrand),
        })
      : translate('add-vehicle.button.compare-vehicle.content');

  return (
    <VehicleOptionWrapper
      className="vehicle-option"
      data-test={`vehicle-option-${position}`}
      isSticky={Boolean(isStickySelectorView && isSticky)}
    >
      <CompareButton
        text={buttonText}
        onClick={handleClick}
        prependIcon={theme.components.vehicleOption.showIcon.prepend}
        appendIcon={theme.components.vehicleOption.showIcon.append}
      >
        <Vector />
      </CompareButton>
    </VehicleOptionWrapper>
  );
};

export default VehicleOption;
