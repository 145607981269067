import { VehicleComparison } from '../types';

const getVrmVehicleIndexes = (comparisons: VehicleComparison[]): number[] => {
  return comparisons.reduce((vrmVehicles: number[], comparison, index) => {
    if (comparison.isVrmComparison || comparison.vrm) {
      vrmVehicles.push(index);
    }
    return vrmVehicles;
  }, []);
};

export default getVrmVehicleIndexes;
