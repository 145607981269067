import { AttributeInterface } from 'features/detailed-comparator/components/Attributes/Attributes';
import { VehicleOptionHeadings } from 'features/detailed-comparator/components/DataTable/constants';
import { DataBubbles } from 'shared/types';

const isVehicleOptionHeadings = (optionsOrder): optionsOrder is VehicleOptionHeadings => {
  const vehicleOptionValues = Object.values(VehicleOptionHeadings);

  return optionsOrder.some((option) => vehicleOptionValues.includes(option));
};

const isDataBubbles = (optionsOrder): optionsOrder is DataBubbles => {
  const dataBubbleOptionValues = Object.values(DataBubbles);

  return optionsOrder.some((option) => dataBubbleOptionValues.includes(option));
};

const sortOptions = (
  unorderedOptions: AttributeInterface[],
  optionsOrder: VehicleOptionHeadings[] | DataBubbles[]
): AttributeInterface[] => {
  if (unorderedOptions.length < 1) {
    return [];
  }

  if (isVehicleOptionHeadings(optionsOrder) || isDataBubbles(optionsOrder)) {
    return unorderedOptions.sort((a, b) => {
      return optionsOrder.indexOf(a.title) - optionsOrder.indexOf(b.title);
    });
  }
  return [];
};

export default sortOptions;
