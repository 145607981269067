import React from 'react';
import { KeyboardKey } from 'shared/types';
import styled from 'styled-components';

const RadioWrapper = styled.label`
  ${({ theme }) => `
    display: block;
    font: ${theme.form.inputs.radio.option.text.font};
    cursor: pointer;
    user-select: none;
    color: ${theme.colors.primary};
  `}
`;

const RadioOption = styled.input`
  display: none;
`;

const RadioOptionContent = styled.span<{ checked: boolean }>`
  ${({ checked, theme }) => `
    display: flex;
    align-items: center;
    position: relative;
    padding-left: calc(${theme.form.inputs.radio.option.size} + ${theme.form.inputs.radio.option.spacing});
    text-transform: ${theme.form.inputs.radio.option.text.transformation};

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      border: ${theme.form.inputs.radio.option.border.size} ${theme.form.inputs.radio.option.border.style}
        ${theme.form.inputs.radio.option.unchecked.color};
      border-radius: ${theme.form.inputs.radio.option.isCircular ? '50%' : '0'};
      width: ${theme.form.inputs.radio.option.size};
      height: ${theme.form.inputs.radio.option.size};
      border-color: ${checked ? theme.form.inputs.radio.option.checked.color : ''};
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: calc(${theme.form.inputs.radio.option.size} / 4);
      border: 1px solid transparent;
      border-radius: ${theme.form.inputs.radio.option.isCircular ? '50%' : '0'};
      width: calc(${theme.form.inputs.radio.option.size} / 2);
      height: calc(${theme.form.inputs.radio.option.size} / 2);

      border-color: ${checked ? theme.form.inputs.radio.option.checked.color : ''};
      background-color: ${checked ? theme.form.inputs.radio.option.checked.color : ''};
    }
  `}
`;

export interface Option {
  id: string;
  value: string;
  checked: boolean;
  content: string;
  dataTest: string;
}

interface RadioProps {
  groupName: string;
  options: Option[];
  onChange: (value: string) => void;
}

const Radio: React.FC<RadioProps> = ({ groupName, options, onChange }) => {
  const handleKeyPress = async (event: React.KeyboardEvent, value): Promise<void> => {
    if (event.code === KeyboardKey.SPACE) {
      event.preventDefault();
      onChange(value);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value);
  };

  return (
    <>
      {options.map((option: Option) => (
        <RadioWrapper
          htmlFor={option.id}
          key={option.id}
          tabIndex={0}
          onKeyDown={(event) => handleKeyPress(event, option.id)}
        >
          <RadioOption
            name={groupName}
            type="radio"
            id={option.id}
            value={option.value}
            checked={option.checked}
            onChange={handleChange}
            data-test={option.dataTest}
          />
          <RadioOptionContent checked={option.checked}>{option.content}</RadioOptionContent>
        </RadioWrapper>
      ))}
    </>
  );
};

export default Radio;
