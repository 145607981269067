import React from 'react';
import { usePlausible } from 'next-plausible';
import styled from 'styled-components';

type Props = { onClick?: () => void; className?: string };

const noOp = () => null;

const NEDCButton = styled.button`
  ${({ theme }) => `
    color: ${theme.NEDCFlag?.color || 'blue'};
    text-decoration: underline;
    font-size: ${theme.fontSizes.sm};
    cursor: pointer;
    pointer-events: all;
    border: none;
    background-color: unset;
  `}
`;

export const NEDCFlag = ({ className = '', onClick = noOp }: Props) => {
  const plausible = usePlausible();
  const handleClick = () => {
    plausible('NEDC_flag_clicked');
    onClick();
  };

  return (
    <NEDCButton type="button" data-test="NEDC-flag" onClick={handleClick} className={className}>
      ⚠ NEDC
    </NEDCButton>
  );
};
