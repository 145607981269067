import { Manufacturer, Model, Option, Trim } from 'features/detailed-comparator/types';

export const makeOptions = (array: Manufacturer[] | Model[]): Option[] => {
  const options = array.map(({ code, name, discontinued }) => ({
    value: code,
    label: name,
    discontinued,
  }));

  return options;
};

export const makeModelOptions = (array: Model[]): Option[] => {
  const options = array.map(({ code, name, introduced, discontinued }) => {
    const shouldShowRange = introduced && discontinued;
    return {
      value: code,
      label: `${name}${shouldShowRange ? ` (${introduced}-${discontinued})` : ''}`,
      discontinued,
    };
  });
  return options;
};

export const makeTrims = (trims: Trim[]): Option[] => {
  const trimOptions = trims.map(({ capId, description, discontinued }) => ({
    value: capId,
    label: description,
    discontinued,
  }));

  return trimOptions;
};

export const makeRegistrationYears = (startYear: number, availableYears: number[] = []) => {
  const currentYear = new Date().getFullYear();
  const options: { value: string; label: string }[] = [];

  for (let i = currentYear - startYear; i <= currentYear; i += 1) {
    if (availableYears.includes(i)) {
      options.push({ value: `${i}`, label: `${i}` });
    }
  }
  return options;
};
