import { Comparison } from 'shared/types';

export interface Manufacturer {
  code: number;
  name: string;
  discontinued: number;
  yearsModelsIntroduced: number[];
}

export interface Model {
  code: number;
  name: string;
  introduced: number;
  discontinued: number;
}

export interface Trim {
  capId: number;
  description: string;
  modelName: string;
  trim: string;
  driveTrain: string;
  fuelDelivery: string;
  transmission: string;
  fuelType: string;
  discontinued: null | number;
}

export interface VehicleOptions {
  title: string;
  content: string;
  type: string;
}

export interface Option {
  value: string | number;
  label: string;
  discontinued: null | number;
}

export interface VehicleComparison {
  vrm: string;
  isComparisonSet: boolean;
  isVrmComparison: boolean;
  comparison: Comparison | any;
  registrationYear?: number | undefined;
  currentMileage?: number | undefined;
}

export enum Position {
  LEFT = 0,
  MIDDLE = 1,
  RIGHT = 2,
}

export enum OwnershipType {
  LEASE = 'lease',
  OWN = 'own',
}

export enum InputType {
  INPUT = 'input',
  SELECT = 'select',
}

export interface FormValues {
  averageMileage: string;
  fuelPrice: string;
  cleanAirZoneEntries: string;
  electricityRate: string;
  taxRate: string;
  leaseTerm: string;
}

export enum CostCalculatorInputLimit {
  LEASE = 4,
  OWN = 6,
}

export interface FleetManagerCosts {
  own: string;
  lease: string;
}
