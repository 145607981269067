import { FormTypes, JourneyType } from 'shared/types';
import {
  DEFAULT_ELECTRIC_FUEL_PRICE,
  DEFAULT_FINANCE_PERIOD_IN_YEARS,
  DEFAULT_MONTHLY_CLEAN_AIR_ZONE_ENTRIES,
  DEFAULT_PETROL_FUEL_PRICE,
  DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
  DEFAULT_TAX_RATE,
} from 'services/helpers/calculations/constants';
import { FormValues, VehicleComparison } from 'features/detailed-comparator/types';
import makeComparison from 'features/detailed-comparator/utils/makeComparison';

export interface State {
  isAddVehicleFormVisible: boolean;
  shouldFetchVehicle: boolean;
  vehicleId: string;
  formPosition: number;
  comparisons: VehicleComparison[];
  activeFormType: FormTypes;
  isRemoveVehiclePromptVisible: boolean;
  formValues: FormValues;
  shouldUpdate: boolean;
  isFleetManagerPromptVisible: boolean;
  selectedRunningCostsTab: JourneyType;
  isValidNewCostFleetManager: boolean;
  isShowModalError: boolean;
  isInitialSlot: boolean;
  isAddVehicleOptionsFormVisible: boolean;
}

export enum ActionType {
  SET_IS_ADD_VEHICLE_FORM_VISIBLE = 'SET_IS_ADD_VEHICLE_FORM_VISIBLE',
  SET_SELECTED_VEHICLE = 'SET_SELECTED_VEHICLE',
  SET_FORM_POSITION = 'SET_FORM_POSITION',
  SET_SHOULD_CLEAR_COMPARISON = 'SET_SHOULD_CLEAR_COMPARISON',
  SET_COMPARISONS = 'SET_COMPARISONS',
  SET_ACTIVE_FORM_TYPE = 'SET_ACTIVE_FORM_TYPE',
  SET_IS_REMOVE_VEHICLE_PROMPT_VISIBLE = 'SET_IS_REMOVE_VEHICLE_PROMPT_VISIBLE',
  SET_FORM_VALUES = 'SET_FORM_VALUES',
  SET_SHOULD_UPDATE = 'SET_SHOULD_UPDATE',
  SET_RECALCULATION = 'SET_RECALCULATION',
  SET_SHOW_FLEET_MANAGER_PROMPT = 'SET_SHOW_FLEET_MANAGER_PROMPT',
  SET_SELECTED_RUNNING_COSTS_TAB = 'SET_SELECTED_RUNNING_COSTS_TAB',
  SET_CLEAR_VRM_VEHICLES = 'SET_CLEAR_VRM_VEHICLES',
  SET_IS_VALID_NEW_COST_FLEET_MANAGER = 'SET_IS_VALID_NEW_COST_FLEET_MANAGER',
  SET_IS_SHOW_MODAL_ERROR = 'SET_IS_SHOW_MODAL_ERROR',
  SET_IS_INITIAL_SLOT = 'SET_IS_INITIAL_SLOT',
  SET_CHOOSE_OPTION = 'SET_CHOOSE_OPTION',
  SET_IS_ADD_VEHICLE_OPTIONS_FORM_VISIBLE = 'SET_IS_ADD_VEHICLE_OPTIONS_FORM_VISIBLE',
}

export const initialState: State = {
  isAddVehicleFormVisible: false,
  shouldFetchVehicle: false,
  vehicleId: '',
  formPosition: 0,
  comparisons: [
    {
      isComparisonSet: false,
      isVrmComparison: false,
      vrm: '',
      comparison: {},
      registrationYear: undefined,
    },
    {
      isComparisonSet: false,
      isVrmComparison: false,
      vrm: '',
      comparison: {},
      registrationYear: undefined,
    },
    {
      isComparisonSet: false,
      isVrmComparison: false,
      vrm: '',
      comparison: {},
      registrationYear: undefined,
    },
  ],
  activeFormType: FormTypes.CHANGE_VEHICLE,
  isRemoveVehiclePromptVisible: false,
  isValidNewCostFleetManager: true,
  formValues: {
    averageMileage: String(DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE),
    fuelPrice: String(DEFAULT_PETROL_FUEL_PRICE),
    cleanAirZoneEntries: String(DEFAULT_MONTHLY_CLEAN_AIR_ZONE_ENTRIES),
    electricityRate: String(DEFAULT_ELECTRIC_FUEL_PRICE),
    taxRate: String(DEFAULT_TAX_RATE),
    leaseTerm: String(DEFAULT_FINANCE_PERIOD_IN_YEARS),
  },
  shouldUpdate: false,
  isFleetManagerPromptVisible: false,
  selectedRunningCostsTab: JourneyType.PRIVATE,
  isShowModalError: false,
  isInitialSlot: false,
  isAddVehicleOptionsFormVisible: false,
};

export const reducer = (state: State, action): State => {
  switch (action.type) {
    case ActionType.SET_IS_ADD_VEHICLE_FORM_VISIBLE:
      return {
        ...state,
        isAddVehicleFormVisible: action.isAddVehicleFormVisible,
        activeFormType: FormTypes.CHANGE_VEHICLE,
      };
    case ActionType.SET_SELECTED_VEHICLE:
      return {
        ...state,
        shouldFetchVehicle: action.shouldFetchVehicle,
        vehicleId: action.vehicleId,
      };
    case ActionType.SET_FORM_POSITION:
      return {
        ...state,
        formPosition: action.formPosition,
      };
    case ActionType.SET_COMPARISONS:
      return {
        ...state,
        comparisons: makeComparison(state, action),
      };
    case ActionType.SET_ACTIVE_FORM_TYPE:
      return {
        ...state,
        activeFormType: action.activeFormType,
      };
    case ActionType.SET_IS_REMOVE_VEHICLE_PROMPT_VISIBLE:
      return {
        ...state,
        isRemoveVehiclePromptVisible: action.isRemoveVehiclePromptVisible,
      };
    case ActionType.SET_FORM_VALUES:
      return {
        ...state,
        formValues: action.formValues,
      };
    case ActionType.SET_SHOULD_UPDATE:
      return {
        ...state,
        shouldUpdate: action.shouldUpdate,
      };
    case ActionType.SET_RECALCULATION: {
      const newComparisons = [...state.comparisons];
      newComparisons[action.index].comparison = action.comparison;

      return {
        ...state,
        comparisons: newComparisons,
        shouldUpdate: false,
      };
    }
    case ActionType.SET_SHOW_FLEET_MANAGER_PROMPT:
      return {
        ...state,
        isFleetManagerPromptVisible: action.isVisible,
      };
    case ActionType.SET_SELECTED_RUNNING_COSTS_TAB:
      return {
        ...state,
        selectedRunningCostsTab: action.selectedRunningCostsTab,
      };
    case ActionType.SET_CLEAR_VRM_VEHICLES:
      return {
        ...state,
        comparisons: action.comparisons,
        selectedRunningCostsTab: JourneyType.BUSINESS_FLEET_MANAGER,
        isFleetManagerPromptVisible: false,
      };
    case ActionType.SET_IS_VALID_NEW_COST_FLEET_MANAGER:
      return {
        ...state,
        isValidNewCostFleetManager: action.isValidNewCostFleetManager,
      };
    case ActionType.SET_IS_SHOW_MODAL_ERROR:
      return {
        ...state,
        isShowModalError: action.isShowModalError,
      };
    case ActionType.SET_IS_INITIAL_SLOT:
      return {
        ...state,
        isInitialSlot: action.isInitialSlot,
      };
    case ActionType.SET_IS_ADD_VEHICLE_OPTIONS_FORM_VISIBLE:
      return {
        ...state,
        isAddVehicleOptionsFormVisible: action.isAddVehicleOptionsFormVisible,
      };
    default:
      throw new Error('Invalid action type');
  }
};
