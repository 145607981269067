import { JourneyType } from 'shared/types';
import { formatToCurrency } from 'shared/utils/currency';

const getMonthlyCost = (costsArray: any[], selectedOption: JourneyType): string => {
  if (Array.isArray(costsArray) && costsArray.length > 0) {
    const monthlyCost = costsArray.find((object: { type: JourneyType }) => object.type === selectedOption).monthlyPrice;

    return formatToCurrency(monthlyCost);
  }

  return '';
};

export default getMonthlyCost;
