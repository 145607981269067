import { InitialFormValues, FormItems } from 'shared/types';

interface MakeTotalCostOfOwnershipFormData {
  [key: string]: {
    label: string;
    defaultType: string;
    helperText: string;
    defaultValue: string | number;
    name: string;
    className: string;
    min: number;
    max: number;
    step: string;
    pattern: string;
    title: string;
    formValueLabel: string;
  };
}

const makeTotalCostOfOwnershipFormData = (initialFormValues: InitialFormValues): MakeTotalCostOfOwnershipFormData => {
  return {
    [FormItems.AVERAGE_MILEAGE]: {
      label: 'total-cost-of-ownership-recalculation-form.average-mileage.label',
      defaultType: 'number',
      helperText: 'miles per year',
      defaultValue: initialFormValues.averageMileage,
      name: 'average-mileage',
      className: 'form-item--large',
      min: initialFormValues.mileage.min,
      max: initialFormValues.mileage.max,
      step: 'any',
      pattern: '',
      title: '',
      formValueLabel: 'averageMileage',
    },
    [FormItems.FUEL_PRICE]: {
      label: 'total-cost-of-ownership-recalculation-form.fuel-price.label',
      defaultType: 'number',
      helperText: 'p per litre',
      defaultValue: initialFormValues.fuelPrice,
      name: 'fuel-price',
      className: 'form-item--large',
      min: 1,
      max: 300,
      step: 'any',
      pattern: '',
      title: '',
      formValueLabel: 'fuelPrice',
    },
    [FormItems.CLEAN_AIR_ZONE_ENTRIES]: {
      label: 'total-cost-of-ownership-recalculation-form.clean-air-zone-entries.label',
      defaultType: 'text',
      helperText: 'times per month',
      defaultValue: initialFormValues.cleanAirZone.defaultEntries,
      name: 'clean-air-zone-entries',
      className: 'form-item--clean-air-zone-entries',
      min: 1,
      max: initialFormValues.cleanAirZone.maxEntriesPerYear,
      step: '1',
      pattern: '^([0-9]|[1-9][0-9]?|[12][0-9]|3[0-5][0-9]|36[0-6])$',
      title: 'Maximum of 366 entries per month',
      formValueLabel: 'cleanAirZoneEntries',
    },
    [FormItems.ELECTRICITY_RATE]: {
      label: 'total-cost-of-ownership-recalculation-form.electricity-rate.label',
      defaultType: 'number',
      helperText: 'p per kWh',
      defaultValue: initialFormValues.electricityRate,
      name: 'electricity-rate',
      className: 'form-item--large',
      min: 1,
      max: 100,
      step: 'any',
      pattern: '',
      title: '',
      formValueLabel: 'electricityRate',
    },
    [FormItems.TAX_RATE]: {
      label: 'total-cost-of-ownership-recalculation-form.tax-rate.label',
      defaultType: 'select',
      helperText: 'percentage',
      defaultValue: initialFormValues.taxRate,
      name: 'tax-rate',
      className: 'form-item--large',
      min: 1,
      max: 100,
      step: 'any',
      pattern: '',
      title: '',
      formValueLabel: 'taxRate',
    },
    [FormItems.OWN_TERM]: {
      label: 'total-cost-of-ownership-recalculation-form.own-term.label',
      defaultType: 'number',
      helperText: 'years',
      defaultValue: initialFormValues.financePeriodInYears,
      name: 'lease-term',
      className: 'form-item--own-lease-fleet-manager',
      min: initialFormValues.leaseTerm.min,
      max: initialFormValues.leaseTerm.max,
      step: 'any',
      pattern: '',
      title: '',
      formValueLabel: 'leaseTerm',
    },
    [FormItems.LEASE_TERM]: {
      label: 'total-cost-of-ownership-recalculation-form.lease-term.label',
      defaultType: 'number',
      helperText: 'years',
      defaultValue: initialFormValues.financePeriodInYears,
      name: 'lease-term',
      className: 'form-item--own-lease-fleet-manager',
      min: initialFormValues.leaseTerm.min,
      max: initialFormValues.leaseTerm.max,
      step: 'any',
      pattern: '',
      title: '',
      formValueLabel: 'leaseTerm',
    },
  };
};

export default makeTotalCostOfOwnershipFormData;
