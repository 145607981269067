import { UseQuery, useQuery } from 'shared/hooks/useQuery';
import { Manufacturer } from 'features/detailed-comparator/types';
import { Product } from 'shared/constants';
import { Filter, filtersAsQueryString } from 'features/detailed-comparator/utils/getFiltersAsQueryString';

interface Response {
  manufacturers?: Manufacturer[];
}

const useManufacturers = ({ product, filters }: { product: Product; filters?: Filter[] }): UseQuery<Response> => {
  const filterQueryString = filtersAsQueryString(filters);
  return useQuery<Response>(true, `/api/manufacturers/${filterQueryString ? `?${filterQueryString}` : ''}`, product);
};

export { useManufacturers };
